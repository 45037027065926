import {
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";

const paymentStatus = ["All", "pending", "paid"];
const actionStatus = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Action Taken",
    value: "AT",
  },
  {
    label: "No Action Taken",
    value: "NAT",
  },
];

const Filter = ({ orders, setTableData }) => {
  const [payment, setPayment] = useState("All");
  const [actionType, setActionType] = useState("All");
  useEffect(() => {
    const filtered = orders
      .filter((o) => o.payment === payment || payment === "All")
      .filter(
        (o) =>
          actionType === "All" ||
          (actionType === "AT" && o.mark_cancel_date) ||
          (actionType === "NAT" && !o.mark_cancel_date)
      )
      .map((o) => ({
        ...o,
        package_status: String(o.package_status).replace("sync#", ""),
      }));
    setTableData(filtered);
  }, [payment, orders, actionType]);
  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          px: 2,
          py: "10px",
          mb: 2,
        }}
      >
        <Stack direction={"row"} gap={2}>
          <TextField
            style={{ width: "200px" }}
            select
            value={payment}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    style={{
                      fontWeight: 800,
                      borderRight: "1px solid black",
                      paddingRight: "4px",
                    }}
                  >
                    Payment
                  </p>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setPayment(e.target.value)}
            variant="outlined"
            className="select"
            size="small"
          >
            {paymentStatus.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ width: "200px" }}
            select
            value={actionType}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    style={{
                      fontWeight: 800,
                      borderRight: "1px solid black",
                      paddingRight: "4px",
                    }}
                  >
                    Action
                  </p>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setActionType(e.target.value)}
            variant="outlined"
            className="select"
            size="small"
          >
            {actionStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Paper>
    </div>
  );
};

export default Filter;
