import React, { useEffect, useState } from "react";
import { addPattern, getPatterns } from "../../actions/materialActions";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const AddPattern = ({ setPatterns }) => {
  const [patternId, setPatternId] = useState("");

  const handleChange = (event) => {
    setPatternId(String(event.target.value).trim());
  };

  const handleClick = async () => {
    const res = await addPattern(patternId);
    if (res.statusCode === 200) {
      setPatterns((prev) => [...prev, patternId]);
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Stack
        direction={"row"}
        gap={2}
        sx={{
          justifyContent: "center",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="id"
          value={patternId}
          onChange={handleChange}
        />
        <Button variant="contained" onClick={handleClick}>
          Add Pattern
        </Button>
      </Stack>
    </Paper>
  );
};

const PatternTable = ({ tableData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Paper p={2} sx={{ my: 3 }}>
      <TableContainer sx={{ minWidth: 700 }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">sl</TableCell>
              <TableCell align="center">Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, i) => (
              <TableRow
                key={i + 1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell align="center">{row}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const PatternAdd = () => {
  const [loading, setLoading] = useState(false);
  const [patterns, setPatterns] = useState([]);

  useEffect(() => {
    setLoading(false);
    getPatterns()
      .then((data) => {
        if (data.statusCode === 200) {
          setPatterns(data.body.map((o) => o.barcode));
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setLoading(false);
      });
  }, []);
  return (
    <div>
      {loading ? (
        <Skeleton variant="rectangular" height={500} width={"100%"} />
      ) : (
        <>
          <h1
            style={{ textAlign: "center", fontSize: "40px", marginBottom: 10 }}
          >
            PATTERNS
          </h1>
          <AddPattern setPatterns={setPatterns} />
          {patterns.length > 0 && <PatternTable tableData={patterns} />}
        </>
      )}
    </div>
  );
};

export default PatternAdd;
