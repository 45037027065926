import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  allShopifyInventory,
  allUnfulfilledOrders,
  getPerDaySalesData,
  getThreeUnfulfilledProductsFilteredData,
} from "../../actions/ordersActions";
import { allProducts } from "../../actions/productsActions";
import { allInventory } from "../../actions/inventoryActions";
import ProgressBar from "../../ProgressBar";
import UnfulfilledOrders from "../ordersWidgets/UnfulfilledOrders";
import RedirectLogin from "../RedirectLogin";
import "./index.css";
import { getProcessedQuantity } from "../../actions/POActions";
import { getPatterns } from "../../actions/materialActions";
import { toast } from "react-toastify";

const Orders = () => {
  const auth = useSelector((state) => state.auth);
  const {
    allUnfulfilled: orders,
    filtered3MProductsData,
    perDaySalesData,
  } = useSelector((state) => state.orderDetails);
  const products = useSelector((state) => state.productDetails.products);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const shopifyInventory = useSelector(
    (state) => state.orderDetails.shopifyInventory
  );
  const { processedQntyList } = useSelector((state) => state.PO);
  const [patterns, setPatterns] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth) return;
    if (!orders.length > 0) dispatch(allUnfulfilledOrders());
    if (!filtered3MProductsData)
      dispatch(getThreeUnfulfilledProductsFilteredData());
    if (!perDaySalesData) {
      dispatch(getPerDaySalesData());
    }
    if (!products.length > 0) dispatch(allProducts());
    if (!inventory.length > 0) dispatch(allInventory());
    if (!shopifyInventory.length > 0 && auth) dispatch(allShopifyInventory());
    if (!processedQntyList.length > 0 && auth) dispatch(getProcessedQuantity());
    getPatterns()
      .then((data) => {
        if (data.statusCode === 200) {
          setPatterns(data.body.map((o) => o.barcode));
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });
  }, [dispatch, auth]);
  console.log("per day sales data", perDaySalesData);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!orders.length > 0 ||
          !products.length > 0 ||
          !perDaySalesData ||
          !processedQntyList.length > 0 ||
          !patterns.length > 0 ||
          !inventory.length > 0 ? (
            <ProgressBar />
          ) : (
            <UnfulfilledOrders patterns={patterns} />
          )}
        </>
      )}
    </>
  );
};

export default Orders;
