import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Typography,
  Modal,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Checkbox,
  Button,
  Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { showFullScreenLoader } from "../../actions/loaderActions";
import {
  replenishDamagedRTOorder,
  updateDamagedRtoOrders,
  markDamagedRTOorder,
} from "../../actions/ordersActions";
import { updateReconProcessedAt } from "../../actions/reverseDeliveryActions";
import { toast } from "react-toastify";
import { markDamagedREOorder, replenishREorder } from "../../actions/reconActions";

export const ReDamagedModal = ({
  open,
  setReSelectDamagedModal,
  selectedOrder,
  orderProducts,
  productVariants,
  orderId,
  setSearchValue,
  setSearchAwbValue,
  order_type,
}) => {
  const dispatch = useDispatch();
  const [openUp, setOpenUp] = useState(open);
  const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);
  const [isDamagedMarked, setIsDamagedMarked] = useState(false);

  console.log("ORDER PRODUCTS", orderProducts);
  console.log("selected Order :", selectedOrder);
  const handleClose = () => {
    setOpenUp(false);
    setReSelectDamagedModal(false);
    setSelectedOrderProducts([]);
  };

  const handleDamageSubmit = async () => {
    // select the lineitems which were mark damaged
    const toBemarkedDamagedItems = selectedOrderProducts.map((x) => ({
      ...x,
      damaged: true,
    }));

    // update recon table record
    const reconPayload = {
      fulfillment_id: orderId,
      line_items: orderProducts.map((l) => {
        // add a key damaged only for damaged items
        const found = toBemarkedDamagedItems.find((p) => p.sku === l.sku);
        if (!!found) {
          return {
            ...l,
            damaged: true,
          };
        } else {
          return l;
        }
      }),
    };
    console.log("reconPayload", reconPayload);


    const toBeUpdatedBody = {
      fulfillment_id: orderId,
      damaged_order_items: toBemarkedDamagedItems, // checked items
      approved_items : orderProducts.length > toBemarkedDamagedItems.length
  ? orderProducts.filter(
      (item) =>
        !toBemarkedDamagedItems.some((damaged) => damaged.sku === item.sku && damaged.originalSize === item.originalSize)
    )
  : [],
    };
    console.log("toBeUpdatedBody", toBeUpdatedBody);

    console.log("recon payload", reconPayload);

    markDamagedREOorder(toBeUpdatedBody);

    setIsDamagedMarked(true);
    setSearchValue("");
    setSearchAwbValue("");
    // CHANGE REDUX VALUES
    dispatch({
      type: "DELETESINGLE",
      payload: String(toBeUpdatedBody.fulfillment_id),
    });
    handleClose();
  };

  const handleApproveAll = () => {
    const approved_payload = {
      approved_items: orderProducts,
      id : orderId
    }
    // Logic for approving all items
    dispatch(replenishREorder(approved_payload));
    console.log("All items approved");
    dispatch({
      type: "DELETESINGLE",
      payload: String(approved_payload.id),
    });
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "whitesmoke",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "scroll",
    maxWidth: "90%",
  };

  return (
    <div>
      <Modal
        open={openUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Select Damaged Items</h3>

          <Paper
            elevation={3}
            sx={{
              padding: 2,
              borderRadius: "8px",
              backgroundColor: "#f5f5f5",
              maxWidth: "400px",
            }}
          >
            <h5>Customer Details</h5>
            <span>{selectedOrder.address?.name}</span>
            <br />
            <span>{selectedOrder.address?.phone}</span>
            <br />
            <span>{selectedOrder.address?.address1}, </span>
            <span>{selectedOrder.address?.address2}, </span>
            <span>{selectedOrder.address?.city}, </span>
            <span>{selectedOrder.address?.province}, </span>
            <span>{selectedOrder.address?.country}</span>
          </Paper>
          <TableContainer
            component={Paper}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <>
                      <Checkbox
                        color="primary"
                        checked={
                          selectedOrderProducts.length > 0 ? true : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrderProducts(orderProducts);
                          } else {
                            setSelectedOrderProducts([]);
                          }
                        }}
                      />
                      {`${selectedOrderProducts.length} Selected`}
                    </>
                  </TableCell>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="left">SKU</TableCell>
                  <TableCell align="left">Size</TableCell>
                  <TableCell align="left">Size Requested by Customer</TableCell>
                  <TableCell align="left">Units</TableCell>
                  {/* <TableCell align="left">Address</TableCell> */}
                  <TableCell align="left">Files from Customer</TableCell>
                  <TableCell align="left">Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderProducts.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      <Checkbox
                        color="primary"
                        checked={
                          selectedOrderProducts.filter(
                            (i) => i.id === row.id
                          ).length > 0
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrderProducts([
                              ...selectedOrderProducts,
                              row,
                            ]);
                          } else {
                            setSelectedOrderProducts(
                              selectedOrderProducts.filter(
                                (item) => item.name !== row.name
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      <Avatar
                        src={row.imageUrl}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: "ro",
                        }}
                        variant="square"
                      />
                    </TableCell>
                    <TableCell align="left">{row.sku.split(".")[0]}</TableCell>
                    <TableCell align="left">
                      {row.originalSize
                        ? row.originalSize
                        : row.sku.split(".")[1]}
                    </TableCell>
                    <TableCell align="left">
                      {row.size ? row.size : "N/A"}
                    </TableCell>
                    <TableCell align="left">{row.units}</TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {selectedOrder.files?.map((f, i) => (
                        <a href={f.url} target="_blank" key={i}>
                          <Avatar
                            src={f.url}
                            sx={{
                              width: 100,
                              height: 100,
                              img: { objectFit: "cover" },
                            }}
                            variant="square"
                          />
                        </a>
                      ))}
                    </TableCell>
                    <TableCell align="left">{selectedOrder?.reason}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          {/* {isDamagedMarked ? (
            <div>
              <CheckCircleIcon color="success" />
              <Typography variant="body1" color="green">
                Damaged items successfully marked!
              </Typography>
            </div>
          ) : (
            <Button
              variant="contained"
              color="error"
              disabled={selectedOrderProducts.length === 0 || isDamagedMarked}
              onClick={handleSubmit}
            >
              DAMAGED
            </Button>
          )} */}

{isDamagedMarked ? (
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <CheckCircleIcon color="success" fontSize="large" />
              <Typography variant="body1" color="green">
                Damaged items successfully marked!
              </Typography>
            </Box>
          ) : (
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDamageSubmit}
                disabled={selectedOrderProducts.length === 0}
              >
                DAMAGED
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApproveAll}
                disabled={selectedOrderProducts.length > 0}
              >
                Approve All
              </Button>
            </Stack>
          )}
        </Box>
      </Modal>
    </div>
  );
};
