import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FabricPOTable from "./FabricPOTable";
import { getSizesByType } from "../../../utils/helperArrays";
const days30 = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];
const days45 = Array.from({ length: 45 }, (v, i) =>
  (i + 1).toString()
).reverse();

const FabricDataGenerate = ({
  materialData,
  vendorMaterials,
  unfulfilledOrders,
}) => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [materialWithPO, setMaterialWithPO] = useState([]);
  const { perDaySalesData } = useSelector((state) => state.orderDetails);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const { processedQntyList } = useSelector((state) => state.PO);

  useEffect(() => {
    const salesData = perDaySalesData.sales_data;

    // create total stock and consumption for each
    const inv = inventory
      .filter((i) => i.SKU)
      .reduce((acc, i) => {
        const found = acc.find((ac) => ac.SKU === i.SKU);
        if (found) {
          if ("material" in i && i.material) {
            found.material = i.material;
            found.stock[i.size] = i.count;
          }
        } else {
          const newObj = {
            SKU: i.SKU,
            type: i.type,
            material: "material" in i ? i.material : [],
            stock: {
              [i.size]: i.count,
            },
          };
          acc.push(newObj);
        }
        return acc;
      }, [])
      .reduce((acc, i) => {
        console.log("acc", acc);
        //  calculate total 45 day sale, total sale consumption
        const materials = "material" in i ? i.material : [];
        materials.forEach((d) => {
          const materialWeight = d.materialWeight ? d.materialWeight : 0;
          const sizes = getSizesByType(i.type);
          const found = acc.find((ac) => ac.name === d.name);
          // update sales data
          if (found) {
            const findSales = salesData.find((s) => s.sku === i.SKU);
            if (findSales) {
              found.perDaySalesData = days45.reduce((acc, day) => {
                const amt = findSales[day] ? findSales[day].total : 0;
                acc[day] = found.perDaySalesData[day] + amt;
                return acc;
              }, {});
              const total45DaySales = days45.reduce((acc, day) => {
                const amt = findSales[day] ? findSales[day].total : 0;
                return acc + amt;
              }, 0);
              found.total45DaySales = found.total45DaySales + total45DaySales;
              found.totalSaleConsumption =
                found.totalSaleConsumption + total45DaySales * materialWeight;
            }
            // update total sale consumption
            const unfulfilledData =
              unfulfilledOrders.find((uo) => uo.sku === i.SKU) ?? {};
            const unfulfilledPerSize = unfulfilledData.sizes ?? {};

            // calculate total sale consumption
            // calculate per size, remove non negative values and multiply by material weight and add the amount

            // get processing orders
            const processingData =
              processedQntyList.find((p) => p.barcode === i.SKU) ?? {};
            const cutting = processingData.cutting
              ? processingData.cutting
              : {};
            const stitching = processingData.stitching
              ? processingData.stitching
              : {};
            const finishing = processingData.finishing
              ? processingData.finishing
              : {};

            const unfulfilledFabric = sizes.reduce((scAcc, size) => {
              const cuttingAmt = cutting[size] ? cutting[size] : 0;
              const stitchingAmt = stitching[size] ? stitching[size] : 0;
              const finishingAmt = finishing[size] ? finishing[size] : 0;
              const invStock = i.stock[size] ? i.stock[size] : 0;
              const unfulfilledAmt = unfulfilledPerSize[size]
                ? unfulfilledPerSize[size]
                : 0;

              const totalAmt =
                cuttingAmt +
                stitchingAmt +
                finishingAmt +
                invStock -
                unfulfilledAmt;
              if (totalAmt < 0) {
                scAcc = scAcc + Math.abs(totalAmt) * materialWeight;
                return scAcc;
              } else {
                return scAcc;
              }
            }, 0);
            found.unfulfilledFabric = parseFloat(
              parseFloat(found.unfulfilledFabric + unfulfilledFabric).toFixed(2)
            );
          } else {
            // initital assumptions
            let perDaySalesData = days45.reduce(
              (acc, day) => ({ ...acc, [day]: 0 }),
              {}
            );
            let total45DaySales = 0;
            let totalSaleConsumption = 0;
            // find the sales for the sku
            const findSales = salesData.find((s) => s.sku === i.SKU);
            if (findSales) {
              perDaySalesData = days45.reduce((acc, day) => {
                const amt = findSales[day] ? findSales[day].total : 0;
                acc[day] = amt;
                return acc;
              }, {});
              total45DaySales = days45.reduce((acc, day) => {
                const amt = findSales[day] ? findSales[day].total : 0;
                return acc + amt;
              }, 0);
              if (d.name === "POLY LINEN 50206") {
                console.log("found it", total45DaySales, findSales);
              }
              totalSaleConsumption = total45DaySales * materialWeight;
            }
            const unfulfilledData =
              unfulfilledOrders.find((uo) => uo.sku === i.SKU) ?? {};
            const unfulfilledPerSize = unfulfilledData.sizes ?? {};

            // calculate total sale consumption
            // calculate per size, remove non negative values and multiply by material weight and add the amount

            // get processing orders
            const processingData =
              processedQntyList.find((p) => p.barcode === i.SKU) ?? {};
            const cutting = processingData.cutting
              ? processingData.cutting
              : {};
            const stitching = processingData.stitching
              ? processingData.stitching
              : {};
            const finishing = processingData.finishing
              ? processingData.finishing
              : {};

            const unfulfilledFabric = sizes.reduce((scAcc, size) => {
              const cuttingAmt = cutting[size] ? cutting[size] : 0;
              const stitchingAmt = stitching[size] ? stitching[size] : 0;
              const finishingAmt = finishing[size] ? finishing[size] : 0;
              const invStock = i.stock[size] ? i.stock[size] : 0;
              const unfulfilledAmt = unfulfilledPerSize[size]
                ? unfulfilledPerSize[size]
                : 0;

              const totalAmt =
                cuttingAmt +
                stitchingAmt +
                finishingAmt +
                invStock -
                unfulfilledAmt;

              if (totalAmt < 0) {
                scAcc = scAcc + Math.abs(totalAmt) * materialWeight;
                return scAcc;
              } else {
                return scAcc;
              }
            }, 0);

            const newObj = {
              name: d.name,
              color: d.color,
              fabric: d.type,
              unfulfilledFabric,
              totalSaleConsumption,
              perDaySalesData,
              total45DaySales,
            };
            console.log(acc);
            acc.push(newObj);
          }
        });
        return acc;
      }, []);
    setData(inv);
  }, [perDaySalesData, inventory, unfulfilledOrders, processedQntyList]);

  console.log(
    "All data",
    materialData
    // vendorMaterials,
    // perDaySalesData,
    // vendorMaterials,
    // unfulfilledOrders
    // inventory,
    // materialWithPO
  );

  useEffect(() => {
    console.log("materialWithPO", materialWithPO);
    const materialInfos = materialWithPO.reduce((acc, m) => {
      const row = data.find(
        (d) => d.fabric === m.fabricName && d.color === m.colorName
      );
      if (row) {
        const total45DaySales = row.total45DaySales;
        const totalSaleConsumption = row.totalSaleConsumption;
        const perDayConsumption = totalSaleConsumption / 45;

        const unfulfilledFabric = row.unfulfilledFabric;
        const perDaySalesData = row.perDaySalesData;

        const unit = m.unit;
        const fabricAmt = m.amt ? m.amt : 0;
        const fabricStock = m.unitValue ? m.unitValue : 0;
        const vendorStock = vendorMaterials
          .filter(
            (v) => v.fabricName === m.fabricName && v.colorName === m.colorName
          )
          .reduce((acc, v) => acc + v.amt, 0);
        const vendorPO = m.vendorPO;
        const totalStock = fabricStock + vendorStock + vendorPO;

        const totalRequired = parseFloat(
          parseFloat(
            totalSaleConsumption + unfulfilledFabric - totalStock
          ).toFixed(2)
        );
        const daysOfFabricAvailable = parseFloat(
          parseFloat((fabricStock + vendorStock) / perDayConsumption).toFixed(2)
        );

        const daysOfFabricReq = parseFloat(
          parseFloat(totalRequired / perDayConsumption).toFixed(2)
        );
        const obj = {
          fabricName: m.fabricName,
          total45DaySales,
          colorName: m.colorName,
          unfulfilledFabric,
          totalSaleConsumption,
          fabricAmt,
          unit,
          fabricStock,
          vendorStock,
          totalStock,
          vendorPO,
          perDaySalesData,
          totalRequired,
          daysOfFabricAvailable,
          daysOfFabricReq,
          perDayConsumption,
        };
        acc.push(obj);
      } else {
        const obj = {
          fabricName: m.fabricName,
          total45DaySales: 0,
          totalSaleConsumption: 0,
          colorName: m.colorName,
          unfulfilledFabric: 0,
          fabricAmt: 0,
          unit: m.unit,
          fabricStock: 0,
          vendorStock: 0,
          totalStock: 0,
          vendorPO: 0,
          perDaySalesData: days45.reduce(
            (acc, day) => ({ ...acc, [day]: 0 }),
            {}
          ),
          totalRequired: 0,
          daysOfFabricAvailable: 0,
          daysOfFabricReq: 0,
          perDayConsumption: 0,
        };
        acc.push(obj);
      }
      return acc;
    }, []);
    setTableData(materialInfos);
  }, [data, materialWithPO, vendorMaterials]);

  useEffect(() => {
    const newData = materialData.map((m) => ({
      ...m,
      skuPO: 0,
      vendorPO: 0,
    }));
    setMaterialWithPO(newData);
  }, [materialData]);
  return (
    <div>
      <FabricPOTable
        tableData={tableData}
        setMaterialWithPO={setMaterialWithPO}
      />
    </div>
  );
};

export default FabricDataGenerate;

// const perDayConsumption = days45.reduce((acc, day) => {
//   if (day === "45") {
//     return acc;
//   } else {
//     const newAmt = (acc + perDaySalesData[day]) / 2;
//     return parseFloat(parseFloat(newAmt).toFixed(2));
//   }
// }, perDaySalesData["45"]);
