import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Chip,
  OutlinedInput,
  Switch,
  FormControlLabel,
  LinearProgress,
} from "@mui/material";
import { ordersByQuery } from "../../../actions/influencerActions";

const OrdersTable = ({
  codes,
  triggerShowOrders,
  setTriggerShowOrders,
  onTotalChange,
}) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [showOnlySubtotal, setShowOnlySubtotal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrdersBatch = async (codesBatch, totalCodes) => {
    for (const code of codesBatch) {
      const searchQuery = `discount_code:${code}`;
      const fetchedOrders = await ordersByQuery(searchQuery);

      const formattedOrders = fetchedOrders.map((o) => ({
        id: o.name,
        total: parseFloat(o.totalPriceSet.presentmentMoney.amount),
        shipping: parseFloat(o.totalShippingPriceSet.presentmentMoney.amount),
        discountCode: code,
      }));

      setOrders((prev) => [...prev, ...formattedOrders]);
      setProgress((prev) => prev + 100 / totalCodes);
    }
  };

  useEffect(() => {
    if (triggerShowOrders) {
      setOrders([]);
      setProgress(0);
      setIsLoading(true);

      const batchSize = 5;
      const fetchOrdersSequentially = async () => {
        for (let i = 0; i < codes.length; i += batchSize) {
          const batch = codes.slice(i, i + batchSize);
          await fetchOrdersBatch(batch, codes.length);
        }
        setIsLoading(false);
        setTriggerShowOrders(false);
      };

      fetchOrdersSequentially();
    }
  }, [triggerShowOrders]);

  useEffect(() => {
    if (selectedCodes.length > 0) {
      setFilteredOrders(
        orders.filter((order) => selectedCodes.includes(order.discountCode))
      );
    } else {
      setFilteredOrders(orders);
    }
  }, [selectedCodes, orders]);

  const totalAmount = filteredOrders.reduce(
    (acc, order) => acc + order.total,
    0
  );
  const totalShipping = filteredOrders.reduce(
    (acc, order) => acc + order.shipping,
    0
  );

  useEffect(() => {
    if (onTotalChange) {
      onTotalChange({ totalAmount, totalShipping });
    }
  }, [totalAmount, totalShipping, onTotalChange]);

  return (
    <Box mt={4}>
      {/* Progress Bar */}
      {isLoading && (
        <Box mb={2}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}

      <Typography variant="h6" fontWeight={600} align="center" mb={2}>
        Orders By Discount Codes
      </Typography>

      {/* Filter Bar & Toggle Switch */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <FormControl fullWidth>
          <InputLabel>Select Discount Codes</InputLabel>
          <Select
            multiple
            value={selectedCodes}
            onChange={(e) => setSelectedCodes(e.target.value)}
            input={<OutlinedInput label="Select Discount Codes" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((code) => (
                  <Chip key={code} label={code} />
                ))}
              </Box>
            )}
          >
            {codes.map((code, index) => (
              <MenuItem key={index} value={code}>
                {code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Switch
              checked={showOnlySubtotal}
              onChange={() => setShowOnlySubtotal(!showOnlySubtotal)}
              color="primary"
            />
          }
          label="Show Only Subtotal"
        />
      </Box>

      {/* Orders Table */}
      {orders.length === 0 ? (
        <Typography align="center" sx={{ mt: 2, fontStyle: "italic" }}>
          No orders available. Please run the query.
        </Typography>
      ) : filteredOrders.length === 0 ? (
        <Typography align="center" sx={{ mt: 2, fontStyle: "italic" }}>
          No orders match the selected discount codes.
        </Typography>
      ) : showOnlySubtotal ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Total Price</b>
                </TableCell>
                <TableCell>
                  <b>Shipping</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>₹{totalAmount.toFixed(2)}</b>
                </TableCell>
                <TableCell>
                  <b>₹{totalShipping.toFixed(2)}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Order ID</b>
                </TableCell>
                <TableCell>
                  <b>Total Price</b>
                </TableCell>
                <TableCell>
                  <b>Shipping</b>
                </TableCell>
                <TableCell>
                  <b>Discount Code</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>₹{order.total.toFixed(2)}</TableCell>
                  <TableCell>₹{order.shipping.toFixed(2)}</TableCell>
                  <TableCell>{order.discountCode}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <b>Total</b>
                </TableCell>
                <TableCell>
                  <b>₹{totalAmount.toFixed(2)}</b>
                </TableCell>
                <TableCell>
                  <b>₹{totalShipping.toFixed(2)}</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default OrdersTable;
