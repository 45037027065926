import {
  Box,
  Button,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { editProcessedQntyNote } from "../../actions/POActions";

const UnfulfilledPONote = ({ note, processedQnty, comments, setTableData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [comment, setComment] = useState(note);
  const noteRef = useRef(null);

  const open = Boolean(anchorEl);
  const openMenu = Boolean(menuAnchorEl);

  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setComment(note);
  }, [note]);

  const handleClick = (event) => {
    if (comments && comments.length > 0) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(noteRef.current);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSave = async () => {
    const payload = {
      sku: processedQnty.barcode,
      note: comment,
    };
    const res = await editProcessedQntyNote(payload);
    if (res.statusCode === 200) {
      // setTable data
      setTableData((prev) =>
        prev.map((p) => {
          console.log(p.sku, processedQnty.barcode);
          if (p.sku === processedQnty.barcode) {
            return {
              ...p,
              note: comment,
            };
          } else {
            return p;
          }
        })
      );
    }
    setMenuAnchorEl(null);
    setAnchorEl(null);
  };

  const handleMenuSave = async (val) => {
    const newNote = comment ? `${comment}, ${val}` : val;
    const payload = {
      sku: processedQnty.barcode,
      note: newNote,
    };
    const res = await editProcessedQntyNote(payload);
    if (res.statusCode === 200) {
      // setTable data
      setTableData((prev) =>
        prev.map((p) => {
          console.log(p.sku, processedQnty.barcode);
          if (p.sku === processedQnty.barcode) {
            return {
              ...p,
              note: newNote,
            };
          } else {
            return p;
          }
        })
      );
    }
    setMenuAnchorEl(null);
  };

  const handleCustomSelect = (e) => {
    setAnchorEl(null);
    setAnchorEl(noteRef.current);
  };
  return (
    <div>
      <Box
        ref={noteRef}
        onClick={handleClick}
        sx={{
          cursor: "pointer",
        }}
      >
        {note ? (
          <Box
            sx={{
              border: "0.3px solid gray",
              borderRadius: "5px",
              padding: "3px",
              my: 1,
            }}
          >
            <h5>Note :</h5>
            {note}
          </Box>
        ) : (
          <Button>Add Note</Button>
        )}
      </Box>
      {/* defined comments */}
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {(comments ?? []).map((c, i) => (
          <MenuItem key={i} onClick={() => handleMenuSave(c)}>
            {c}
          </MenuItem>
        ))}
        <MenuItem onClick={handleCustomSelect}>Custom</MenuItem>
      </Menu>
      {/* custom comment */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Typography>Note</Typography>
          <br />
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            size="medium"
          />
          <br />
          <Button variant="contained" color="secondary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

export default UnfulfilledPONote;
