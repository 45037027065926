import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  TextField,
  MenuItem,
  Box,
  Backdrop,
  Checkbox,
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import Select from "@mui/material/Select";
import OrderTakeout from "../delhivery/ordersWidgets/OrderTakeout";
import {
  unfulfilledPartialOrders,
  getPincodesDelhivery,
  getForwardSyncOrders,
  getProcessingOrdersData,
  getAllRtoOrders,
} from "../../actions/ordersActions";
import { allInventory } from "../../actions/inventoryActions";
import RedirectLogin from "../RedirectLogin";
import DateSelector from "../ordersWidgets/DateSelector";
import { indianStates, productTypes } from "../../utils/helperArrays";
import "./index.css";
import { allProducts } from "../../actions/productsActions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DispatchInDelhivery = () => {
  const { stock } = useSelector((state) => state.inventoryDetails);
  const {
    pincodesDelhivery: pincodes,
    inTransitOrders,
    unfulfilled: unfulfilled_orders,
    processingOrders,
    fulfillmentLoading,
  } = useSelector((state) => state.orderDetails);
  const loading = useSelector((state) => state.loader.loading);

  const [minDate, setMinDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]
  );
  const [maxDate, setMaxDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [productType, setProductType] = useState("");
  const [workingStock, setWorkingStock] = useState([]);

  const [includePincode, setIncludePincode] = useState(false);
  const [inTransitLength, setInTransitLength] = useState(0);

  const [isByState, setIsByState] = useState(false);
  const [stateSelected, setStateSelected] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const products = useSelector((state) => state.productDetails.products);

  useEffect(() => {
    if (!auth) return;

    if (!stock.length > 0) dispatch(allInventory());
    // if (!inTransitOrders.length > 0) dispatch(getForwardSyncOrders());
    if (!inTransitOrders.length > 0) dispatch(getAllRtoOrders());
    if (!processingOrders.length > 0) dispatch(getProcessingOrdersData());
    if (!pincodes.length > 0) dispatch(getPincodesDelhivery());
    if (!products.length > 0 && auth) dispatch(allProducts());
  }, [auth]);

  useEffect(() => console.log("PIND", pincodes), [pincodes]);

  useEffect(() => {
    if (productType === "") {
      const stockAmt = stock.filter(
        (s) =>
          "length" in s &&
          "breadth" in s &&
          "height" in s &&
          s.length !== 0 &&
          s.breadth !== 0 &&
          s.height !== 0 &&
          s.XSDeadWeight &&
          s.XLDeadWeight
      );
      setWorkingStock(stockAmt);
    } else {
      setWorkingStock(
        stock.filter(
          (p) =>
            p.type === productType &&
            "length" in p &&
            "breadth" in p &&
            "height" in p &&
            p.length !== 0 &&
            p.breadth !== 0 &&
            p.height !== 0 &&
            p.XSDeadWeight &&
            p.XLDeadWeight
        )
      );
    }
  }, [stock, productType]);

  const searchOrders = () => {
    dispatch(unfulfilledPartialOrders(minDate, maxDate));
  };

  const clearOrders = () =>
    dispatch({
      type: "CLEAR_UNFULFILLED",
    });

  useEffect(() => {
    const total = inTransitOrders.length;
    const totalRTD = inTransitOrders.filter(
      (o) => o.package_status === "RTD"
    ).length;
    const actualInTransit = total - totalRTD;
    setInTransitLength(actualInTransit);
  }, [inTransitOrders]);

  const handleChange = (e) => {
    setStateSelected(e.target.value);
  };
  console.log("states", stateSelected);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <h1>Loading. Please Wait...</h1>
            </Backdrop>
          )}

          {fulfillmentLoading ? (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={fulfillmentLoading}
            >
              <h1>Fulfillment in progress. Donot close or press back!</h1>
            </Backdrop>
          ) : (
            <>
              {stock.length > 0 && (
                <div className="flexRow">
                  <h4 style={{ flex: 1 }}>Dispatch by Category</h4>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      flex: 1,
                    }}
                  >
                    <TextField
                      select
                      label="Select Product Category"
                      value={productType}
                      onChange={(e) => setProductType(e.target.value)}
                      variant="outlined"
                      className="select"
                      style={{
                        width: "400px",
                        background: "white",
                      }}
                    >
                      {productTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </div>
              )}
              <div className="flexRow">
                <div>
                  <h4>Search By Date Range (Default last 30 days)</h4>
                  <DateSelector
                    handleMinChange={setMinDate}
                    handleMaxChange={setMaxDate}
                  />
                  <br />
                  <Stack
                    direction={"row"}
                    gap={1}
                    sx={{ alignItems: "center" }}
                  >
                    <h3>Include Pincodes</h3>
                    <Checkbox
                      checked={includePincode}
                      onChange={(e) => setIncludePincode(!includePincode)}
                    />
                  </Stack>
                  {/* states select*/}
                  <Box my={1}>
                    <Stack
                      direction={"row"}
                      gap={1}
                      sx={{ alignItems: "center" }}
                    >
                      <h3>Include States</h3>
                      <Checkbox
                        checked={isByState}
                        onChange={(e) => setIsByState(!isByState)}
                      />
                    </Stack>
                    {isByState && (
                      <FormControl sx={{ my: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          States
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={stateSelected}
                          onChange={handleChange}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                          sx={{
                            background: "white",
                          }}
                        >
                          {indianStates.map((name, key) => (
                            <MenuItem key={key} value={name}>
                              <Checkbox
                                checked={stateSelected.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                  <br />
                  <div className="flexRow">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={searchOrders}
                    >
                      Search Orders
                    </Button>
                    <Button
                      sx={{ left: "10px" }}
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={clearOrders}
                    >
                      Clear Orders
                    </Button>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  {stock.length > 0 ? (
                    <h4>Total Stock : {workingStock.length}</h4>
                  ) : (
                    <p>Loading Stock...</p>
                  )}
                  {inTransitOrders.length > 0 ? (
                    <h4 style={{ paddingLeft: "30px" }}>
                      {/* Total In Transit : {inTransitLength} */}
                      Total RTO : {inTransitLength}
                    </h4>
                  ) : (
                    <p style={{ paddingLeft: "30px" }}>
                      {/* Loading In-Transit Orders... */}
                      Loading RTO Orders...
                    </p>
                  )}
                </div>
              </div>
              {unfulfilled_orders.length > 0 &&
                workingStock.length > 0 &&
                products.length > 0 &&
                inTransitOrders.length > 0 && (
                  <OrderTakeout
                    orderData={unfulfilled_orders}
                    stock={workingStock}
                    inTransitOrders={[...inTransitOrders, ...processingOrders]}
                    pincodes={pincodes}
                    includePincode={includePincode}
                    states={stateSelected}
                    includeStates={isByState}
                  />
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default DispatchInDelhivery;
