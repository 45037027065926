import React, { useState } from "react";
import { Modal, Box, Paper, Button, Alert } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { sendToReconQueue, updateRecon } from "../../actions/reconActions";

export const UpdateInventoryModal = ({
  open,
  setOpenInventoryUpdateModal,
  orderData,
  setIsInQueue
}) => {
  const [openUp, setOpenUp] = useState(open);


  const newOrderData = orderData.map(({ fulfillment_id, type, line_items }) => ({
    fulfillment_id,
    type,
    line_items: line_items.map(({ units, sku, size }) => ({
      units,
      sku: sku.split(".")[0], 
      size: type === "R/E" ? size : sku.split(".")[1] 
    }))
  }));
  console.log("newOrderData",  newOrderData)

  const formattedData = { orders: newOrderData };


  const handleClose = () => {
    setOpenUp(false);
    setOpenInventoryUpdateModal(false);
  };

  const handleUpdateAll = () => {
    console.log("Updating inventory...");
    const resRecon = updateRecon("0","inQueue", newOrderData.length)
    const resQueue = sendToReconQueue(formattedData)
    if(resRecon){
      setIsInQueue(true)
    }
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "whitesmoke",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "scroll",
    maxWidth: "90%",
  };

  return (
    <Modal
      open={openUp}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h3>Are You Sure?</h3>

        <Paper
          elevation={3}
          sx={{
            padding: 2,
            borderRadius: "8px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Alert
            severity="error"
            icon={<WarningIcon fontSize="inherit" />}
            sx={{ fontWeight: "bold", color: "red" }}
          >
            By clicking the **Update All** button, all the items in the orders
            listed in the table will be updated in the inventory count.
          </Alert>
        </Paper>

        <Box mt={3}>
          <Button
            variant="contained"
            color="error"
            onClick={handleUpdateAll}
            sx={{ fontWeight: "bold", mr: 2 }}
          >
            Update All
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};



