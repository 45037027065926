import React, { useState, useEffect } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableFooter,
  TablePagination,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../utils/TablePaginationActions";
import {
  cancelAndPnrOrder,
  cancelDelhiveryShipment,
  deleteOrder,
  markAndCloseFulfillment,
  packetNotReady,
  packetNotReadySelectedProducts,
  revertOrderFulfillment,
  revertStock,
} from "../../actions/ordersActions";
import ConfirmPopup from "../ordersWidgets/ConfirmPopup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

function OrderActionsSelector({ orders, reset, removeFromTable, updateTable }) {
  const [popupVisible, setPopupVisible] = useState(false);
  const { stock } = useSelector((state) => state.inventoryDetails);
  const { dispatch } = useDispatch();

  const pnrSelectedProducts = async () => {
    console.log("pnr selected", orders[0]);
    await cancelDelhiveryShipment(orders[0].awb_code);
    const order = orders[0];
    //create new order
    const newOrderItems = order.order_items.filter(
      (oi) => !order.pnrItems.find((p) => p.sku === oi.sku)
    );
    const newOrder = {
      ...orders[0],
      revertedItems: newOrderItems.map((o) => o.sku),
      pnrItems: order.pnrItems.map((o) => o.sku),
      cancel_type: "PR",
      package_status: "PR_Cancelled",
      mark_cancel_date: moment().format(),
    };
    const revertedOrder = {
      ...orders[0],
      order_items: newOrderItems,
    };
    console.log(newOrder);

    //revert fulfillment
    const data = await packetNotReadySelectedProducts(
      orders[0],
      newOrder,
      revertedOrder
    );
    if (data.statusCode === 200) {
      updateTable(newOrder);
      reset([]);
    }
  };

  const deleteProcessing = async () => {
    await cancelDelhiveryShipment(orders[0].awb_code);
    const data = await revertOrderFulfillment(orders[0]);
    if (data && data.statusCode === 200) {
      removeFromTable(orders[0]);
    }
    setPopupVisible(false);
    reset([]);
  };
  const handleChange = async (e) => {
    if (e.target.value === 1) {
      // revert fulfillment
      setPopupVisible(true);
    } else if (e.target.value === 2) {
      // revert stock
      await cancelDelhiveryShipment(orders[0].awb_code);
      const data = await revertStock(orders[0]);
      if (data && data.statusCode === 200) {
        removeFromTable(orders[0]);
        reset([]);
      }
    } else if (e.target.value === 3) {
      // packet not ready
      await cancelDelhiveryShipment(orders[0].awb_code);
      const data = await packetNotReady(orders[0]);
      if (data && data.statusCode === 200) {
        removeFromTable(orders[0]);
        reset([]);
      }
    } else if (e.target.value === 4) {
      // cancel order
      await cancelDelhiveryShipment(orders[0].awb_code);
      console.log("cancel order", orders[0]);
      const data = await markAndCloseFulfillment(orders[0]);
      if (data && data.statusCode === 200) {
        removeFromTable(orders[0]);
        reset([]);
      }
    } else if (e.target.value === 5) {
      // cancel order , not revert stock
      await cancelDelhiveryShipment(orders[0].awb_code);
      console.log("cancel order", orders[0]);
      const data = await cancelAndPnrOrder(orders[0]);
      if (data && data.statusCode === 200) {
        removeFromTable(orders[0]);
        reset([]);
      }
    } else {
      // delete
      await cancelDelhiveryShipment(orders[0].awb_code);
      const data = await deleteOrder(orders[0]);
      if (data && data.statusCode === 200) {
        removeFromTable(orders[0]);
        reset([]);
      }
    }
  };
  if (orders[0].pnrItems && orders[0].pnrItems.length > 0) {
    return (
      <Button
        sx={{ my: 2, mx: 2 }}
        onClick={pnrSelectedProducts}
        variant="outlined"
      >
        Pnr Selected Products
      </Button>
    );
  } else {
    return (
      <FormControl variant="outlined" size="small" sx={{ m: 2, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-label">Actions</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={""}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={1}>Revert Fulfillment</MenuItem>
          <MenuItem value={2}>Revert Stock</MenuItem>
          <MenuItem value={3}>Packet Not Ready</MenuItem>
          <MenuItem value={4}>Manually mark close fulfillment</MenuItem>
          <MenuItem value={5}>Cancel and Packet not ready</MenuItem>
          <MenuItem value={6}>Delete</MenuItem>
        </Select>
        <ConfirmPopup
          visible={popupVisible}
          cancel={() => setPopupVisible(false)}
          proceed={deleteProcessing}
        />
      </FormControl>
    );
  }
}
export default function OrderTable({ tableData, shippingPartner }) {
  const [data, setData] = useState(
    tableData?.sort(
      (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
    )
  );
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [open, setOpen] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      setOpen(Array(tableData.length).fill(false));
    }
    setData(
      [...tableData]
        ?.sort(
          (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
        )
        .reverse()
    );
  }, [tableData]);

  const removeFromTable = (order) => {
    const filtered = data.filter(
      (d) => d.fulfillment_id !== order.fulfillment_id
    );
    setData(filtered);
  };
  const updateTable = (order) => {
    console.log("updating", order);
    const filtered = data.map((o) => {
      if (o.fulfillment_id === order.fulfillment_id) {
        return order;
      } else {
        return o;
      }
    });
    setData(filtered);
  };
  const handlePnrOrderChange = (e, order, item) => {
    //check if the action is checked
    if (e.target.checked) {
      //map
      const newSelectedOrders = selectedOrders.map((o) => {
        if (o.fulfillment_id === order.fulfillment_id) {
          return {
            ...o,
            pnrItems: o.pnrItems ? [...o.pnrItems, item] : [item],
          };
        } else {
          return o;
        }
      });
      setSelectedOrders(newSelectedOrders);
    } else {
      const newSelectedOrders = selectedOrders.map((o) => {
        if (o.fulfillment_id === order.fulfillment_id) {
          const newItems = o.pnrItems.filter((p) => p.sku !== item.sku);
          return {
            ...o,
            pnrItems: newItems.length > 0 ? newItems : undefined,
          };
        } else {
          return o;
        }
      });
      setSelectedOrders(newSelectedOrders);
    }
  };
  return (
    <>
      {data.length > 0 ? (
        <>
          {selectedOrders.length === 1 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h4>Selected Action : </h4>
              <OrderActionsSelector
                orders={selectedOrders}
                reset={setSelectedOrders}
                removeFromTable={removeFromTable}
                updateTable={updateTable}
              />
            </div>
          ) : (
            <h4>Select only one order to perform actions.</h4>
          )}
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {tableData.length} Items
                    <br /> Select
                  </TableCell>
                  <TableCell>Order Number</TableCell>
                  <TableCell>AWB Code</TableCell>
                  <TableCell>Processed At</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell>Package Status</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Action Type</TableCell>
                  <TableCell>Action Date</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>List</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      key={index}
                      sx={{
                        bgcolor:
                          row.status === "fulfilled"
                            ? "rgba(145,145,145, 0.2)"
                            : index % 2 === 0
                            ? "#afd275"
                            : "#edf5e1",

                        "& > *": {
                          borderBottom: "unset",
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Checkbox
                          color="primary"
                          checked={
                            selectedOrders.filter(
                              (i) => i.fulfillment_id === row.fulfillment_id
                            ).length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedOrders([...selectedOrders, row]);
                            } else {
                              setSelectedOrders(
                                selectedOrders.filter(
                                  (item) =>
                                    item.fulfillment_id !== row.fulfillment_id
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {row.original_order_id} (#
                        {row.fulfillment_id})
                      </TableCell>
                      <TableCell>
                        {row.awb_code ? row.awb_code : "No awb"}
                      </TableCell>
                      <TableCell>
                        {new Date(row.processed_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{row.payment}</TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "blue",
                        }}
                      >
                        {row.package_status}
                      </TableCell>

                      <TableCell>{row.total}</TableCell>
                      <TableCell>
                        {row.cancel_type === "PNR" || row.cancel_type === "PR"
                          ? "packet not ready"
                          : row.cancel_type === "R"
                          ? "Reverted"
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.mark_cancel_date
                          ? new Date(row.mark_cancel_date).toLocaleDateString(
                              "en-in"
                            )
                          : "N/A"}
                      </TableCell>
                      <TableCell>{row.total}</TableCell>
                      <TableCell>{row.order_items?.length} Items</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            const temp = open.map((i, openIndex) => {
                              if (index !== openIndex) {
                                return i;
                              } else {
                                return !i;
                              }
                            });
                            setOpen(temp);
                          }}
                        >
                          {open[index] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                        }}
                        colSpan={6}
                      >
                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Product Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>SKU</TableCell>
                                  <TableCell>Size</TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell>MRP</TableCell>
                                  <TableCell>Discount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.order_items?.map((productRow) => (
                                  <TableRow key={productRow.sku}>
                                    <TableCell component="th" scope="row">
                                      {!!selectedOrders.find(
                                        (o) =>
                                          o.fulfillment_id ===
                                          row.fulfillment_id
                                      ) && (
                                        <Checkbox
                                          checked={selectedOrders.find(
                                            (o) =>
                                              o.fulfillment_id &&
                                              productRow.fulfillment_id &&
                                              o.pnrItems &&
                                              !!o.pnrItems.find(
                                                (pi) =>
                                                  pi.sku === productRow.sku
                                              )
                                          )}
                                          onChange={(e) =>
                                            handlePnrOrderChange(
                                              e,
                                              row,
                                              productRow
                                            )
                                          }
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell>{productRow.name}</TableCell>
                                    <TableCell>{productRow.sku}</TableCell>
                                    <TableCell>
                                      {productRow.variant_title}
                                    </TableCell>
                                    <TableCell>{productRow.units}</TableCell>
                                    <TableCell>
                                      {productRow.selling_price}
                                    </TableCell>
                                    <TableCell>{productRow.discount}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h4>Shipping Address:</h4>
                            <p>
                              Name:{" "}
                              {row.billing_customer_name +
                                " " +
                                row.billing_last_name}
                              <br />
                              Mobile: {row.shipping_phone}
                              <br />
                              Address: {row.shipping_address}
                              <br />
                              {row.shipping_address_2}
                              <br />
                              {row.shipping_city} | {row.shipping_state}
                              <br />
                              {row.shipping_country}
                              <br />
                              {row.shipping_pincode}
                            </p>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ overflow: "inherit" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        "No Under Process Orders At Present"
      )}
    </>
  );
}
