import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialFabricList,
  getMaterialList,
} from "../../actions/materialActions";
import { MenuItem, Paper, Stack, TextField } from "@mui/material";
import { toast } from "react-toastify";
import UpdateMaterialForm from "./modifyMaterial/UpdateMaterialForm";

const UpdateMaterial = () => {
  const [colors, setColors] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedFabric, setSelectedFabric] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const { materialFabricList } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (materialFabricList.length === 0) dispatch(getMaterialFabricList());
    // if (materialColorList.length === 0) dispatch(getMaterialColorList());
  }, []);
  useEffect(() => {
    if (selectedFabric) {
      setColors([]);
      setMaterials([]);
      getMaterialList(selectedFabric).then((data) => {
        if (data.statusCode === 200) {
          const colors = data.body.map((m) => ({
            code: m.colorId,
            name: m.colorName,
          }));
          if (colors.length === 0) {
            toast.error("No colors found for this fabric");
          }
          setMaterials(data.body);
          setColors(colors);
        }
      });
    }
  }, [selectedFabric]);
  useEffect(() => {
    if (selectedFabric && selectedColor && materials.length > 0) {
      setSelectedMaterial(
        materials.find(
          (m) => m.fabricId === selectedFabric && m.colorId === selectedColor
        )
      );
    }
  }, [selectedFabric, selectedColor, materials]);
  console.log("selected material", selectedMaterial);
  return (
    <div>
      {materialFabricList.length > 0 && (
        <div>
          <Stack direction={"row"} gap={2}>
            {/* fabric */}
            <TextField
              style={{ width: "250px" }}
              select
              label="Select Fabric"
              value={selectedFabric}
              onChange={(e) => setSelectedFabric(e.target.value)}
              variant="standard"
              className="select"
              // fullWidth
            >
              {materialFabricList.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            {/* color */}
            <TextField
              style={{ width: "250px" }}
              disabled={colors.length === 0}
              select
              label="Select Color"
              value={selectedColor}
              onChange={(e) => setSelectedColor(e.target.value)}
              variant="standard"
              className="select"
              // fullWidth
            >
              {colors.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          {selectedMaterial && (
            <Paper sx={{ my: 2 }}>
              <h2
                style={{ textAlign: "center" }}
              >{`${selectedMaterial.fabricName} ${selectedMaterial.colorName}`}</h2>
              <Stack
                direction={"row"}
                gap={2}
                sx={{ justifyContent: "center" }}
              >
                <div>
                  <span style={{ fontWeight: "bold" }}>Unit:</span>
                  {selectedMaterial.unit}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>Unit Value:</span>
                  {selectedMaterial.unitValue}
                </div>
              </Stack>
              <UpdateMaterialForm
                material={selectedMaterial}
                setMaterials={setMaterials}
                allMaterials={materials}
              />
            </Paper>
          )}
        </div>
      )}
    </div>
  );
};

export default UpdateMaterial;
