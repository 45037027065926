import React, { useEffect, useState } from 'react'
import Filter from "./Filter"
import InventoryTable from './InventoryTable'

// Create DispatchedOrder LineItems || check per order per line_items
const createDispatchedOrderLineItems = (acc, order_item) => {
  const sku = order_item.sku.split(".")[0]
  
    const found = acc.find((ac) => ac.sku === sku && ac.size === order_item.variant_title)
    console.log("found", found)
    if (found) {
      found.count = found.count + order_item.units
    } else {
      const newObj = {
        sku,
        size: order_item.variant_title,
        count: order_item.units
      }
      acc.push(newObj)
    }
  return acc
}


//// Create reconOrder LineItems || check per order per line_items
const createReconLineItems = (acc, row) => {
  // const orderItems = row.line_items
  // orderItems.forEach((order_item) => {
    //per order item , sku , variant_title  and units
    // const [sku, size] = row.sku.split(".") 
    const sku = row.sku;
    const size = row.size;

    const found = acc.find((ac) => ac.sku === sku && ac.size === size)
    if (found) {
      found.count = found.count + row.count
    } else {
      const newObj = {
        sku,
        size:  size,
        count: row.count
      }
      acc.push(newObj)
    }
  // })
  return acc
}

//check if per order per line_items is damaged
const createReconDamagedLineItems = (acc, row) => {
  // const orderItems = row.line_items
  // orderItems.forEach((order_item) => {
    // if ("isDamaged" in order_item) {
      //per order item , sku , variant_title  and units
      // const [sku, variant_title] = order_item.sku.split(".")
      const sku = row.sku.split(".")[0];
    const size = row.size;
    let count = row.count;

      const found = acc.find((ac) => ac.sku === sku && ac.size === size)
      if (found) {
        found.count = found.count + row.count
      } else {
        const newObj = {
          sku,
          size: size,
          count: row.count,
        }
        acc.push(newObj)
      }
    // }

  // })
  return acc
}

const InventoryManagement = ({
  inventory,
  dispatchedOrders,
  reconOrders,
  openingStock,
  poStockHistory,
  stockHistory,
  damagedOrders,
  revertOrders,
  reInventoryOrders
}) => {
  const [inventoryManagementDetails, setInventoryManagementDetails] = useState([])
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    console.log("inventoryManagementDetails :", inventoryManagementDetails.filter((f)=> f.sku === "WC1206"))
  },[inventoryManagementDetails])
  useEffect(() => {
    console.log("openingStock from inventory management :", openingStock)
    // console.log("reInventoryOrders from inventory management : ", reInventoryOrders)
    console.log("inventory ", inventory)
    //dispatched order line items 
    // const dispatchedOrderItems = dispatchedOrders.filter((o) => o.package_status !== "R_CANCELLED").reduce(createDispatchedOrderLineItems, [])
    const dispatchedOrderItems = dispatchedOrders.reduce((acc,o) => ([...acc, ...o.order_items]),[])   .reduce(createDispatchedOrderLineItems, [])
    console.log("dispatchedOrderItems : ------> ", dispatchedOrderItems.filter((f) => f.sku === "WC1206"))
    //recon rto line items
    const reconOrderItemsForRTO = reconOrders.filter((o) =>  o.entryType === "RTO").reduce(createReconLineItems, [])

    // console.log("reconOrderItemsForRTO", reconOrders.filter((o) => o.type === "RTO"))
    //recon R/E line items
    const reconOrderItemsForRE = reconOrders.filter((o) => o.entryType === "R/E").reduce(createReconLineItems, [])
    // po
    // const poHistoryItems = dispatchedOrders.filter((o) => true).reduce(createPoHistoryItems, [])
    //recon damaged line items
    const damagedReconOrderItems = damagedOrders.reduce(createReconDamagedLineItems, [])
    // inventory with all details (opening , dispatched , rto , r/e , po)
    const inventoryWithDetails = inventory.filter(o => "SKU" in o && "count" in o && "size" in o).map((row) => {
      const sku = row.SKU
      const inventoryAmt = row.count
      const size = row.size
      //dispatch order amt for this sku and size
      const dispatchedAmt = dispatchedOrderItems.find(o => o.sku === sku && o.size === size)?.count || 0
      
      // po
      const poAmt = poStockHistory.find(o => o.barcode === row.barcode )?.count || 0
        // stock added today amt
      const stockAdded = stockHistory.find(o => o.barcode === row.barcode )?.count || 0
      // revert
      const revertAmt = revertOrders.find(o => o.barcode === row.barcode )?.count || 0     
      // reInventory, # for now add dispatched amt to get real reinventory amount, remove later 
      const reInventoryAmt = reInventoryOrders.find(o => o.barcode === row.barcode )?.reInventoryAmt + dispatchedAmt || 0
      //recon order amt for this sku and size
      const RTOAmt = reconOrderItemsForRTO.find(o => o.sku === sku && o.size === size)?.count || 0
      
      const REAmt = reconOrderItemsForRE.find(o => o.sku === sku && o.size === size)?.count || 0
      //opening stock amt for this sku and size
      const openingStockAmt = openingStock.find((o) => o.sku === sku && o.size === size)?.closing || 0
      // console.log("openingStockAmt : ", openingStockAmt)
      //Damaged amt for this sku and size
      const DamagedAmt = damagedReconOrderItems.find((o) => o.sku === sku && o.size === size)?.count || 0
      console.log("DamagedAmt", DamagedAmt)
      //closing
      const closing = openingStockAmt - dispatchedAmt + RTOAmt + REAmt + poAmt  + stockAdded + revertAmt + reInventoryAmt
      //later portion

      //
      return {
        sku,
        size,
        type: row.type,
        dispatchedAmt,
        RTOAmt,
        REAmt,
        openingStockAmt,
        inventoryAmt,
        closing,
        poAmt,
        DamagedAmt,
        stockAdded,
        revertAmt,
        reInventoryAmt,
        isMatched : inventoryAmt - closing === 0 ? true : false
      }
    })
    setInventoryManagementDetails(inventoryWithDetails)
    setFilteredData(inventoryWithDetails)
  }, [dispatchedOrders, reconOrders, inventory, openingStock, poStockHistory])
  console.log("table data", filteredData)
  
  return (
    <div>
      {/* filter component */}
      <Filter inventoryManagementDetails={inventoryManagementDetails} setFilteredData={setFilteredData} filteredData = {filteredData} />
      {/* table data component */}
      <InventoryTable filteredData={filteredData} setFilteredData={setFilteredData} />
    </div>
  )
}

export default InventoryManagement