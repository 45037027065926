import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";

const ReceiveAmtTable = ({ data, handleRemove }) => {
  return (
    <TableContainer sx={{ minWidth: 700, my: 2 }}>
      <Table
        stickyHeader
        aria-label="simple table"
        sx={{ border: "1px solid", borderColor: "#eaebfe" }}
      >
        <TableHead>
          <TableRow
            sx={{
              "& th": {
                color: "rgba(96, 96, 96)",
                backgroundColor: "#eaebfe",
                fontWeight: "bold",
                py: 1,
                fontSize: "15px",
              },
            }}
          >
            <TableCell align="center">Fabric</TableCell>
            <TableCell align="center">Color</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Unit Value</TableCell>
            <TableCell align="center">Prev Avg Amt</TableCell>
            <TableCell align="center">Roll amt</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        {data.length > 0 ? (
          <TableBody>
            {data.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="center" component="th" scope="row">
                  {row.fabricName}
                </TableCell>
                <TableCell align="center">{row.colorName}</TableCell>
                <TableCell align="center">{row.quantity}</TableCell>
                <TableCell align="center">{row.unitValue}</TableCell>
                <TableCell align="center">
                  {"prevAvgAmt" in row ? row.prevAvgAmt : "-"}
                </TableCell>
                <TableCell align="center">
                  {row.amt} {row.unit}
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      cursor: "pointer",
                      "& :hover": {
                        color: "red",
                      },
                    }}
                    onClick={() => handleRemove(row.fabricId, row.colorId)}
                  >
                    <DeleteOutline />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <Box
                py={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                No Materials Added
              </Box>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ReceiveAmtTable;
