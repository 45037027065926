import React, { useEffect, useState } from 'react';
import { getCatalogueReconDetailsByDate } from '../../actions/reconActions';
import { Box, Button, Chip, CircularProgress, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import DamageTable from './tables/DamageTable';
import { useNavigate } from 'react-router-dom';
import { AgCharts } from 'ag-charts-react';

const getLastSelectedDays = (day) => {
    let dates = [];
    const selectedDay = day ? day : 30;
    for (let i = 0; i < selectedDay; i++) {
        let date = new Date();
        date.setDate(date.getDate() - i);
        dates.push(date.toISOString().split('T')[0]);
    }
    return dates;
};

const PieChart = ({ data, isLoading }) => {
    const [options, setOptions] = useState({
        data: [
            { category: "RTO", value: data.RTOcount
            },
            { category: "RE", value: data.REcount },
        ],
        title: {
            text: "RTO vs R/E Damaged History",
        },
        series: [{
            type: "pie",
            angleKey: "value",
            calloutLabelKey: "category",
            sectorLabelKey: "value",
            sectorLabel: {
                color: "white",
                fontWeight: "bold",
                formatter: ({ value }) => value.toLocaleString(),
            },
            tooltip: {
                renderer: ({ datum }) => ({
                    content: `${datum.category}: ${datum.value}`
                })
            }
        }],
        legend: {
            enabled: true,
            position: "bottom"
        }
    });

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="60%" margin="auto">
            {/* <h3>RTO & R/E Damaged History</h3> */}
            {isLoading ? <CircularProgress /> : <AgCharts options={options} style={{ width: '150%' }} />}
        </Box>
    );
    // return <AgCharts options={options} />;
};

const BarGraph = ({data, isLoading}) => {
    const [options, setOptions] = useState({
        data: data,
            title: {
              text: "Total Visitors to Museums and Galleries",
            },
            footnote: {
              text: "Source: Department for Digital, Culture, Media & Sport",
            },
            series: [
              {
                type: "bar",
                xKey: "year",
                yKey: "visitors",
                label: {
                //   formatter: ({ value }) => formatNumber(value),
                },
              },
            ],
            axes: [
              {
                type: "category",
                position: "bottom",
                title: {
                  text: "Year",
                },
              },
              {
                type: "number",
                position: "left",
                title: {
                  text: "Total Visitors",
                },
                label: {
                //   formatter: ({ value }) => formatNumber(value),
                },
              },
            ],
    })

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="60%" margin="auto">
            {isLoading ? <CircularProgress /> : <AgCharts options={options} style={{ width: '150%' }} />}
        </Box>
    );
}

const DamagedHistory = () => {
    const [damagedData, setDamagedData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [noOfDays, setNoOfDays] = useState("30");
    const [lastSelectedDays, setLastSelectedDays] = useState([])
    const [selectedOrders ,setSelectedOrders] = useState([])
    const [selectedDate ,setSelectedDate] =  useState("")
    const [loading, setLoading] = useState(false)
    const [entryTypeAggData, setEntryTypeAggData] = useState({})

    

    const fetchData = async () => {
        setLoading(true);
        const damagedDataRes = await getCatalogueReconDetailsByDate(lastSelectedDays[lastSelectedDays.length - 1], "damage", "rangeDate");
        if (damagedDataRes.statusCode === 500) {
            setIsError(true);
            setLoading(false)
        } else {
            setDamagedData(damagedDataRes.body || []);
            setLoading(false)
        }
    };

    const handleToggleChange = (event, newAlignment) => {
        setNoOfDays(newAlignment);
      };
    
    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedOrders(damagedData.filter((d) => d.date.slice(0,10) === date))
    }

    const handleDateSubmit = async() => {
        const isWithin = selectedDate in lastSelectedDays;
        if(isWithin){
            setSelectedOrders(damagedData.filter((d) => d.date.slice(0,10) === selectedDate))
        }
        else{
            const damagedOrdersRes = await getCatalogueReconDetailsByDate(selectedDate, "damage", "singleDate");
            damagedOrdersRes.statusCode === 200 ? setSelectedOrders(damagedOrdersRes.body) : setSelectedOrders([])
        }
        
    }

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    useEffect(()=> {
        setLoading(true)
        const skuAgg = Object.values(
            damagedData.reduce((acc, { sku, count }) => {
              const originalSku = sku.split(".")[0]; 
              acc[originalSku] = acc[originalSku] || { sku: originalSku, count: 0 };
              acc[originalSku].count += count;
              return acc;
            }, {})
          );
        
          const entryTypeAgg = damagedData.reduce((acc, { entryType, count }) => {
            const key = entryType === "RTO" ? "RTOcount" : "REcount"; 
            acc[key] = (acc[key] || 0) + count;
            return acc;
          }, {});
          setEntryTypeAggData(entryTypeAgg)

          const dateAgg = Object.values(
            damagedData.reduce((acc, { date, count }) => {
              const formattedDate = date.slice(0, 10); 
              acc[formattedDate] = acc[formattedDate] || { date: formattedDate, count: 0 };
              acc[formattedDate].count += count;
              return acc;
            }, {})
          );
          setLoading(false)

        console.log("skuAgg", skuAgg)
        console.log("entryTypeAgg", entryTypeAgg)
        console.log("dateAgg", dateAgg)
    },[damagedData])

    useEffect(() => {
        fetchData();
    }, [lastSelectedDays]);

    useEffect(() => {
        setLastSelectedDays(getLastSelectedDays(noOfDays));
    }, [noOfDays]);

    useEffect(() => {
        console.log(damagedData);
    }, [damagedData]);

    useEffect(() => {
        console.log("selectedOrders",selectedOrders);
    }, [selectedOrders]);





    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" gutterBottom>
            Damaged History
        </Typography>
        <ToggleButtonGroup
            color="primary"
            value={noOfDays}
            exclusive
            onChange={handleToggleChange}
            aria-label="Platform"
        >
            <ToggleButton value="30">30 Days</ToggleButton>
            <ToggleButton value="60">60 Days</ToggleButton>
        </ToggleButtonGroup>
    </Box>
            <br></br>

            <Box>
                    <PieChart data={entryTypeAggData} isLoading={loading} />
                </Box>

            <br></br>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {lastSelectedDays.map((dd, i) => (
                    <Chip key={i} label={dd} variant="outlined" onClick={() => handleDateClick(dd)} sx={{ fontSize: 14, px: 1.5, py: 0.5 }} />
                ))}
            </Box>
            <br></br>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                <Box>
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                    <Button  variant="contained" onClick={handleDateSubmit}>Submit</Button>
                </Box>
            </Box>
            {loading ? <CircularProgress /> : <DamageTable data = {selectedOrders}/>}
            
        </Box>
    );
};

export default DamagedHistory;
