import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Backdrop,
  CircularProgress,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Chip,
  TableFooter,
  TablePagination,
  IconButton,
  Avatar,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  getAllScanSingleDate,
  replenishREorder,
  submitAwbScan,
  submitAwbSuccess,
} from "../../actions/reconActions";
import { updateReconProcessedAt } from "../../actions/reverseDeliveryActions";
import {
  replenishReverseorder,
  replenishRTOorder,
  updateRtoDeliveredOrders,
} from "../../actions/ordersActions"; //updateShopifyInventory and then replenishRTOorder(orderId)
import { searchAWB } from "../../actions/ordersActions";
import { useTheme } from "@emotion/react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";

const showToastMessage = (msg, isError) => {
  if (isError) {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <ArrowUpwardIcon />
        ) : (
          <ArrowDownwardIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const QuickScan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchTriggered = useRef(false);
  const ref = useRef();
  const orderData = useSelector((state) => state.recon.reconDetails);
  const { shopify_product_variants } = useSelector(
    (state) => state.shopifyDetails
  );

  const [searchAwbValue, setSearchAwbValue] = useState("");
  const [awbSearchLoading, setAwbSearchLoading] = useState(false);
  const [totalNumScanOrder, setTotalNumScanOrder] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({ key: "processed_at", direction: "desc" });
  const [isFocussed, setIdFocussed] = useState(true);
  const [sortedData, setSortedData] = useState([]);
  // Temporary storage of scanned AWBs
  const [scannedAWBs, setScannedAWBs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

 

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderData.length) : 0;

  // Ensure that the page is within the valid range
  useEffect(() => {
    const maxPage = Math.max(0, Math.ceil(orderData.length / rowsPerPage) - 1);
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [orderData.length, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  useEffect(() => {
    // setTotalNumScanOrder(orderData.length);

    setPage(0);

    if (orderData.length > 0) {
      setLoading(true)
      const newsortedData = [...orderData].sort((a, b) => {
        const valueA = a[sortConfig.key] ;
        const valueB = b[sortConfig.key] ;
        const dateA = new Date(valueA).getTime();
        const dateB = new Date(valueB).getTime();
    
        if (sortConfig.direction === "asc") {
          return dateA - dateB;
        }
        return dateB - dateA;
      });
      setSortedData(newsortedData);
      setLoading(false)
    } else if (!fetchTriggered.current) {
      fetchTriggered.current = true; // Mark fetch as triggered to prevent loops
      fetchData(); // Call fetch only once when orderData is empty
    } else {
      showToastMessage("No data available for the given date.", true);
      setLoading(false)
    }

  }, [orderData]);
       
  // HANDLE SORT CONFIG CHANGES
  useEffect(() => {
    if(orderData.length > 0){
      setLoading(true)
      const newsortedData = [...orderData].sort((a, b) => {
        const valueA = a[sortConfig.key] ;
        const valueB = b[sortConfig.key] ;
        const dateA = new Date(valueA).getTime();
        const dateB = new Date(valueB).getTime();
    
        if (sortConfig.direction === "asc") {
          return dateA - dateB;
        }
        return dateB - dateA;
      });
      setSortedData(newsortedData);
      setLoading(false)
    }
  }, [sortConfig])

  // FETCH RECON DETAILS
  const fetchData = () => {
    try {
      if (orderData.length > 0) return;
      // Dispatch the Redux action
      setLoading(true)
      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${String(
        today.getMonth() + 1
      ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
      // TO GET RECON DETAILS
      dispatch(getAllScanSingleDate(formattedDate, "scaning"));
      setLoading(false)
    } catch (err) {
      console.log("Error", err);
      setLoading(false)
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  

  const handleScanSubmit = async (e) => {
    e.preventDefault();
    // Step 0: AVOID DUPLICATE quick scanning
    const found = scannedAWBs.find(awb => awb === searchAwbValue)
    if(!!found){
      setSearchAwbValue("")
      // toast("Duplicate scanned!!!!")
      return
    }
    // Step 1. Temporarily store AWB in AWBs state
    setScannedAWBs(prev => ([...prev, searchAwbValue]))

    // Step 2: Make Asynchronous calls to handle DB entries
    const timeStamp = new Date().toISOString();
    const targetAWB = searchAwbValue
    // Clear Text Field
    setSearchAwbValue("");
// Promise based code alternative
    submitAwbScan(targetAWB, timeStamp)
      .then(order => {
        if (!order) {
          // setAwbSearchLoading(false);
          return;
        }
        // REMOVE AWB from list
        const awb = order?.awb_code
        setScannedAWBs(prev => prev.filter(a => a !== awb))

        console.log("order", order);
        
        dispatch(submitAwbSuccess(order));
      })  
      .catch(err => {
        showToastMessage("Failed to submit AWB scan", true);
        console.log("Error", err);
      }) 
      .finally(() => {
        setSearchAwbValue("");
        // setAwbSearchLoading(false);
        // ref?.current?.focus();
        setIdFocussed(false);
      }) 
  };

  useEffect(() => {
    if (!isFocussed) {
      setIdFocussed(true);
      ref?.current?.focus();
    }
  }, [isFocussed]);
  
  useEffect(()=> {
    const filteredData = sortedData.filter((d)=> d.processed_at.slice(0,10) === new Date().toISOString().slice(0,10))
    setFilteredData(filteredData)
    setTotalNumScanOrder(filteredData.length);
    console.log("Orders scanned today : ", filteredData)

  },[sortedData])

  const exportToExcel = async (type) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Scan RTO Data");

    worksheet.columns = [
      { header: "Sl No", key: "sl", width: 10 },
      { header: "AWB", key: "awb_code", width: 20 },
      { header: "Fulfillment ID", key: "fulfillment_id", width: 20 },
      { header: "Inscan Date", key: "sync_date", width: 30 },
      { header: "Inscan Time", key: "sync_time", width: 30 },
      { header: "Courier", key: "courier", width: 20 },
    ];
    const todayDateFormated = new Date().toISOString().slice(0,10)
    const RtoData = filteredData.filter((order) => order.type === type && order.processed_at.slice(0,10) === todayDateFormated);
    RtoData.forEach((order, index) => {
      worksheet.addRow({
        sl: index + 1,
        awb_code: order.awb_code,
        fulfillment_id: order.fulfillment_id,
        sync_date: new Date(order.processed_at).toLocaleDateString(
          "en-IN"
        ),
        sync_time: new Date(order.processed_at).toLocaleTimeString(
          "en-IN",
          { timeZone: "Asia/Kolkata" }
        ),
        courier: order?.courier_name || "N/A"
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob,  `scanData${type}${new Date().toISOString().split('T')[0]}.xlsx`);

    showToastMessage("Data exported to Excel successfully", false);
  };

  return (
    <>
      <h2
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        RTO Inscan
      </h2>
      {awbSearchLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={awbSearchLoading}
        >
          <CircularProgress size={100} color="inherit" />
        </Backdrop>
      )}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={3}
        gap={2}
      >
        <form
          onSubmit={handleScanSubmit}
          style={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            placeholder="Scan AWB Here"
            value={searchAwbValue}
            onChange={(e) => setSearchAwbValue(e.target.value)}
            disabled={awbSearchLoading}
            variant="outlined"
            style={{ width: 300, marginRight: "10px" }}
            inputProps={{ autoFocus: true }}
            inputRef={ref}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={awbSearchLoading}
          >
            Submit
          </Button>
        </form>

        <Button
          variant="contained"
          color="error"
          onClick={() => navigate("/temp")}
        >
          Back
        </Button>

        <Paper
          elevation={3}
          style={{
            padding: "10px 20px",
            gap: "8px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Chip
            label={new Date().toLocaleDateString("en-GB")}
            style={{
              textAlign: "center",
              backgroundColor: "red",
              color: "white",
              border: "1px solid",
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TaskAltIcon sx={{ mr: 1 }} />
            <h5>Total Number of Scanned Order: {totalNumScanOrder}</h5>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TaskAltIcon sx={{ mr: 1 }} />
            <h5>RTO Scanned: {filteredData.filter((d) => d.type === "RTO").length}</h5>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TaskAltIcon sx={{ mr: 1 }} />
            <h5>R/E Scanned: {filteredData.filter((d) => d.type === "R/E").length}</h5>
          </Box>
        </Paper>
      </Box>
            {/* HTML to show list of quickly scanned */}
            {scannedAWBs.length > 0 && <Paper elevation={3} style={{ gap: "8px", display: "flex", flexWrap: "wrap" }}>
                {scannedAWBs.map((awb,i) => <div key={i}>
                <Chip
            label={awb}
            style={{
              textAlign: "center",
              backgroundColor: "red",
              color: "white",
              border: "1px solid",
            }}
          /></div>)}
              </Paper>}
              <br/><br/>
      <Paper elevation={3} style={{ gap: "8px" }}>
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sl No</TableCell>
              <TableCell>AWB
              <IconButton onClick={() => handleSort("processed_at")}>
                  {sortConfig.key === "awb_code" &&
                  sortConfig.direction === "asc" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>Fulfillment ID
              <IconButton onClick={() => handleSort("processed_at")}>
                  {sortConfig.key === "fulfillment_id" &&
                  sortConfig.direction === "asc" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>
                Inscan Time
                <IconButton onClick={() => handleSort("processed_at")}>
                  {sortConfig.key === "processed_at" &&
                  sortConfig.direction === "asc" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Courier Name</TableCell>
              <TableCell>Orderd Items</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              (rowsPerPage > 0
                ? filteredData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredData.filter((d)=> d.processed_at.slice(0,10) === new Date().toISOString().slice(0,10))
              ).map((order, index) => (
                <TableRow key={index}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{order.awb_code}</TableCell>
                  <TableCell>{order.fulfillment_id}</TableCell>
                  <TableCell>
                    <span>
                      {new Date(order.processed_at).toLocaleDateString(
                        "en-IN"
                      )}
                    </span>
                    <br />
                    <span>
                      {new Date(order.processed_at).toLocaleTimeString(
                        "en-IN",
                        { timeZone: "Asia/Kolkata" }
                      )}
                    </span>
                  </TableCell>
                  <TableCell>{order.type}</TableCell>
                  <TableCell>{order?.courier_name || "N/A"}</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    {order?.line_items?.map((row, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Avatar
                          src={row.url}
                          sx={{
                            width: 60,
                            height: 60,
                            objectFit: "cover",
                          }}
                          variant="square"
                        />
                        <span style={{ marginLeft: "15px" }}>{row.sku}</span>
                        {/* <span>{row.product_id}</span> */}
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <CircularProgress />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      </Paper>

      <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => exportToExcel("RTO")}
        >
          Export to Excel (RTO)
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => exportToExcel("R/E")}
        >
          Export to Excel (R/E)
        </Button>
        <Button
          variant="contained"
          color="secondary"
        >
          Export to Google Sheet (Coming Soon)
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => navigate("/inscanStatus")}
        >
          Check InScan History
        </Button>
      </Box>
    </>
  );
};

export default QuickScan;
