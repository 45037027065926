const initialState = {
  loading: false,
  influencers: [],
  orders: [],
};

const influencerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_INFLUENCERS":
      return {
        ...state,
        influencers: action.payload,
      };
    case "UPDATE_INFLUENCER":
      return {
        ...state,
        influencers: state.influencers.map((influencer) =>
          influencer.id === action.payload.id ? action.payload : influencer
        ),
      };
    case "DELETE_INFLUENCER":
    case "ALL_ORDERS":
      return {
        ...state,
        orders: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default influencerReducer;
