import React from "react";
import { useSelector } from "react-redux";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";

const StatCard = ({ text, value }) => {
  return (
    <Card
      sx={{
        minWidth: 150,
        textAlign: "center",
        p: 2,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", fontSize: { xs: "1rem", sm: "1.25rem" } }}
        >
          {text}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "primary.main",
            mt: 1,
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
          }}
        >
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

const InfluencerHome = () => {
  const { influencers, orders, loading } = useSelector(
    (state) => state.influencerDetails
  );

  //STATUS OF INFLUENCERS
  const totalInfluencers = influencers.length;
  const approvedInfluencers = influencers?.filter(
    (it) => it?.approved_at
  )?.length;
  const rejectedInfluencers = influencers?.filter(
    (it) => it?.rejected_at
  )?.length;
  const pendingInfluencers =
    totalInfluencers - (approvedInfluencers + rejectedInfluencers);

  //STATUS OF ORDERS
  const totalOrders = orders?.length;
  const delivered = orders?.filter(
    (order) => "Delivered" in order?.tags
  )?.length;

  const { totalUploaded, approved, rejected, viewsUpdated, totalViews } =
    orders.reduce(
      (acc, order) => {
        const metafields = order.metafields;
        const keys = [
          "additionals_uploaded",
          "stories_uploaded",
          "posts_uploaded",
          "reels_uploaded",
          "statics_uploaded",
        ];
        for (const key of Object.keys(metafields)) {
          if (!key in keys) continue;
          for (const item of metafields[key]) {
            acc.totalUploaded++;

            if (item?.status === "approved") {
              acc.approved++;
              if (item?.views) {
                acc.viewsUpdated++;
                acc.totalViews += item?.views;
              }
            }

            if (item?.status === "rejected") acc.rejected++;
          }
        }

        return acc;
      },
      {
        totalUploaded: 0,
        approved: 0,
        rejected: 0,
        viewsUpdated: 0,
        totalViews: 0,
      }
    );

  return (
    <>
      <Box sx={{ p: { xs: 2, sm: 4 }, textAlign: "center" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            mb: 3,
            fontSize: { xs: "1.5rem", sm: "2rem" },
          }}
        >
          STATUS OF INFLUENCERS
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Total Registration" value={totalInfluencers} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Pending" value={pendingInfluencers} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Approved" value={approvedInfluencers} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Rejected" value={rejectedInfluencers} />
          </Grid>
        </Grid>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            my: 4,
            fontSize: { xs: "1.5rem", sm: "2rem" },
          }}
        >
          STATUS OF ORDERS
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Orders Created" value={totalOrders} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Delivered" value={delivered} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Content Uploaded" value={totalUploaded} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Content Approved" value={approved} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Content Rejected" value={rejected} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              text="Content Pending Approval"
              value={totalUploaded - approved - rejected}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Views Updated" value={viewsUpdated} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard text="Total Views" value={totalViews} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InfluencerHome;
