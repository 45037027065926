import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createProductsTableData,
  createProductsTableDataWithDrafts,
} from "../../utils/helpers";
import { productTypes, getSizesByType } from "../../utils/helperArrays";
import AddProductSizeTable from "../inventoryWidgets/AddProductSizeTable";
import {
  TextField,
  MenuItem,
  Box,
  InputAdornment,
  Paper,
  Divider,
  Button,
  Stack,
} from "@mui/material";
import { CSVLink } from "react-csv";
import React from "react";

const productStatusList = ["All", "active", "draft", "no status"];

const DimensionErrorForSkuWithStock = ({ list }) => {
  return <p></p>;
};

const AddProductStock = () => {
  const inventoryData = useSelector((state) => state.inventoryDetails.stock);
  const { shopify_products } = useSelector((state) => state.shopifyDetails);

  const [productType, setProductType] = useState("");
  const [products, setProducts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [searchedSKU, setSearchedSKU] = useState("");
  const [productStatus, setProductStatus] = useState("All");

  const [inventoryErrorList, setInventoryErrorList] = useState([]);
  const [brokenImageList, setBrokenImageList] = useState([]);
  const [checkImageLoading, setCheckImageLoading] = useState(false);
  const [checkImageProgress, setCheckImageProgress] = useState("");

  const [dimensionsList, setDimensionsList] = useState([]);
  const [nonDimensionsList, setNonDimensionsList] = useState([]);
  const [stockPresentnonDimensionsList, setstockPresentnonDimensionsList] =
    useState([]);

  const checkAllDimensionsPresent = (product) => {
    if (
      "length" in product &&
      "breadth" in product &&
      "height" in product &&
      product.length > 0 &&
      product.height > 0 &&
      product.breadth > 0 &&
      product.XLDeadWeight &&
      product.XSDeadWeight
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => console.log("Table Data", tableData), [tableData]);

  useEffect(() => {
    if (
      inventoryData.length > 0 &&
      productType &&
      shopify_products.length > 0
    ) {
      const noSkus = inventoryData.filter((i) => !i.SKU);
      setInventoryErrorList(noSkus);

      const temp = getSizesByType(productType);
      setProductSizes(temp);
      // const data = createProductsTableData(inventoryData, productType);
      const data = createProductsTableDataWithDrafts(
        inventoryData,
        shopify_products,
        productType
      );

      setProducts(data);
    }
  }, [inventoryData, shopify_products, productType]);

  const handleSearchSKU = (e) => {
    e.preventDefault();
    const text = e.target.value;
    setSearchedSKU(text);
    console.log("text", text);
    if (text.length > 1) {
      console.log(
        "no sku",
        products.filter((p) => !p.SKU)
      );
      const filteredSKU = products.filter((p) =>
        p.SKU.toString().match(text.toUpperCase())
      );
      if (filteredSKU) {
        console.log("filtered sku", filteredSKU);
        setTableData(filteredSKU);
      } else {
        setTableData([]);
      }
    } else {
      setTableData(products);
    }
  };

  useEffect(() => {
    if (products.length > 0) {
      console.log(products);
      setTableData(
        products.filter(
          (p) =>
            productStatus === "All" ||
            (p.status && p.status.includes(productStatus)) ||
            (productStatus === "no status" && !p.status)
        )
      );
    }
  }, [products, productStatus]);

  const handleCheckImages = async () => {
    if (inventoryData.length === 0) return;
    setCheckImageLoading(true);
    const skuWithImages = inventoryData
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.SKU === item.SKU)
      )
      .map((p) => ({ SKU: p.SKU, imageUrl: p.imageUrl }));
    let err = [];
    for (let i = 0; i < skuWithImages.length; i++) {
      console.log(skuWithImages[i]);
      setCheckImageProgress(`${i}/${skuWithImages.length}`);
      if (skuWithImages[i].imageUrl) {
        const response = await fetch(skuWithImages[i].imageUrl, {
          method: "HEAD",
        });
        if (!response.ok) {
          err.push(skuWithImages[i].SKU);
        }
      } else {
        err.push(skuWithImages[i].SKU);
      }
    }

    setBrokenImageList(err);
    setCheckImageLoading(false);
  };

  useEffect(() => {
    if (inventoryData.length > 0) {
      const inventory = inventoryData.reduce((acc, p) => {
        const found = acc.find((ac) => ac.SKU === p.SKU);
        if (found) {
          found.count = found.count + p.count;
          return acc;
        } else {
          if (checkAllDimensionsPresent(p)) {
            const newObj = {
              SKU: p.SKU,
              count: p.count,
              length: p.length,
              breadth: p.breadth,
              height: p.height,
              XSDeadWeight: p.XSDeadWeight,
              XLDeadWeight: p.XLDeadWeight,
            };
            acc.push(newObj);
          } else {
            const newObj = {
              SKU: p.SKU,
              count: p.count,
            };
            acc.push(newObj);
          }
        }
        return acc;
      }, []);
      setDimensionsList(inventory.filter((p) => "length" in p));
      const dimensionsAbsentSkus = inventory.filter((p) => !("length" in p));
      const dimensionsAbsentSkusWithStock = dimensionsAbsentSkus
        .filter((td) => "count" in td && td.count > 0)
        .map((d) => ({ SKU: d.SKU }));

      setNonDimensionsList(dimensionsAbsentSkus.map((d) => ({ SKU: d.SKU })));
      setstockPresentnonDimensionsList(dimensionsAbsentSkusWithStock);
    }
  }, [inventoryData]);

  return (
    <>
      <br />
      <>
        {inventoryErrorList.length > 0 && (
          <Box
            sx={{
              background: "gray",
              opacity: 0.5,
              width: "100%",
              textAlign: "center",
              p: "7px",
              marginBottom: "5px",
            }}
          >
            <h4 style={{ color: "white" }}>
              Some products does not have sku : {inventoryErrorList.length}
            </h4>
          </Box>
        )}
        {checkImageLoading && (
          <Box
            sx={{
              background: "gray",
              opacity: 0.5,
              width: "100%",
              textAlign: "center",
              p: "7px",
              marginBottom: "5px",
            }}
          >
            <h4 style={{ color: "white" }}>
              checking for broken images .... <span>{checkImageProgress}</span>
            </h4>
          </Box>
        )}
        {brokenImageList.length > 0 && (
          <Box
            sx={{
              background: "gray",
              opacity: 0.5,
              width: "100%",
              textAlign: "center",
              p: "7px",
              marginBottom: "5px",
            }}
          >
            <h4 style={{ color: "white" }}>
              Number of images not working : {brokenImageList.length}
            </h4>
            <Divider sx={{ color: "white" }} />
            {brokenImageList.map((i) => (
              <p style={{ color: "white" }}>{i}</p>
            ))}
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <Paper sx={{ width: "400px" }}>
            <TextField
              select
              // label="Select Product Type"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              variant="outlined"
              className="select"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p
                      style={{
                        fontWeight: 800,
                        borderRight: "1px solid black",
                        paddingRight: "4px",
                      }}
                    >
                      Product Type
                    </p>
                  </InputAdornment>
                ),
              }}
            >
              {productTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
        </Box>
        <br />
        {productType && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <Paper sx={{ width: "400px" }}>
              <TextField
                // label="Search SKU"
                type="text"
                variant="outlined"
                size="small"
                value={searchedSKU}
                fullWidth
                onChange={(e) => handleSearchSKU(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <p
                        style={{
                          fontWeight: 800,
                          borderRight: "1px solid black",
                          paddingRight: "4px",
                        }}
                      >
                        Search
                      </p>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
            <Paper>
              <TextField
                style={{ width: "250px" }}
                select
                value={productStatus}
                onChange={(e) => setProductStatus(e.target.value)}
                variant="outlined"
                className="select"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <p
                        style={{
                          fontWeight: 800,
                          borderRight: "1px solid black",
                          paddingRight: "4px",
                        }}
                      >
                        Product Status
                      </p>
                    </InputAdornment>
                  ),
                }}
              >
                {productStatusList.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Paper>
          </Box>
        )}
        {stockPresentnonDimensionsList.length > 0 && (
          <h4>
            Warning: SKU's has stock but dimensions not entered -{" "}
            {stockPresentnonDimensionsList.length}{" "}
          </h4>
        )}
        <Stack direction={"row"} gap={1} my={1}>
          <Button variant="outlined" onClick={handleCheckImages}>
            Check Images
          </Button>
          {inventoryData.length > 0 && (
            <React.Fragment>
              <CSVLink data={dimensionsList} filename={`Dimensions list`}>
                <Button variant="outlined">Dimensions List</Button>
              </CSVLink>
              <CSVLink data={nonDimensionsList} filename={"Non Dimensions All"}>
                <Button variant="outlined">Non Dimensions SKUs</Button>
              </CSVLink>
              <CSVLink
                data={stockPresentnonDimensionsList}
                filename={"Non Dimensions Stock Present"}
              >
                <Button variant="outlined">
                  Non Dimensions SKUs With Stock
                </Button>
              </CSVLink>
            </React.Fragment>
          )}
        </Stack>
      </>
      <br />
      {productType && tableData.length > 0 && productSizes.length > 0 ? (
        <AddProductSizeTable
          data={tableData}
          productSizes={productSizes}
          setBrokenImageList={setBrokenImageList}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AddProductStock;
