import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SalesTable from "./SalesTable";
import { productTypes, categoryTypes } from "../../utils/helperArrays";
import { TextField, MenuItem, InputAdornment, Paper } from "@mui/material";

const salesViewList = [
  { value: 0, label: "weeks" },
  { value: 1, label: "3 days interval" },
  { value: 2, label: "last 10 days" },
  { value: 3, label: "last 3 days" },
  { value: 4, label: "last 7 days" },
  { value: 5, label: "last 15 days" },
  { value: 6, label: "last 45 days" },
];

function filterKeys(obj, maxKey) {
  const filtered = {};

  // Loop through each key in the object
  for (const key in obj) {
    // Check if the key is numeric and within the desired range
    if (!isNaN(key) && parseInt(key) <= maxKey) {
      filtered[key] = obj[key];
    } else if (isNaN(key)) {
      // Retain non-numeric keys
      filtered[key] = obj[key];
    }
  }
  const keys = Array.from({ length: maxKey }, (_, i) => (i + 1).toString());
  const total = keys.reduce((acc, k) => {
    const value = filtered[k] ? filtered[k].total : 0;
    return acc + parseInt(value);
  }, 0);
  return { ...filtered, total };
}

function calculate45DaysWeeklySales(salesData) {
  let weeklySales = {};

  for (let key in salesData) {
    let numKey = parseInt(key, 10);
    if (!isNaN(numKey) && numKey >= 1 && numKey <= 45) {
      // Process only numeric keys (1-45)
      let week = Math.ceil(numKey / 7); // Determine the week (1-6)

      if (!weeklySales[week]) {
        weeklySales[week] = { total: 0 }; // Initialize week entry
      }

      // Loop through sizes in the current entry (excluding "total")
      for (let size in salesData[key]) {
        if (size !== "total") {
          weeklySales[week][size] =
            (weeklySales[week][size] || 0) + salesData[key][size];
        }
      }

      // Sum up total weekly sales
      weeklySales[week].total += salesData[key].total;
    }
  }

  return weeklySales;
}

const SalesDataWithImage = () => {
  const {
    weeklySalesData,
    tridiurnalSalesData,
    shopifyInventory,
    perDaySalesData,
  } = useSelector((state) => state.orderDetails);
  const productVariants = useSelector(
    (state) => state.productDetails.productVariants
  );
  const inventoryStock = useSelector((state) => state.inventoryDetails.stock);

  const [tableData, setTableData] = useState([]);
  const [productType, setProductType] = useState("");
  const [filteredProducts, setFilteredProducts] = useState("");

  const [categoryTypesArray, setCategoryTypesArray] = useState([]);
  const [productVariantList, setProductVariantList] = useState([]);
  const [salesView, setSalesView] = useState(0);
  const [numberOfSalesColumn, setNumberOfSalesColumn] = useState(4);
  const [weeklySale45Days, setWeeklySale45Days] = useState(null);

  const changeDateFormat = (date) => {
    const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
    const reqMonth =
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
    const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
      "-"
    )}T00:00:00+05:30`;
    return reqFormat;
  };

  const handleChangeSalesView = (value) => {
    setSalesView(value);
    const range =
      value === 0
        ? 4
        : value === 1
        ? 5
        : value === 2
        ? 10
        : value === 3
        ? 3
        : value === 4
        ? 7
        : value === 5
        ? 15
        : 7;
    setNumberOfSalesColumn(range);
  };

  useEffect(() => {
    if (perDaySalesData) {
      setWeeklySale45Days({
        ...perDaySalesData,
        sales_data: perDaySalesData.sales_data.map((sd) => {
          const sales = calculate45DaysWeeklySales(sd);
          return {
            ...sd,
            ...sales,
          };
        }),
      });
    }
  }, [perDaySalesData]);

  useEffect(() => {
    if (shopifyInventory.length > 0 && productVariants.length > 0) {
      const temp = productVariants.map((x) => {
        const variant = shopifyInventory.find(
          (p) => p.inventory_item_id === x.inventory_item_id
        );
        let stock = 0;
        if (variant) {
          stock = variant.available;
        } else {
          stock = 0;
        }
        return {
          ...x,
          stock,
        };
      });
      // console.log(temp)
      setProductVariantList(temp);
    }
  }, [shopifyInventory, productVariants]);

  useEffect(() => {
    //Create the Tabledata
    if (productVariantList.length > 0 && inventoryStock.length > 0) {
      const sales =
        salesView === 0
          ? weeklySalesData
          : salesView === 1
          ? tridiurnalSalesData
          : salesView === 6
          ? weeklySale45Days
          : perDaySalesData;
      console.log("sales view", salesView, weeklySale45Days);
      // sales data is of 15 days
      const data = sales?.sales_data.map((p) => {
        const imageObj = inventoryStock.find((item) => item.SKU === p.sku);
        let src = null;
        let material = null;
        if (imageObj) {
          src = imageObj.imageUrl;
          material = imageObj.material || [];
        } else {
          src = "https://sss-retail-images.s3.amazonaws.com/404.jpg?w=256&q=75";
          material = [];
        }

        let variantArr = [];
        const variantObj = productVariantList.filter(
          (item) => item.sku === p.sku
        );
        variantArr.push(
          variantObj.map((x) => ({
            size: x.title,
            stock: x.stock ? x.stock : 0,
          }))
        );

        let inventoryArr = [];
        const inventoryObj = inventoryStock.filter(
          (item) => item.SKU === p.sku
        );
        inventoryArr.push(
          inventoryObj.map((x) => ({
            size: x.size,
            stock: x.count ? x.count : 0,
          }))
        );
        const inventoryObjHavingDate = inventoryObj.find((i) => "date" in i);

        const filteredP = filterKeys(p, numberOfSalesColumn);

        return {
          ...filteredP,
          tags: "",
          src,
          publishedAt: inventoryObjHavingDate
            ? changeDateFormat(new Date(inventoryObjHavingDate.date))
            : "Invalid Date",
          material,
          type: imageObj?.type,
          stock: variantArr,
          inventoryStock: inventoryArr,
        };
      });
      // console.log(data)
      setTableData(data);
    }
  }, [
    inventoryStock,
    productVariantList,
    salesView,
    numberOfSalesColumn,
    perDaySalesData,
    tridiurnalSalesData,
    weeklySalesData,
    weeklySale45Days,
  ]);

  useEffect(() => {
    if (tableData.length > 0 && productType) {
      const getCategories = categoryTypes(productType);
      const filtered = tableData.filter((item) => item.type === productType);
      setCategoryTypesArray(getCategories);
      setFilteredProducts(filtered);
    }
  }, [productType, tableData]);
  return (
    <div>
      <br />
      <h2>Last Updated at: {weeklySalesData.updated_at}</h2>
      <br />
      <div style={{ display: "flex" }}>
        <Paper sx={{ width: "500px" }}>
          <TextField
            select
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    style={{
                      fontWeight: 800,
                      borderRight: "1px solid black",
                      paddingRight: "4px",
                    }}
                  >
                    Product Type
                  </p>
                </InputAdornment>
              ),
            }}
          >
            {productTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Paper>
      </div>
      <br />
      {productType && filteredProducts.length > 0 && (
        <>
          {/* sales */}
          <Paper sx={{ width: "250px", my: "5px", marginBottom: "10px" }}>
            <TextField
              select
              fullWidth
              value={salesView}
              onChange={(e) => {
                handleChangeSalesView(e.target.value);
              }}
              variant="outlined"
              className="select"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p
                      style={{
                        fontWeight: 800,
                        borderRight: "1px solid black",
                        paddingRight: "4px",
                      }}
                    >
                      Sales
                    </p>
                  </InputAdornment>
                ),
              }}
            >
              {salesViewList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
          <SalesTable
            tableData={filteredProducts}
            salesView={salesView}
            categoryTypes={categoryTypesArray}
            productType={productType}
            salesColumn={numberOfSalesColumn}
          />
        </>
      )}
    </div>
  );
};

export default SalesDataWithImage;
