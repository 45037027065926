import React, { useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../utils/TablePaginationActions";

export default function ManualPdfGnTable({ tableData }) {
  const [open, setOpen] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="left">AWB Code</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Shipping Cost</TableCell>
              <TableCell align="left">Sub Total</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left">Product</TableCell>
              <TableCell align="left">List</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  key={index}
                  sx={{
                    bgcolor:
                      row.status === "fulfilled"
                        ? "rgba(145,145,145, 0.2)"
                        : index % 2 === 0
                        ? "#afd275"
                        : "#edf5e1",

                    "& > *": {
                      borderBottom: "unset",
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.original_order_id} (#
                    {row.fulfillment_id})
                  </TableCell>
                  <TableCell>{row.awb_code}</TableCell>
                  <TableCell align="left">
                    {new Date(row.order_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="left">{row.shipping_charges}</TableCell>
                  <TableCell align="left">{row.sub_total}</TableCell>
                  <TableCell align="left">{row.total}</TableCell>
                  <TableCell align="left">
                    {row.order_items?.length || 0} Items
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        const temp = open.map((i, openIndex) => {
                          if (index !== openIndex) {
                            return i;
                          } else {
                            return !i;
                          }
                        });
                        setOpen(temp);
                      }}
                    >
                      {open[index] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      paddingBottom: 0,
                      paddingTop: 0,
                    }}
                    colSpan={6}
                  ></TableCell>
                </TableRow>
              </React.Fragment>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
