import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  TableSortLabel,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const InfluencerOrders = () => {
  const dispatch = useDispatch();
  const { orders, loading } = useSelector((state) => state.influencerDetails);

  const [search, setSearch] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "displayName",
    direction: "asc",
  });
  const [dateRange, setDateRange] = useState({ from: "", to: "" });

  if (loading)
    return <CircularProgress sx={{ display: "block", margin: "20px auto" }} />;
  if (!orders || orders.length === 0)
    return <Typography>No orders found.</Typography>;

  // Sorting function
  const sortedData = [...orders].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key])
      return sortConfig.direction === "asc" ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key])
      return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  // Filtering function
  const filteredData = sortedData.filter((order) => {
    const lowerSearch = search.toLowerCase();
    const matchesSearch =
      order.name?.toLowerCase().includes(lowerSearch) ||
      order.id?.toLowerCase().includes(lowerSearch);

    // Date filtering
    const orderDate = new Date(order.createdAt);
    const fromDate = dateRange.from ? new Date(dateRange.from) : null;
    const toDate = dateRange.to ? new Date(dateRange.to) : null;
    const matchesDateRange =
      (!fromDate || orderDate >= fromDate) && (!toDate || orderDate <= toDate);

    return matchesSearch && matchesDateRange;
  });

  // Change sorting
  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ p: 2 }}>
        {/* Filters */}
        <TextField
          label="Search"
          size="small"
          sx={{ m: 1, width: "30%" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by order numeber or id..."
        />
        <TextField
          label="From Date"
          type="date"
          size="small"
          sx={{ m: 1 }}
          InputLabelProps={{ shrink: true }}
          value={dateRange.from}
          onChange={(e) =>
            setDateRange((prev) => ({ ...prev, from: e.target.value }))
          }
        />
        <TextField
          label="To Date"
          type="date"
          size="small"
          sx={{ m: 1 }}
          InputLabelProps={{ shrink: true }}
          value={dateRange.to}
          onChange={(e) =>
            setDateRange((prev) => ({ ...prev, to: e.target.value }))
          }
        />

        {/* Table */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === "id"}
                  direction={sortConfig.direction}
                  onClick={() => handleSort("id")}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell>Order Number</TableCell>
              <TableCell>Ordered on</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Content</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.id?.split("/")?.at(-1)}</TableCell>
                <TableCell>{order.name}</TableCell>
                <TableCell>
                  {new Date(order.createdAt)?.toDateString()}
                </TableCell>
                <TableCell>{"N/A"}</TableCell>
                <TableCell>{"N/A"}</TableCell>
                <TableCell align="right">
                  <IconButton
                    // onClick={() => handleEdit(order)}
                    color="inherit"
                    sx={{
                      border: "1px solid gray",
                      borderRadius: "10px",
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InfluencerOrders;
