import { combineReducers } from "redux";
import authReducer from "./authReducer";
import productsReducer from "./productsReducer";
import errorModalReducer from "./errorModalReducer";
import inventoryReducer from "./inventoryReducer";
import ordersReducer from "./ordersReducer";
import loaderReducer from "./loaderReducer";
import reportReducer from "./reportReducer";
import customerReducer from "./customerReducer";
import ticketReducer from "./ticketReducer";
import analyticsReducer from "./analyticsReducer";
import POReducer from "./POReducer";
import xbReducer from "./xbReducer";
import reverseDeliveryReducer from "./reverseDeliveryReducer";
import shopifyReducer from "./shopifyReducer";
import sesReducer from "./sesReducer";
import { reconReducer } from "./reconReducer";
import influencerReducer from "./influencerReducer";

const appReducer = combineReducers({
  auth: authReducer,
  productDetails: productsReducer,
  errorModal: errorModalReducer,
  inventoryDetails: inventoryReducer,
  orderDetails: ordersReducer,
  loader: loaderReducer,
  reports: reportReducer,
  customers: customerReducer,
  tickets: ticketReducer,
  analytics: analyticsReducer,
  PO: POReducer,
  xbDetails: xbReducer,
  reverseDetails: reverseDeliveryReducer,
  shopifyDetails: shopifyReducer,
  sesDetails: sesReducer,
  recon: reconReducer,
  influencerDetails: influencerReducer, // newly added reducer for our influencer app
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
export default rootReducer;
