import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SyncIcon from "@mui/icons-material/Sync";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import MenuIcon from "@mui/icons-material/Menu";
import InterestsIcon from "@mui/icons-material/Interests";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

export const navBarArr = [
  // admin
  [
    {
      text: "DashBoard",
      link: "/",
      icon: <DashboardIcon />,
      submenu: false,
    },
    {
      text: "Products",
      submenu: true,
      icon: <MenuBookIcon />,
      items: [
        { text: "Product Items", link: "/products" },
        { text: "Add Products", link: "/addProduct" },
        { text: "Edit Products", link: "/editProduct" },
        { text: "Material List", link: "/productmaterial" },
        { text: "Design Picks", link: "/updatedesignpicks" },
        { text: "Pattern", link: "/pattern-add" },
      ],
    },
    {
      text: "Inventory Management",
      submenu: true,
      icon: <InventoryIcon />,
      items: [
        { text: "Add Stock", link: "/addStock" },
        { text: "Reset Stock", link: "/updateStock" },
        // { text: "RTO List XB", link: "/temp" },
        // { text: "R&E List XB", link: "/reverseDelivered" },
        { text: "Reverse Recon", link: "/reversereconcilation" },
        { text: "Quick ScanRTO", link: "/scanrto" },
        { text: "Vendor Upload", link: "/addProductFromVendor" },
      ],
    },
    {
      text: "Orders",
      icon: <AddShoppingCartIcon />,
      submenu: true,
      items: [
        {
          text: "Process Orders",
          link: "/processorders",
        },
        {
          text: "Order Mail",
          link: "/bulkordermail",
        },
        {
          text: "Print Invoices",
          link: "/createinvoice",
        },
        {
          text: "Unfulfilled Orders",
          link: "/unfulfilledOrderProducts",
        },
        { text: "Unfulfilled (OLD)", link: "/orders" },
        { text: "Revert Fulfillments", link: "/revertFulfillment" },
        { text: "XB Dispatch", link: "/xbDispatch" },
        {
          text: "Dispatch Orders Delhivery",
          link: "/dispatchInDelhivery",
        },
        {
          text: "Dispatch Shiprocket",
          link: "/dispatchInShiprocket",
        },
        {
          text: "Shiprocket Orders",
          link: "/shiprocketOrders",
        },
        // { text: "Revert Dispatched", link: "/readyToShipOrders" },
        { text: "Manually Dispatch", link: "/manualDispatch" },
        // { text: "XB Manual Dispatch", link: "/singleDispatch" },
        // { text: "AWB Not Ready Orders", link: "/awbNotReady" },
        { text: "Dispatch to Shiprocket", link: "/shiprocketDispatch" },
        { text: "Pincodes", link: "/pincodes" },
        { text: "Single Order", link: "/single-order-details" },
        { text: "view orders", link: "/vieworderbystatus" },
        { text: "Track Lifecycle", link: "/dispatchestracker" },
      ],
    },
    {
      text: "Delhivery",
      submenu: true,
      icon: <AddShoppingCartIcon />,
      items: [
        { text: "Cancel Shipment", link: "/canceldelhiveryshipment" },
        {
          text: "Delhivery Pdf",
          link: "/manual-pdf-generation",
        },
      ],
    },
    {
      text: "Sync Data",
      icon: <SyncIcon />,
      submenu: true,
      items: [
        {
          text: "XB Forward",
          link: "/forwardSync",
        },
        {
          text: "XB Reverse",
          link: "/reverseSync",
        },
        {
          text: "Analytics Sync",
          link: "/analyticsSync",
        },
        // {
        //   text: "rto_sync",
        //   link: "/syncRtoData",
        // },
        {
          text: "NDR Sync",
          link: "/syncNdrData",
        },
        // {
        //   text: "reverse sync",
        //   link: "/syncReverseData",
        // },
        // {
        //   text: "Shiprocket Sync",
        //   link: "/syncDelivered",
        // },
        {
          text: "Shiprocket Sync",
          link: "/syncshiprocket",
        },
      ],
    },
    {
      text: "Sales",
      icon: <TrendingUpIcon />,
      submenu: true,
      items: [
        { text: "Sales Data", link: "/sales" },
        { text: "New Products", link: "/newproductsales" },
      ],
    },
    {
      text: "Returns & Exchanges",
      icon: <SyncAltIcon />,
      submenu: true,
      items: [
        {
          text: "Get Reverse Details",
          link: "/reverseDetails",
        },
        {
          text: "Reverse Orders",
          link: "/reverseMain",
        },
        {
          text: "Reverse Delhivery",
          link: "/reverseDelhivery",
        },
        {
          text: "Manual Exchange",
          link: "/manualexchange",
        },
        {
          text: "Report",
          link: "/reverseReport",
        },
        {
          text: "Create Refund",
          link: "/createrefund",
        },
        // {
        //   text: "report old",
        //   link: "/ReturnExchangeReport",
        // },
      ],
    },
    {
      text: "NDR",
      icon: <PhoneEnabledIcon />,
      submenu: true,
      items: [
        { text: "NDR Assign", link: "/assignNdr" },
        // { text: "NDR Actions", link: "/nonDeliveryReport" },
        { text: "NDR On Hold", link: "/ndrOnHold" },
        { text: "NDR Shiprocket", link: "/NDROrders" },
      ],
    },
    {
      text: "NPR",
      icon: <PhoneEnabledIcon />,
      submenu: true,
      items: [{ text: "NPR Actions", link: "/nprActions" }],
    },
    {
      text: "PO Manager",
      icon: <SummarizeIcon />,
      submenu: true,
      items: [
        { text: "PO Products List", link: "/poProducts" },
        { text: "Show PO", link: "/showpo" },
        { text: "Create PO", link: "/createPO" },
        { text: "Create PO Manually", link: "/createPOManual" },
        { text: "Raw Material Calc", link: "/rawmaterialcalculator" },
        { text: "Material Dashboard", link: "/materialdashboard" },
        { text: "Fabric PO", link: "/fabricpo" },
      ],
    },
    {
      text: "Reports",
      icon: <AssessmentIcon />,
      submenu: true,
      items: [
        { text: "GST Report", link: "/gstReport" },
        { text: "XB GST", link: "/xbGst" },
        { text: "Delhivery GST", link: "/delhiveryGst" },
        // { text: "XB GST AWB", link: "/xbgstbyawbrange" },
        // { text: "GST Report New", link: "/gstOrdersReport" },
        { text: "Shiprocket GST", link: "/shiprocketGst" },
        { text: "Email Report", link: "/emailreport" },
        { text: "Customer Report", link: "/customerreport" },

        {
          text: "Mobile Unvailability",
          link: "/mobileunavailablityreport",
        },
        { text: "State Wise Sales", link: "/statewisesales" },
        { text: "Get Order Staus(CSV)", link: "/getorderstatus" },
      ],
    },
    {
      text: "Customer Support",
      icon: <ConfirmationNumberIcon />,
      submenu: true,
      items: [
        { text: "Templates", link: "/templates" },
        { text: "Estimate Dispatch", link: "/order-dispatchability" },
        { text: "Support Tickets", link: "/allQuery" },
        { text: "Tickets Report", link: "/ticketsreport" },
        { text: "Assign Tickets", link: "/assignticket" },
        { text: "Repeating Products", link: "/repeatingproducts" },
        { text: "Unreliable Orders", link: "/unreliableorders" },
        {
          text: "Customer order history",
          link: "/customerorderhistory",
        },
      ],
    },
    {
      text: "Influencers",
      icon: <InterestsIcon />,
      submenu: true,
      items: [
        { text: "Home", link: "/influencer" },
        { text: "Registrations", link: "/influencer/registrations" },
        { text: "Influencer", link: "/influencer/active" },
        { text: "Orders", link: "/influencer/orders" },
        { text: "Analytics", link: "/influencer/analytics" },
      ],
    },
    {
      text: "Shopify Reports",
      icon: <ShoppingBagIcon />,
      submenu: true,
      items: [
        { text: "Customer Journey", link: "/shopifyreports/customerjourney" },
      ],
    },
    {
      text: "About",
      link: "/about",
      icon: <MenuIcon />,
      submenu: false,
    },
    // Check influencer program
  ],
  // supervisor
  [
    {
      text: "DashBoard",
      link: "/",
      icon: <DashboardIcon />,
      submenu: false,
    },
    {
      text: "Orders",
      icon: <AddShoppingCartIcon />,
      submenu: true,
      items: [
        {
          text: "Unfulfilled Orders",
          link: "/unfulfilledOrderProducts",
        },
        {
          text: "Print Invoices",
          link: "/createinvoice",
        },
      ],
    },
    {
      text: "Sales",
      icon: <TrendingUpIcon />,
      submenu: true,
      items: [{ text: "Sales Data", link: "/sales" }],
    },
    {
      text: "Returns & Exchanges",
      icon: <SyncAltIcon />,
      submenu: true,
      items: [
        {
          text: "Get Reverse Details",
          link: "/reverseDetails",
        },
        {
          text: "Reverse Orders",
          link: "/reverseMain",
        },
        {
          text: "Reverse Delhivery",
          link: "/reverseDelhivery",
        },
        {
          text: "Warehouse Action",
          link: "/reverseDelivered",
        },
        {
          text: "Report",
          link: "/ReturnExchangeReport",
        },
      ],
    },
    {
      text: "NDR",
      icon: <PhoneEnabledIcon />,
      submenu: true,
      items: [
        { text: "NDR Assign", link: "/assignNdr" },
        { text: "NDR Actions", link: "/nonDeliveryReport" },
        { text: "NDR On Hold", link: "/ndrOnHold" },
        { text: "NDR Shiprocket", link: "/NDROrders" },
      ],
    },
    {
      text: "NPR",
      icon: <PhoneEnabledIcon />,
      submenu: true,
      items: [{ text: "NPR Actions", link: "/nprActions" }],
    },
    {
      text: "Customer Support",
      icon: <ConfirmationNumberIcon />,
      submenu: true,
      items: [
        { text: "Templates", link: "/templates" },
        { text: "Support Tickets", link: "/allQuery" },
        { text: "Tickets Report", link: "/ticketsreport" },
        { text: "Assign Tickets", link: "/assignticket" },
        { text: "Returning Customer", link: "/customerreport" },
      ],
    },
    {
      text: "Reports",
      icon: <ConfirmationNumberIcon />,
      submenu: true,
      items: [
        {
          text: "get order status",
          link: "/getorderstatus",
        },
      ],
    },
    {
      text: "About",
      link: "/about",
      icon: <MenuIcon />,
      submenu: false,
    },
  ],
  // customer care
  [
    {
      text: "DashBoard",
      link: "/",
      icon: <DashboardIcon />,
      submenu: false,
    },
    {
      text: "Products",
      submenu: true,
      icon: <MenuBookIcon />,
      items: [
        { text: "Product Items", link: "/products" },
        { text: "Add Products", link: "/addProduct" },
      ],
    },
    {
      text: "Sales",
      icon: <TrendingUpIcon />,
      submenu: true,
      items: [{ text: "Sales Data", link: "/sales" }],
    },
    {
      text: "Returns & Exchanges",
      icon: <SyncAltIcon />,
      submenu: true,
      items: [
        {
          text: "Get Reverse Details",
          link: "/reverseDetails",
        },
        {
          text: "Reverse Orders",
          link: "/reverseMain",
        },
        {
          text: "Reverse Delhivery",
          link: "/reverseDelhivery",
        },
        {
          text: "Manual Exchange",
          link: "/manualexchange",
        },
        {
          text: "Warehouse Action",
          link: "/reverseDelivered",
        },
        {
          text: "Report",
          link: "/ReturnExchangeReport",
        },
      ],
    },
    {
      text: "NDR",
      icon: <PhoneEnabledIcon />,
      submenu: true,
      items: [
        // { text: "NDR Assign", link: "/assignNdr" },
        { text: "NDR Actions", link: "/nonDeliveryReport" },
        // { text: "NDR On Hold", link: "/ndrOnHold" },
        // { text: "NDR Shiprocket", link: "/NDROrders" },
      ],
    },
    {
      text: "NPR",
      icon: <PhoneEnabledIcon />,
      submenu: true,
      items: [{ text: "NPR Actions", link: "/nprActions" }],
    },
    {
      text: "Customer Support",
      icon: <ConfirmationNumberIcon />,
      submenu: true,
      items: [
        { text: "Templates", link: "/templates" },
        { text: "Estimate Dispatch", link: "/order-dispatchability" },
        { text: "Support Tickets", link: "/allQuery" },
        { text: "Tickets Report", link: "/ticketsreport" },
        { text: "Assign Tickets", link: "/assignticket" },
        { text: "Returning Customer", link: "/customerreport" },
        { text: "Repeating Products", link: "/repeatingproducts" },
        { text: "Unreliable Orders", link: "/unreliableorders" },
        {
          text: "Customer order history",
          link: "/customerorderhistory",
        },
      ],
    },
    {
      text: "Reports",
      icon: <ConfirmationNumberIcon />,
      submenu: true,
      items: [
        {
          text: "get order status",
          link: "/getorderstatus",
        },
      ],
    },
    {
      text: "About",
      link: "/about",
      icon: <MenuIcon />,
      submenu: false,
    },
  ],
  // warehouse
  [
    {
      text: "DashBoard",
      link: "/",
      icon: <DashboardIcon />,
      submenu: false,
    },
    {
      text: "Orders",
      icon: <AddShoppingCartIcon />,
      submenu: true,
      items: [
        // { text: "All Orders", link: "/viewOrders" },
        {
          text: "Print Invoices",
          link: "/createinvoice",
        },
        {
          text: "Unfulfilled Orders",
          link: "/unfulfilledOrderProducts",
        },
        // { text: "Dispatch Orders", link: "/dispatch" },
        // { text: "Revert Fulfillments", link: "/revertFulfillment" },
        // { text: "XB Dispatch", link: "/xbDispatch" },
        // { text: "Revert Dispatched", link: "/readyToShipOrders" },
        // { text: "Manually Dispatch", link: "/manualDispatch" },
        // { text: "XB Manual Dispatch", link: "/singleDispatch" },
        // { text: "AWB Not Ready Orders", link: "/awbNotReady" },
        // { text: "Dispatch to Shiprocket", link: "/shiprocketDispatch" },
        // { text: "Pincodes", link: "/pincodes" },
      ],
    },
    {
      text: "Inventory Management",
      submenu: true,
      icon: <InventoryIcon />,
      items: [
        { text: "Add Stock", link: "/addStock" },
        { text: "Reset Stock", link: "/updateStock" },
        // { text: "RTO List XB", link: "/temp" },
        // { text: "R&E List XB", link: "/reverseDelivered" },
        { text: "Reverse Recon", link: "/reversereconcilation" },
        { text: "Quick ScanRTO", link: "/scanrto" },
        { text: "Vendor Upload", link: "/addProductFromVendor" },
      ],
    },
    {
      text: "About",
      link: "/about",
      icon: <MenuIcon />,
      submenu: false,
    },
  ],
  // customer care supervisor
  [
    {
      text: "DashBoard",
      link: "/",
      icon: <DashboardIcon />,
      submenu: false,
    },
    {
      text: "Products",
      submenu: true,
      icon: <MenuBookIcon />,
      items: [{ text: "Product Items", link: "/products" }],
    },
    {
      text: "Inventory Management",
      submenu: true,
      icon: <InventoryIcon />,
      items: [{ text: "Add Stock", link: "/addStock" }],
    },
    {
      text: "Sales",
      icon: <TrendingUpIcon />,
      submenu: true,
      items: [{ text: "Sales Data", link: "/sales" }],
    },
    {
      text: "Returns & Exchanges",
      icon: <SyncAltIcon />,
      submenu: true,
      items: [
        {
          text: "Get Reverse Details",
          link: "/reverseDetails",
        },
        {
          text: "Reverse Orders",
          link: "/reverseMain",
        },
        {
          text: "Reverse Delhivery",
          link: "/reverseDelhivery",
        },
        {
          text: "Manual Exchange",
          link: "/manualexchange",
        },
        {
          text: "Warehouse Action",
          link: "/reverseDelivered",
        },
        {
          text: "Report",
          link: "/ReturnExchangeReport",
        },
      ],
    },
    {
      text: "NDR",
      icon: <PhoneEnabledIcon />,
      submenu: true,
      items: [
        { text: "NDR Assign", link: "/assignNdr" },
        // { text: "NDR Actions", link: "/nonDeliveryReport" },
        { text: "NDR On Hold", link: "/ndrOnHold" },
        { text: "NDR Shiprocket", link: "/NDROrders" },
      ],
    },
    {
      text: "NPR",
      icon: <PhoneEnabledIcon />,
      submenu: true,
      items: [{ text: "NPR Actions", link: "/nprActions" }],
    },
    {
      text: "Customer Support",
      icon: <ConfirmationNumberIcon />,
      submenu: true,
      items: [
        { text: "Templates", link: "/templates" },
        { text: "Estimate Dispatch", link: "/order-dispatchability" },
        { text: "Support Tickets", link: "/allQuery" },
        { text: "Tickets Report", link: "/ticketsreport" },
        { text: "Assign Tickets", link: "/assignticket" },
        { text: "repeating customer", link: "/customerreport" },
        { text: "Repeating Products", link: "/repeatingproducts" },
        { text: "Unreliable Orders", link: "/unreliableorders" },
        {
          text: "Customer order history",
          link: "/customerorderhistory",
        },
      ],
    },
    {
      text: "Reports",
      icon: <ConfirmationNumberIcon />,
      submenu: true,
      items: [
        {
          text: "get order status",
          link: "/getorderstatus",
        },
      ],
    },
    {
      text: "About",
      link: "/about",
      icon: <MenuIcon />,
      submenu: false,
    },
  ],
  // warehouse assistant
  [
    {
      text: "DashBoard",
      link: "/",
      icon: <DashboardIcon />,
      submenu: false,
    },
    {
      text: "Inventory Management",
      submenu: true,
      icon: <InventoryIcon />,
      items: [
        // { text: "Add Stock", link: "/addStock" },
        // { text: "Reset Stock", link: "/updateStock" },
        // { text: "RTO List XB", link: "/temp" },
        // { text: "R&E List XB", link: "/reverseDelivered" },
        { text: "Reverse Recon", link: "/reversereconcilation" },
        { text: "Quick ScanRTO", link: "/scanrto" },
        { text: "Vendor Upload", link: "/addProductFromVendor" },
        {
          text: "Print Invoices",
          link: "/createinvoice",
        },
      ],
    },
    {
      text: "Orders",
      icon: <AddShoppingCartIcon />,
      submenu: true,
      items: [
        // { text: "All Orders", link: "/viewOrders" },
        {
          text: "Print Invoices",
          link: "/createinvoice",
        },
      ],
    },
    {
      text: "About",
      link: "/about",
      icon: <MenuIcon />,
      submenu: false,
    },
  ],
  // marketing
  [
    {
      text: "DashBoard",
      link: "/",
      icon: <DashboardIcon />,
      submenu: false,
    },
    // {
    //   text: "Orders",
    //   icon: <AddShoppingCartIcon />,
    //   submenu: true,
    //   items: [
    //     {
    //       text: "Unfulfilled Orders",
    //       link: "/unfulfilledOrderProducts",
    //     },
    //   ],
    // },
    {
      text: "Sales",
      icon: <TrendingUpIcon />,
      submenu: true,
      items: [
        { text: "Sales Data", link: "/sales" },
        { text: "New Products", link: "/newproductsales" },
      ],
    },
    {
      text: "Returns & Exchanges",
      icon: <SyncAltIcon />,
      submenu: true,
      items: [
        // {
        //   text: "Get Reverse Details",
        //   link: "/reverseDetails",
        // },
        // {
        //   text: "Reverse Orders",
        //   link: "/reverseMain",
        // },
        {
          text: "Report",
          link: "/ReturnExchangeReport",
        },
      ],
    },
    {
      text: "Influencers",
      icon: <InterestsIcon />,
      submenu: true,
      items: [
        { text: "Home", link: "/influencer" },
        { text: "Registrations", link: "/influencer/registrations" },
        { text: "Influencer", link: "/influencer/active" },
        { text: "Orders", link: "/influencer/orders" },
        { text: "Analytics", link: "/influencer/analytics" },
      ],
    },
    {
      text: "Shopify Reports",
      icon: <ShoppingBagIcon />,
      submenu: true,
      items: [
        { text: "Customer Journey", link: "/shopifyreports/customerjourney" },
      ],
    },
    // {
    //   text: "Customer Support",
    //   icon: <ConfirmationNumberIcon />,
    //   submenu: true,
    //   items: [
    //     { text: "Tickets Report", link: "/ticketsreport" },
    //     { text: "Returning Customer", link: "/customerreport" },
    //   ],
    // },
    // {
    //   text: "About",
    //   link: "/about",
    //   icon: <MenuIcon />,
    //   submenu: false,
    // },
  ],
];
