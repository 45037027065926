import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddProductStock from "../productsWidgets/AddProductStock";
import RedirectLogin from "../RedirectLogin";
import { allInventory } from "../../actions/inventoryActions";
import { allProducts } from "../../actions/productsActions";
import "./index.css";
import ProgressBar from "../../ProgressBar";
import { Skeleton, Stack } from "@mui/material";

const AddStock = () => {
  const auth = useSelector((state) => state.auth);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const { shopify_products } = useSelector((state) => state.shopifyDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventory.length > 0 && auth) dispatch(allInventory());
    if (!shopify_products.length > 0 && auth) dispatch(allProducts());
  }, [dispatch, auth]);

  const Progress = () => {
    return (
      <div>
        <Skeleton variant="rectangular" width={210} height={40} />
        <Stack direction={"row"} gap={2}>
          <Skeleton
            variant="rectangular"
            width={200}
            height={40}
            sx={{ my: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width={200}
            height={40}
            sx={{ my: 1 }}
          />
        </Stack>
        <Skeleton
          variant="rectangular"
          width={150}
          height={40}
          sx={{ my: 1 }}
        />
        <Skeleton variant="rectangular" width={"100%"} height={400} />
      </div>
    );
  };
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!inventory.length > 0 && !shopify_products.length > 0 ? (
            <Progress />
          ) : (
            <AddProductStock />
          )}
        </>
      )}
    </>
  );
};

export default AddStock;
