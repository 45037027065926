import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allUnfulfilledOrders,
  getPerDaySalesData,
} from "../../actions/ordersActions";
import { allInventory } from "../../actions/inventoryActions";
import RedirectLogin from "../RedirectLogin";
import { PageSkeleton } from "../dashboard/helperComponents";
import FabricPOPerFabric from "../poWidgets/fabricPO/FabricPOPerFabric";
import {
  getMaterialFabricList,
  getVendorMaterialList,
} from "../../actions/materialActions";
import { createUnfulfilledOrdersTableData } from "../../utils/helpers";
import { getProcessedQuantity } from "../../actions/POActions";

const FabricPO = () => {
  const dispatch = useDispatch();
  const [vendorMaterials, setVendorMaterials] = useState([]);
  const [unfulfilledOrders, setUnfulfilledOrders] = useState([]);
  const auth = useSelector((state) => state.auth);
  const { allUnfulfilled: orders, perDaySalesData } = useSelector(
    (state) => state.orderDetails
  );
  const { materialFabricList } = useSelector((state) => state.inventoryDetails);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const { processedQntyList } = useSelector((state) => state.PO);

  useEffect(() => {
    if (!perDaySalesData) {
      dispatch(getPerDaySalesData());
    }
    if (!orders.length > 0) dispatch(allUnfulfilledOrders());
    if (!inventory.length > 0) dispatch(allInventory());
    if (materialFabricList.length === 0) dispatch(getMaterialFabricList());
    if (!processedQntyList.length > 0) dispatch(getProcessedQuantity());
  }, []);

  useEffect(() => {
    getVendorMaterialList("001")
      .then((data) => {
        if (data.statusCode === 200) {
          const materials = data.body;
          console.log("materials", materials);
          //get all the fabrics
          setVendorMaterials(materials);
        }
      })
      .catch((e) => {
        console.log("err", e);
      });
  }, []);
  useEffect(() => {
    if (orders.length > 0) {
      const filteredOrders = orders.filter(
        (x) => x.financial_status !== "voided"
      );
      const formatted = createUnfulfilledOrdersTableData(filteredOrders);
      setUnfulfilledOrders(formatted);
    }
  }, [orders]);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!perDaySalesData ||
          !unfulfilledOrders.length > 0 ||
          !orders.length > 0 ||
          !materialFabricList.length > 0 ||
          !vendorMaterials.length > 0 ||
          !processedQntyList.length > 0 ||
          !inventory.length > 0 ? (
            <PageSkeleton />
          ) : (
            <FabricPOPerFabric
              vendorMaterials={vendorMaterials}
              unfulfilledOrders={unfulfilledOrders}
            />
          )}
        </>
      )}
    </>
  );
};

export default FabricPO;
