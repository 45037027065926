import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allInfluencers, allOrders } from "../../../actions/influencerActions";
import { Box, CircularProgress } from "@mui/material";
import { Outlet } from "react-router-dom";

// Outlet for influencer group
function InfluencerGroup() {
  const auth = useSelector((state) => state.auth);
  const { influencers, orders, loading } = useSelector(
    (state) => state.influencerDetails
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (influencers?.length === 0 && auth) dispatch(allInfluencers());
    if (orders?.length === 0 && auth) dispatch(allOrders());
  }, [dispatch, auth]);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }
  return <Outlet />;
}

export default InfluencerGroup;
