import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { getOrderDetails } from "../../actions/customerActions";
import OrderTable from "../viewOrderByStatus/OrderTable";

const SingleOrderDetails = () => {
  const [fulfillmentId, setFulfillmentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const handleChange = (event) => {
    const value = String(event.target.value).trim();
    setFulfillmentId(value);
  };
  const handleSearch = async () => {
    setLoading(true);
    const data = await getOrderDetails("fulfillment_id", fulfillmentId);
    if (data === 500) {
      return;
    } else {
      setTableData(data);
    }
    setLoading(false);
  };
  return (
    <div>
      <Stack direction={"row"} gap={2} my={3}>
        <TextField
          placeholder="order id"
          onChange={handleChange}
          value={fulfillmentId}
          variant="outlined"
        />
        <Button variant="contained" onClick={handleSearch} disabled={loading}>
          Search
        </Button>
      </Stack>
      {tableData.length > 0 && <OrderTable tableData={tableData} />}
    </div>
  );
};

export default SingleOrderDetails;
