import React, { useState } from "react";
import {
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";

const OrderNumberSelector = ({ orderNumbers, setOrderNumbers }) => {
  const [newOrderNumbers, setNewOrderNumbers] = useState("");

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        const parsedOrderNumbers = text
          .split(/\r?\n/)
          .map((num) => num.trim())
          .filter(Boolean);
        setOrderNumbers((prev) => [
          ...new Set([...prev, ...parsedOrderNumbers]),
        ]);
      };
      reader.readAsText(file);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const handleAddOrderNumbers = (e) => {
    e.preventDefault();
    const parsedOrderNumbers = newOrderNumbers
      .split(/\r?\n/)
      .map((num) => num.trim())
      .filter(Boolean);
    setOrderNumbers((prev) => [...new Set([...prev, ...parsedOrderNumbers])]);
    setNewOrderNumbers("");
  };

  const handleRemoveOrderNumber = (orderToRemove) => {
    setOrderNumbers((prev) => prev.filter((num) => num !== orderToRemove));
  };

  const handleClearAll = () => {
    setOrderNumbers([]);
  };

  return (
    <Paper
      elevation={4}
      sx={{ p: 3, maxWidth: 450, mx: "auto", mt: 4, borderRadius: 3 }}
    >
      <Typography variant="h6" fontWeight={600} gutterBottom align="center">
        Order Numbers
      </Typography>

      {/* Drag and Drop Upload Section */}
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #1976d2",
          p: 3,
          textAlign: "center",
          borderRadius: 2,
          backgroundColor: "#f5f5f5",
          cursor: "pointer",
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          transition: "0.3s",
          "&:hover": { backgroundColor: "#e3f2fd" },
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 48, color: "#1976d2" }} />
        <Typography variant="body2" mt={1}>
          Drag & Drop or Click to Upload CSV
        </Typography>
      </Box>

      {/* Multi-line Text Area Entry */}
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        mb={2}
        component="form"
        onSubmit={handleAddOrderNumbers}
      >
        <textarea
          rows="4"
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            fontSize: "14px",
            resize: "none",
          }}
          placeholder="Paste order numbers, one per line"
          value={newOrderNumbers}
          onChange={(e) => setNewOrderNumbers(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ whiteSpace: "nowrap" }}
        >
          Add
        </Button>
      </Box>

      {/* List of Added Order Numbers */}
      <List sx={{ maxHeight: 150, overflowY: "auto", p: 0 }}>
        {orderNumbers?.map((num, index) => (
          <ListItem
            key={index}
            sx={{
              backgroundColor: "#f1f1f1",
              mb: 1,
              borderRadius: 2,
              display: "flex",
              justifyContent: "space-between",
              px: 2,
            }}
          >
            <ListItemText primary={num} sx={{ fontSize: "14px" }} />
            <IconButton
              size="small"
              onClick={() => handleRemoveOrderNumber(num)}
            >
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          </ListItem>
        ))}
      </List>

      {/* Clear All Button */}
      {orderNumbers.length > 0 && (
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={handleClearAll}
          sx={{ mt: 2 }}
        >
          Clear All
        </Button>
      )}
    </Paper>
  );
};

export default OrderNumberSelector;
