import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  activeInfluencer,
  rejectInfluencer,
} from "../../actions/influencerActions";
import { toast } from "react-toastify";

const influencerTypes = [
  { label: "Nano 1", description: "1.2k - 4k" },
  { label: "Nano 2", description: "4k - 7.5k" },
  { label: "Micro 1", description: "7.5 - 15k" },
  { label: "Micro 2", description: "15k - 30k" },
  { label: "Macro", description: "30k - 75k" },
  { label: "Mega", description: "75k - 150k" },
  { label: "Mega+", description: "150k - 200k" },
  { label: "Celeb", description: "200k+" },
];

const RegistrationActionModal = ({ open, onClose, influencer, dispatch }) => {
  const [formData, setFormData] = useState({
    type: "",
    maxLineItems: "",
    maxCost: "",
    reel: 0,
    story: 0,
    static: 0,
    post: 0,
    optional: 0,
    optionalDescription: "",
    remarks: "",
    affiliateLink: "",
  });

  const [action, setAction] = useState(""); // 'accept' or 'reject'

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAction = (type) => {
    setAction(type);
  };

  const handleSubmit = () => {
    if (action === "accept") {
      if (
        !formData.type ||
        !formData.maxLineItems ||
        !formData.maxCost ||
        !formData.affiliateLink
      )
        return toast.error("All details are required");
      const body = {
        customer_id: influencer?.id,
        influencer_data: {
          ...influencer.influencer_data,
          type: formData.type,
          affiliate_link: formData.affiliateLink,
          status: "approved",
        },
        max_lineitems: {
          max_count: parseInt(formData.maxLineItems),
          max_cost: parseInt(formData.maxCost),
        },
        contents_count: {
          reel: parseInt(formData.reel),
          story: parseInt(formData.story),
          static: parseInt(formData.static),
          post: parseInt(formData.post),
          optional: {
            count: parseInt(formData.optional),
            description: formData.optionalDescription,
          },
        },
      };
      // Submit all details
      dispatch(activeInfluencer(body, influencer));
    } else if (action === "reject") {
      if (!formData.remarks) return toast.error("All details are required");
      const body = {
        customer_id: influencer?.id,
        influencer_data: {
          ...influencer.influencer_data,
          remarks: formData.remarks,
          status: "rejected",
        },
      };
      // Submit only remarks
      dispatch(rejectInfluencer(body, influencer));
    }
    // onClose();
  };

  if (!influencer) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Influencer Registration</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {/* Influencer Details in Grid */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Name:</strong> {influencer.displayName || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Email:</strong>{" "}
              {influencer.defaultEmailAddress?.emailAddress || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Phone:</strong>{" "}
              {influencer.defaultPhoneNumber?.phoneNumber || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Platform:</strong>{" "}
              {influencer.influencer_data?.social_media_platform || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Audience Count:</strong>{" "}
              {influencer.influencer_data?.audience_count || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Handle:</strong>{" "}
              {influencer.influencer_data?.account_handle || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Registered At:</strong>{" "}
              {influencer?.registered_at || "N/A"}
            </Typography>
          </Grid>
        </Grid>

        {action === "accept" ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                {/* Influencer Type */}
                <TextField
                  select
                  label="Select Type"
                  value={formData.type || ""}
                  onChange={(e) => handleChange("type", e.target.value)}
                  fullWidth
                  helperText={
                    influencerTypes.find((type) => type.label === formData.type)
                      ?.description || "Select an influencer type"
                  }
                >
                  {influencerTypes.map((type) => (
                    <MenuItem key={type.label} value={type.label}>
                      <Tooltip title={type.description} arrow placement="right">
                        <span>{type.label}</span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                {/* Max Line Items */}
                <TextField
                  label="Max Line Items"
                  type="number"
                  value={formData.maxLineItems}
                  onChange={(e) => handleChange("maxLineItems", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Max Cost"
                  type="number"
                  value={formData.maxCost}
                  onChange={(e) => handleChange("maxCost", e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <TextField
              label="Affiliate Link"
              type="text"
              value={formData.affiliateLink}
              onChange={(e) => handleChange("affiliateLink", e.target.value)}
              fullWidth
            />
            {/* Content Uploads */}
            <Typography variant="h6">Content Uploads</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Reel"
                  type="number"
                  value={formData.reel}
                  onChange={(e) => handleChange("reel", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Story"
                  type="number"
                  value={formData.story}
                  onChange={(e) => handleChange("story", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Static"
                  type="number"
                  value={formData.static}
                  onChange={(e) => handleChange("static", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Post"
                  type="number"
                  value={formData.post}
                  onChange={(e) => handleChange("post", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Optional"
                  type="number"
                  value={formData.optional}
                  onChange={(e) => handleChange("optional", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                {/* Optional Description */}
                {formData.optional > 0 && (
                  <TextField
                    label="Optional Description"
                    value={formData.optionalDescription}
                    onChange={(e) =>
                      handleChange("optionalDescription", e.target.value)
                    }
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
          </>
        ) : action === "reject" ? (
          <TextField
            label="Remarks"
            multiline
            rows={3}
            value={formData.remarks}
            onChange={(e) => handleChange("remarks", e.target.value)}
            fullWidth
            required
          />
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleAction("accept")}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleAction("reject")}
        >
          Reject
        </Button>
        {action && (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RegistrationActionModal;
