import * as React from "react";
import { useEffect, useState } from "react";
import {
  getSizesByType,
  materialColorArray,
  materialFabricArray,
  materialSoleFabricArray,
  materialSolePlaceArray,
} from "../../utils/helperArrays";
import {
  Table,
  Collapse,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Avatar,
  Box,
  TextField,
  MenuItem,
  Stack,
  Button,
  InputAdornment,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { generateSalesDataExcel } from "../../utils/excelUtils";
import SalesDateFilter from "./SalesDateFilter";

const materialFabricList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialFabricArray.sort((a, b) => a.name.localeCompare(b.name)),
];
const materialSoleFabricList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialSoleFabricArray.sort((a, b) => a.name.localeCompare(b.name)),
];
const materialColorList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialColorArray.sort((a, b) => a.name.localeCompare(b.name)),
];

const materialSolePlaceList = [
  { code: "All", name: "All" },
  ...materialSolePlaceArray.sort((a, b) => a.name.localeCompare(b.name)),
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, view, salesColumn }) {
  const [open, setOpen] = useState(false);

  const totalInventory = row.stock[0].reduce(
    (sum, item) => item.stock + sum,
    0
  );
  console.log("salesColumn", salesColumn);
  const OrderOfSizes = getSizesByType(row.type);
  const keys = Array.from({ length: salesColumn }, (_, i) =>
    (i + 1).toString()
  );
  const showRows = () => {
    return (
      <React.Fragment>
        {keys.map((k, i) => (
          <TableCell key={i}>{row[k] ? row[k].total : 0}</TableCell>
        ))}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Avatar
            src={row.src}
            sx={{ width: 100, height: 100 }}
            variant="square"
          />
        </TableCell>
        <TableCell>
          {new Date(row.publishedAt).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell>{row.sku}</TableCell>
        <TableCell>{row.name}</TableCell>
        {showRows()}
        <TableCell>{row.total}</TableCell>
        {row.material.length > 0 ? (
          <React.Fragment>
            <TableCell>
              <div>
                {row.material.map((m) => (
                  <p>{`${m.type}${m.place ? `(${m.place})` : ""} ${
                    m.color
                  } `}</p>
                ))}
              </div>
            </TableCell>
            <TableCell>
              <div>
                {row.material.map((m) => (
                  <p>{m.materialWeight}</p>
                ))}
              </div>
            </TableCell>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TableCell>Not found</TableCell>
            <TableCell>Not found</TableCell>
          </React.Fragment>
        )}
        <TableCell>{totalInventory}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={17}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <h3>Shopify Inventory</h3>
              <div style={{ display: "flex" }}>
                {row.stock[0].map((i, k) => (
                  <div key={k} style={{ paddingRight: "30px" }}>
                    <div style={{ color: "red" }}>{i.size}</div>
                    <div>
                      <b>{i.stock}</b>
                    </div>
                  </div>
                ))}
              </div>
            </Box>
            <Box sx={{ margin: 1 }}>
              <h3>Warehouse Inventory</h3>
              <div style={{ display: "flex" }}>
                {row.inventoryStock[0]
                  .sort(
                    (a, b) =>
                      OrderOfSizes.indexOf(a.size) -
                      OrderOfSizes.indexOf(b.size)
                  )
                  .map((i, k) => (
                    <div key={k} style={{ paddingRight: "30px" }}>
                      <div style={{ color: "red" }}>{i.size}</div>
                      <div>
                        <b>{i.stock}</b>
                      </div>
                    </div>
                  ))}
              </div>
            </Box>
            {/* material list */}
            <Box sx={{ margin: 1 }}>
              <h3>Material List</h3>
              {row.material.length > 0 ? (
                <div>
                  {row.material.map((i, k) => (
                    <div key={k} style={{ display: "flex" }}>
                      {/* type */}
                      <div style={{ paddingRight: "30px" }}>
                        <div>
                          <span style={{ color: "red" }}>Fabric </span>
                          <b>{i.type}</b>
                        </div>
                      </div>
                      {/* color */}
                      <div style={{ paddingRight: "30px" }}>
                        <div>
                          <span style={{ color: "red" }}>Color </span>
                          <b>{i.color}</b>
                        </div>
                      </div>
                      {/* amount */}
                      <div style={{ paddingRight: "30px" }}>
                        <div>
                          <span style={{ color: "red" }}>Amount </span>
                          <b>{i.materialWeight}</b>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No material list found</h4>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function SalesTable({
  tableData,
  productType,
  categoryTypes,
  salesView,
  salesColumn,
}) {
  const [data, setData] = useState(
    tableData.sort((a, b) => Number(a.total) - Number(b.total))
  );
  const [filteredData, setFilteredData] = useState([]);

  const [desc, setDesc] = useState(false);
  const [weekNumber, setWeekNumber] = useState("");
  const [weekdesc, setWeekDesc] = useState(false);
  // const [week1desc, setWeek1Desc] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [categoryType, setCategoryType] = useState("");
  const [materialColor, setMaterialColor] = useState("All");
  const [materialFabric, setMaterialFabric] = useState("All");
  const [fabricArr, setFabricARR] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [materialPlace, setMaterialPlace] = useState("All");
  // date filters
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSizes(getSizesByType(productType));
    setData(
      [...tableData].sort((a, b) => Number(a.total) - Number(b.total)).reverse()
    );
    setCategoryType("");
  }, [productType, tableData]);

  useEffect(() => {
    if (desc) {
      const newData = [...tableData].reverse();
      setData(newData);
    } else {
      const newData = [...tableData];
      setData(newData);
    }
  }, [desc, tableData]);

  useEffect(() => {
    // weeks
    if (salesView === 0) {
      if (weekdesc && weekNumber !== "") {
        const toggleWeek1Data = [...tableData]
          .sort(
            (a, b) => Number(a[`${weekNumber}`]) - Number(b[`${weekNumber}`])
          )
          .reverse();
        // const newData = [...toggleWeek1Data]
        setData(toggleWeek1Data);
      } else {
        const toggleWeek1Data = [...tableData].sort(
          (a, b) => Number(a[`${weekNumber}`]) - Number(b[`${weekNumber}`])
        );
        setData(toggleWeek1Data);
      }
    } else {
      if (weekdesc && weekNumber !== "") {
        const toggleTriData = [...tableData]
          .sort(
            (a, b) =>
              Number(a[`${weekNumber}`]?.total || 0) -
              Number(b[`${weekNumber}`]?.total || 0)
          )
          .reverse();
        setData(toggleTriData);
      } else if (!weekdesc && weekNumber !== "") {
        const toggleTriData = [...tableData].sort(
          (a, b) =>
            Number(a[`${weekNumber}`]?.total || 0) -
            Number(b[`${weekNumber}`]?.total || 0)
        );
        setData(toggleTriData);
      }
    }
  }, [weekdesc, weekNumber, salesView, salesColumn, tableData]);

  useEffect(() => {
    if (categoryType && productType) {
      const filterTableData = tableData.filter((p) =>
        p.sku?.includes(categoryType)
      );

      if (filterTableData.length > 0) {
        const newData = [...filterTableData].reverse();
        setData(newData);
      } else {
        setData([]);
      }
    } else {
      setData(
        [...tableData]
          .sort((a, b) => Number(a.total) - Number(b.total))
          .reverse()
      );
    }
    setPage(0);
  }, [categoryType, categoryTypes, productType, tableData]);
  // filter material wise and date wise
  useEffect(() => {
    let dataAfterMaterialFilter = [];
    if (materialColor === "No material" || materialFabric === "No material") {
      const filtered = data.filter((d) => d.material.length === 0);
      dataAfterMaterialFilter = [...dataAfterMaterialFilter, ...filtered];
    } else {
      const filtered = data.filter(
        (d) =>
          (d.material.some((m) => m.color === materialColor) ||
            materialColor === "All") &&
          (d.material.some((m) => m.type === materialFabric) ||
            materialFabric === "All") &&
          (d.material.some((m) => m.place === materialPlace) ||
            materialPlace === "All")
      );
      dataAfterMaterialFilter = [...dataAfterMaterialFilter, ...filtered];
    }
    if (openDateFilter) {
      const dateFiltered = dataAfterMaterialFilter.filter((d) => {
        return (
          new Date(d.publishedAt) >= new Date(date.startDate) &&
          new Date(d.publishedAt) <= new Date(date.endDate)
        );
      });
      setFilteredData(dateFiltered);
    } else {
      setFilteredData(dataAfterMaterialFilter);
    }
  }, [
    data,
    materialFabric,
    materialColor,
    materialPlace,
    date,
    openDateFilter,
  ]);

  // set fabric array
  useEffect(() => {
    if (productType === "Shoes" || productType === "Men Shoes") {
      setFabricARR(materialSoleFabricList);
    } else {
      setFabricARR(materialFabricList);
    }
  }, [productType]);

  // for excel data
  useEffect(() => {
    const excel_formatted = filteredData.map((fd) => ({
      name: fd.name,
      week1Sale: "1" in fd ? fd["1"].total : 0,
      week2Sale: "2" in fd ? fd["2"].total : 0,
      week3Sale: "3" in fd ? fd["3"].total : 0,
      week4Sale: "4" in fd ? fd["4"].total : 0,
      sku: fd.sku,
      src: fd.src,
      material: fd.material,
      total: fd.total,
    }));
    setExcelData(excel_formatted);
  }, [filteredData]);
  const handleExcelDownload = () => {
    generateSalesDataExcel(excelData);
  };

  const showSalesHeader = (value) => {
    if (value === 0) {
      return (
        <React.Fragment>
          <TableCell
            onClick={() => {
              setWeekNumber("1");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 1<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("2");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 2<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("3");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 3<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("4");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 4<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell onClick={() => setDesc(!desc)}>
            <div style={{ display: "flex" }}>
              Total
              <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
        </React.Fragment>
      );
    } else if (value === 1) {
      return (
        <React.Fragment>
          <TableCell
            onClick={() => {
              setWeekNumber("1");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              0-3 days
              <div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("2");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              3-6 days
              <div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("3");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              6-9 days
              <div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("4");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              9-12 days
              <div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("5");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              12-15 days
              <div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          {/* <TableCell>Week 2</TableCell>
                  <TableCell>Week 3</TableCell>
                  <TableCell>Week 4</TableCell> */}
          <TableCell onClick={() => setDesc(!desc)}>
            <div style={{ display: "flex" }}>
              Total
              <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
        </React.Fragment>
      );
    } else if (value === 6) {
      return (
        <React.Fragment>
          <TableCell
            onClick={() => {
              setWeekNumber("1");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 1<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("2");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 2<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("3");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 3<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("4");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 4<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("5");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 5<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("6");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 6<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              setWeekNumber("7");
              setWeekDesc(!weekdesc);
            }}
          >
            <div style={{ display: "flex" }}>
              Week 7<div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
          <TableCell onClick={() => setDesc(!desc)}>
            <div style={{ display: "flex" }}>
              Total
              <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {Array.from({ length: salesColumn }, (_, i) => i + 1).map((day) => (
            <TableCell
              onClick={() => {
                setWeekNumber(String(day));
                setWeekDesc(!weekdesc);
              }}
            >
              <div style={{ display: "flex" }}>
                {`${day} day`}
                <div>{weekdesc ? <ArrowDownward /> : <ArrowUpward />}</div>
              </div>
            </TableCell>
          ))}
          <TableCell onClick={() => setDesc(!desc)}>
            <div style={{ display: "flex" }}>
              Total
              <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
            </div>
          </TableCell>
        </React.Fragment>
      );
    }
  };
  return (
    <>
      <Stack
        direction={"row"}
        gap={3}
        sx={{
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            width: "250px",
          }}
        >
          <TextField
            // style={{ width: "500px" }}
            select
            value={categoryType}
            onChange={(e) => setCategoryType(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    style={{
                      fontWeight: 800,
                      borderRight: "1px solid black",
                      paddingRight: "4px",
                    }}
                  >
                    Category
                  </p>
                </InputAdornment>
              ),
            }}
          >
            {categoryTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Paper>
        <div>
          {openDateFilter ? (
            <Paper>
              <Stack
                direction={"row"}
                sx={{
                  alignItems: "center",
                }}
              >
                <SalesDateFilter setDate={setDate} />
                <Box
                  onClick={() => setOpenDateFilter(false)}
                  sx={{
                    "& :hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <HighlightOffIcon
                    sx={{
                      mx: 1,
                      color: "red",
                    }}
                  />
                </Box>
              </Stack>
            </Paper>
          ) : (
            <Paper>
              <Button
                variant="outlined"
                sx={{
                  py: 0.8,
                }}
                size="medium"
                onClick={() => setOpenDateFilter(true)}
              >
                Open date filter
              </Button>
            </Paper>
          )}
        </div>
      </Stack>
      <br />
      {/* material filter */}
      <Stack direction={"row"} gap={3}>
        {/* fabric */}
        <Paper>
          <TextField
            style={{ width: "300px" }}
            select
            value={materialFabric}
            onChange={(e) => setMaterialFabric(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    style={{
                      fontWeight: 800,
                      borderRight: "1px solid black",
                      paddingRight: "4px",
                    }}
                  >
                    Fabric
                  </p>
                </InputAdornment>
              ),
            }}
          >
            {fabricArr.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Paper>
        {/* color */}
        <Paper>
          <TextField
            style={{ width: "250px" }}
            select
            value={materialColor}
            onChange={(e) => setMaterialColor(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    style={{
                      fontWeight: 800,
                      borderRight: "1px solid black",
                      paddingRight: "4px",
                    }}
                  >
                    Color
                  </p>
                </InputAdornment>
              ),
            }}
          >
            {materialColorList.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Paper>
        {/* material place */}
        <Paper>
          <TextField
            style={{ width: "250px" }}
            select
            value={materialPlace}
            onChange={(e) => setMaterialPlace(e.target.value)}
            variant="outlined"
            className="select"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    style={{
                      fontWeight: 800,
                      borderRight: "1px solid black",
                      paddingRight: "4px",
                    }}
                  >
                    place
                  </p>
                </InputAdornment>
              ),
            }}
          >
            {materialSolePlaceList.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Paper>
      </Stack>
      <br />
      <br />
      {/* download excel button */}
      <Button variant="outlined" onClick={handleExcelDownload}>
        Download excel
      </Button>
      <br />
      {filteredData.length > 0 && sizes.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>published at</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Name</TableCell>
                  {showSalesHeader(salesView)}
                  <TableCell>Material Name</TableCell>
                  <TableCell>Material Amt</TableCell>
                  <TableCell>Check Stock</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(rowsPerPage > 0
                  ? filteredData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredData
                ).map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    view={salesView}
                    salesColumn={salesColumn}
                  />
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ overflow: "inherit" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        <h4>No products found</h4>
      )}
    </>
  );
}
