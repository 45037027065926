import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Typography,
  Modal,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Checkbox,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { showFullScreenLoader } from "../../actions/loaderActions";
import {
  replenishDamagedRTOorder,
  updateDamagedRtoOrders,
  markDamagedRTOorder,
} from "../../actions/ordersActions";
import { updateReconProcessedAt } from "../../actions/reverseDeliveryActions";
import { toast } from "react-toastify";
import { markDamagedREOorder } from "../../actions/reconActions";

export const RtoDamagedModal = ({
  open,
  setRtoSelectDamagedModal,
  orderProducts,
  productVariants,
  orderId,
  setSearchValue,
  setSearchAwbValue,
  order_type
}) => {
  const dispatch = useDispatch();
  const [openUp, setOpenUp] = useState(open);
  const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);
  const [isDamagedMarked, setIsDamagedMarked] = useState(false);

  const handleClose = () => {
    setOpenUp(false);
    setRtoSelectDamagedModal(false);
    setSelectedOrderProducts([]);
  };

  const handleSubmit = async () => {
    // select the lineitems which were mark damaged
    const toBemarkedDamagedItems = selectedOrderProducts.map((x) => ({
      ...x,
      damaged: true,
    }));

    // update recon table record
    const reconPayload = {
      fulfillment_id: orderId,
      line_items: orderProducts.map((l) => {
        // add a key damaged only for damaged items
        const found = toBemarkedDamagedItems.find((p) => p.sku === l.sku);
        if (!!found) {
          return {
            ...l,
            damaged: true,
          };
        } else {
          return l;
        }
      }),
    };
    console.log("reconPayload", reconPayload);

    const toBeUpdatedBody = {
      fulfillment_id: orderId,

      damaged_order_items: toBemarkedDamagedItems,
    };

    markDamagedRTOorder(toBeUpdatedBody);
    setIsDamagedMarked(true);
    setSearchValue("");
    setSearchAwbValue("");
    //CHANGE REDUX VALUES
    dispatch({
      type: "DELETESINGLE",
      payload: String(toBeUpdatedBody.fulfillment_id),
    });
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "whitesmoke",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "scroll",
    maxWidth: "90%",
  };

  return (
    <div>
      <Modal
        open={openUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Select Damaged Items</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <>
                      <Checkbox
                        color="primary"
                        checked={
                          selectedOrderProducts.length > 0 ? true : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrderProducts(orderProducts);
                          } else {
                            setSelectedOrderProducts([]);
                          }
                        }}
                      />
                      {`${selectedOrderProducts.length} Selected`}
                    </>
                  </TableCell>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">SKU</TableCell>
                  <TableCell align="left">Size</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderProducts.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      <Checkbox
                        color="primary"
                        checked={
                          selectedOrderProducts.filter(
                            (i) => i.name === row.name
                          ).length > 0
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrderProducts([
                              ...selectedOrderProducts,
                              row,
                            ]);
                          } else {
                            setSelectedOrderProducts(
                              selectedOrderProducts.filter(
                                (item) => item.name !== row.name
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      <Avatar
                        src={row.url}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: "ro",
                        }}
                        variant="square"
                      />
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.sku.split(".")[0]}</TableCell>
                    <TableCell align="left">
                      {row.variant_title
                        ? row.variant_title
                        : row.sku.split(".")[1]}
                    </TableCell>
                    <TableCell align="left">{row.units}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          {isDamagedMarked ? (
            <div>
              <CheckCircleIcon color="success" />
              <Typography variant="body1" color="green">
                Damaged items successfully marked!
              </Typography>
            </div>
          ) : (
            <Button
              variant="contained"
              color="error"
              disabled={selectedOrderProducts.length === 0 || isDamagedMarked}
              onClick={handleSubmit}
            >
              DAMAGED
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
};
