import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const DamageTable = ({ data }) => {
    return (
        <Paper sx={{ p: 2, mt: 2, boxShadow: 3 }}>
            <Typography variant="h6" gutterBottom>
                Damage Records
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>SKU</strong></TableCell>
                            <TableCell><strong>Size</strong></TableCell>
                            <TableCell><strong>Count</strong></TableCell>
                            <TableCell><strong>Date</strong></TableCell>
                            <TableCell><strong>Entry Type</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 ? (
                            data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.sku}</TableCell>
                                    <TableCell>{row.size}</TableCell>
                                    <TableCell>{row.count}</TableCell>
                                    <TableCell>{new Date(row.date).toLocaleDateString()}</TableCell>
                                    <TableCell>{row.entryType}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} align="center">No data available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default DamageTable;
