import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSizesByType, productTypes, skuTypes } from "../utils/helperArrays";
import { toast } from "react-toastify";
const XLSX = require('xlsx');

// Create a new workbook
const newWorkbook = XLSX.utils.book_new();

const InventoryValue = () => {
    const { stock } = useSelector((state) => state.inventoryDetails);
    const [inventoryTotal, setInventoryTotal] = useState("0");
    const [totalClothingCost, setTotalClothingCost] = useState("0");
    const [totalFootwearCost, setTotalFootwearCost] = useState("0");
    const [notUpdatedItems, setNotUpdatedItems] = useState([]);



    // function to generate Excell
    // const generateExcell = (data) => {
    //     const groupedData = 
    //     data.reduce((acc, row) => {
    //         const found = acc.find(ac => ac.SKU === row.SKU)
    //         if(found){
    //             found[row.size] = row.count
    //             found.Total = (found.Total || 0) + row.count;
    //         }else{
    //             if(row.type){
    //                 const newObj = {
    //                     SKU : row.SKU,
    //                     category : row.category,
    //                     type : row.type,
    //                     [row.size] : row.count,
    //                     Total: row.count

    //                 }
    //                 acc.push(newObj)
    //             }
    //         }
    //         return acc
    //     },[]).reduce((acc, item) => {
    //         acc[item.type] = [item.type] in acc ? [...acc[item.type], {...item}] : [{...item}]
    //         return acc;
    //     }, {});


    //     Object.keys(groupedData).forEach(type => {
    //         const worksheet = XLSX.utils.json_to_sheet(groupedData[type], {
    //             header : [
    //                 "SKU",
    //                 "type",
    //                 "category",
    //                 ...getSizesByType(type),
    //                 "Total"
    //             ]
    //         });
    //         XLSX.utils.book_append_sheet(newWorkbook, worksheet, type); // Use type as sheet name
    //     });

    //     // Define the output file path
    //     const filePath = `inventory${new Date().toISOString().split('T')[0]}.xlsx`;

    //     // Write the Excel file
    //     XLSX.writeFile(newWorkbook, filePath);

    //     toast.success(`Excel file successfully created with separate sheets with fileName ${filePath}`);
    // }

    const generateExcell = (data) => {
        const newWorkbook = XLSX.utils.book_new(); // Ensure new workbook is created

        const groupedData = data.
        reduce((acc, row) => {
            const found = acc.find(ac => ac.SKU === row.SKU);
            if (found) {
                if (getSizesByType(row.type).includes(row.size)) { // Only add valid sizes
                    found[row.size] = row.count;
                }
                found.Total = (found.Total || 0) + row.count; // Update total
                found.TotalCP = (parseFloat(found.TotalCP) || 0) + parseFloat(row.count) * parseFloat(row.costPrice)
            } else {
                if (row.type) {
                    const newObj = {
                        SKU: row.SKU,
                        type: row.type,
                        category: row.category,
                        Total: row.count,
                        TotalCP: parseFloat(row.count) * parseFloat(row.costPrice)
                    };

                    // Initialize all valid sizes with 0
                    const validSizes = getSizesByType(row.type);
                    validSizes.forEach(size => newObj[size] = 0);

                    // Only add the size if it's valid
                    if (validSizes.includes(row.size)) {
                        newObj[row.size] = row.count;
                    }

                    acc.push(newObj);
                }
            }
            return acc;
        }, [])
        .reduce((acc, item) => {
            acc[item.type] = item.type in acc ? [...acc[item.type], { ...item }] : [{ ...item }];
            return acc;
        }, {});

        Object.keys(groupedData).forEach(type => {
            const validSizes = getSizesByType(type);

            const headers = ["SKU", "type", "category", ...validSizes, "Total", "TotalCP"];

            const worksheet = XLSX.utils.json_to_sheet(groupedData[type], { header: headers });

            XLSX.utils.book_append_sheet(newWorkbook, worksheet, type); // Use type as sheet name
        });

        // Define the output file path
        const filePath = `inventory${new Date().toISOString().split('T')[0]}.xlsx`;

        // Write the Excel file
        XLSX.writeFile(newWorkbook, filePath);

        toast.success(`Excel file successfully created with separate sheets with fileName ${filePath}`);
    };


    // find category based on SKU
    const getCategoryFromSKU = (sku) => {
        if (!sku) return null;

        const regex = new RegExp("([a-zA-Z]+)", "g");
        const splitSKU = sku.match(regex)?.[0];

        // Use skuTypes array to find the correct category
        const matchedType = skuTypes.find((type) => type.value === splitSKU);

        return matchedType ? matchedType.label : null;
    };

    useEffect(() => {
        if (stock.length > 0) {
            console.log(
                "STOCKm",
                stock.filter(p => !p.breadth).map(p => p.SKU)
            );
            const unprocessedItems = [];
            const inventoryValue = stock.reduce(
                (sum, item) => {
                    const category = getCategoryFromSKU(item?.SKU);

                    if (
                        isNaN(parseFloat(item?.costPrice)) ||
                        !("count" in item) ||
                        !category
                    ) {
                        unprocessedItems.push(item);
                        return sum;
                    } else {
                        const itemTotal =
                            parseFloat(item.costPrice) * item.count;

                        if (
                            [
                                "Clothing",
                                "Men's Clothing",
                                "Plus-Curve",
                                "Lingerie and Athleisure",
                            ].includes(category)
                        ) {
                            sum.clothing += itemTotal;
                        } else if (category === "Footwear") {
                            sum.footwear += itemTotal;
                        }
                        sum.total += itemTotal;

                        return sum;
                    }
                },
                { total: 0, clothing: 0, footwear: 0 }
            );

            console.log("Final Totals:", inventoryValue);

            setInventoryTotal(inventoryValue.total.toFixed(2));
            setTotalClothingCost(inventoryValue.clothing.toFixed(2));
            setTotalFootwearCost(inventoryValue.footwear.toFixed(2));
            setNotUpdatedItems(unprocessedItems);
            console.log("Not Updated Items:", unprocessedItems);
        }
    }, [stock]);

    return (
        <Box my={2}>
            <Paper
                sx={{
                    px: 4,
                    py: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginBottom: 2 }}
                >
                    Present Inventory Value
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    Total: ₹{inventoryTotal}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    Total Clothing Cost Price: ₹{totalClothingCost}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    Total Footwear Cost Price: ₹{totalFootwearCost}
                </Typography>
            </Paper>
            {<Button
                variant="contained"
                fullWidth
                onClick={() => generateExcell(stock)}>
                Download Todays Stock Snapshpot
            </Button>}
        </Box>
    );
};

export default InventoryValue;
