import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Backdrop,
    CircularProgress,
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TableFooter,
    TablePagination,
    IconButton,
    Switch,
    FormControlLabel,
    Typography,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
    getAllScanSingleDate,
    getReconDetailsByFulfillmentId,
} from "../../actions/reconActions";
import { updateReconProcessedAt } from "../../actions/reverseDeliveryActions";
import {
    updateRtoDeliveredOrders,
    searchAWB,
} from "../../actions/ordersActions"; //updateShopifyInventory and then replenishRTOorder(orderId)

import { allInventory } from "../../actions/inventoryActions";
import { useTheme } from "@emotion/react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { RtoDamagedModal } from "../rto/RtoDamagedModal";
import { useNavigate } from "react-router-dom";
import { ReDamagedModal } from "../rto/ReDamagedModal";
import { UpdateInventoryModal } from "./UpdateInventoryModal";

const showToastMessage = (msg, isError) => {
    if (isError) {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
        });
    } else {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <ArrowUpwardIcon />
                ) : (
                    <ArrowDownwardIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeftIcon />
                ) : (
                    <KeyboardArrowRightIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    );
}

const ScanRTO = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const orders = useSelector((state) => state.recon.reconDamageDetails);
    const { shopify_product_variants } = useSelector(
        (state) => state.shopifyDetails
    );
    const { stock } = useSelector((state) => state.inventoryDetails);
    const [searchAwbValue, setSearchAwbValue] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [awbSearchLoading, setAwbSearchLoading] = useState(false);
    const [totalNumScanRto, setTotalNumScanRto] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
    const [selectRtoDamagedModal, setRtoSelectDamagedModal] = useState(false);
    const [selectReDamagedModal, setReSelectDamagedModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [isInQueue, setIsInQueue] = useState(false);
    const [openInventoryUpdateModal, setOpenInventoryUpdateModal] =
        useState(false);
    const [orderType, setOrderType] = useState("RTO");

    // Ensure that the page is within the valid range
    useEffect(() => {
        const maxPage = Math.max(
            0,
            Math.ceil(orderData.length / rowsPerPage) - 1
        );
        if (page > maxPage) {
            setPage(maxPage);
        }
    }, [orderData.length, rowsPerPage, page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDamagedClick = (order) => {
        // PUT product images in the order
        const updatedOrder = {
            ...order,
            line_items: order.line_items.map((p) => {
                const found = stock.find((i) => p.sku === i.SKU);
                if (!found) {
                    //If not found, just return p
                    console.log("NOT FOUND", p);
                    return {
                        ...p,
                        imageUrl: "",
                    };
                } else {
                    // If found return with imageURL attached
                    console.log("FOUND", found);
                    return {
                        ...p,
                        imageUrl: found.imageUrl,
                    };
                }
            }),
        };
        setSelectedOrder(updatedOrder);
        if (order?.type === "RTO") {
            setRtoSelectDamagedModal(true);
        } else if (order?.type === "R/E") {
            setReSelectDamagedModal(true);
        }
    };

    useEffect(() => {
        setPage(0);
        setTotalNumScanRto(orderData.length);
    }, [orderData]);

    useEffect(() => {
        setPage(0);
        setLoading(true);
        const filteredOrders = orders.filter(
            (order) => order.type === orderType && order.isVerified !== true
        );
        setOrderData(filteredOrders);
        setTotalNumScanRto(filteredOrders.length);
        console.log("orderData", filteredOrders);
        setLoading(false);
    }, [orders, orderType]);

    useEffect(() => {
        //LOAD THE STOCK to get image URL
        if (stock.length === 0) dispatch(allInventory());
        const fetchData = () => {
            try {
                setLoading(true);
                const today = new Date();
                const inputDateStr = today.toISOString().split("T")[0];
                const inputDate = new Date(inputDateStr);
                // Subtract number of required days from the current date
                inputDate.setDate(inputDate.getDate() - 7);

                const formattedDate = inputDate.toISOString().split("T")[0];

                dispatch(getAllScanSingleDate(formattedDate, "damage"));
            } catch (err) {
                console.log("Error", err);
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchFlag = async () => {
            const res = await getReconDetailsByFulfillmentId("0");
            if (res.statusCode === 200 && res.body[0].inQueue !== 0) {
                setIsInQueue(true);
            } else {
                setIsInQueue(false);
            }
        };
        fetchFlag();
    }, []);

    useEffect(() => {
        console.log("isInQueue", isInQueue);
    }, [isInQueue]);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        // Checks the 'isVerified' key --> allows to discard already mark 'damaged' orders
        const filteredSearchedOrders = orderData.filter(
            (order) =>
                !order.isVerified && order.awb_code === searchAwbValue.trim()
        );
        if (filteredSearchedOrders.length > 0) {
            setOrderData(filteredSearchedOrders);
            showToastMessage("Order found!", false);
        } else {
            showToastMessage("Order not found with the provided AWB.", true);
        }
    };

    const handleUpdateInventory = () => {
        setOpenInventoryUpdateModal(true);
    };

    return (
        <>
            {awbSearchLoading && stock.length === 0 && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={awbSearchLoading}
                >
                    <CircularProgress size={100} color="inherit" />
                </Backdrop>
            )}

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={3}
                gap={2}
            >
                <form
                    onSubmit={handleSearchSubmit}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <TextField
                        placeholder="Search Order with AWB"
                        value={searchAwbValue}
                        onChange={(e) => setSearchAwbValue(e.target.value)}
                        disabled={awbSearchLoading}
                        variant="outlined"
                        style={{ width: 300, marginRight: "10px" }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={awbSearchLoading}
                        // onClick={handleClearSearch}
                    >
                        Search
                    </Button>
                </form>

                <Button
                    type="onClick"
                    variant="contained"
                    color="error"
                    onClick={() => navigate("/temp")}
                >
                    Back
                </Button>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={4}
                sx={{
                    backgroundColor: "#f5f5f5",
                    padding: "12px 24px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: "bold",
                        color: "#333",
                        marginRight: "16px",
                    }}
                >
                    RE
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            size="large"
                            checked={orderType === "RTO"}
                            onChange={() =>
                                setOrderType(
                                    orderType === "RTO" ? "R/E" : "RTO"
                                )
                            }
                            sx={{
                                "& .MuiSwitch-thumb": {
                                    width: 24,
                                    height: 24,
                                },
                                "& .MuiSwitch-track": {
                                    width: 100,
                                    height: 20,
                                },
                            }}
                        />
                    }
                />
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: "bold",
                        color: "#333",
                        marginLeft: "16px",
                    }}
                >
                    RTO
                </Typography>
            </Box>

            <Paper elevation={3} style={{ gap: "8px" }}>
                {orders && (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sl No</TableCell>
                                    <TableCell>Order Number</TableCell>
                                    <TableCell>AWB</TableCell>
                                    <TableCell>RTO Date</TableCell>
                                    <TableCell>Delivery Date</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Courier Name</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <Box sx={{ display: "flex" }}>
                                        <CircularProgress />
                                    </Box>
                                ) : isInQueue && orderType === "RTO" ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: "15px 20px",
                                                backgroundColor: "#ffeb3b",
                                                color: "#333",
                                                borderRadius: "8px",
                                                fontSize: "18px",
                                                fontWeight: "bold",
                                                boxShadow:
                                                    "0px 4px 6px rgba(0,0,0,0.1)",
                                            }}
                                        >
                                            You are in the queue. Please wait
                                            while we process your request...
                                        </div>
                                    </div>
                                ) : orderData.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    padding: 3,
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    color="textSecondary"
                                                >
                                                    No orders found!
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    (rowsPerPage > 0
                                        ? orderData.slice(
                                              page * rowsPerPage,
                                              page * rowsPerPage + rowsPerPage
                                          )
                                        : orderData
                                    ).map((order, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {page * rowsPerPage + index + 1}
                                            </TableCell>
                                            <TableCell>
                                                {order.fulfillment_id}
                                            </TableCell>
                                            <TableCell>
                                                {order.awb_code}
                                            </TableCell>
                                            <TableCell>
                                                <span>
                                                    {new Date(
                                                        order.created_at
                                                    ).toLocaleDateString(
                                                        "en-IN"
                                                    )}
                                                </span>
                                                <br />
                                                <span>
                                                    {new Date(
                                                        order.created_at
                                                    ).toLocaleTimeString(
                                                        "en-IN"
                                                    )}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span>
                                                    {new Date(
                                                        order.processed_at
                                                    ).toLocaleDateString(
                                                        "en-IN"
                                                    )}
                                                </span>
                                                <br />
                                                <span>
                                                    {new Date(
                                                        order.processed_at
                                                    ).toLocaleTimeString(
                                                        "en-IN"
                                                    )}
                                                </span>
                                            </TableCell>
                                            <TableCell>{order.type}</TableCell>
                                            <TableCell>
                                                {order?.courier_name || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() =>
                                                        handleDamagedClick(
                                                            order
                                                        )
                                                    }
                                                >
                                                    Action
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        style={{ overflow: "inherit" }}
                                        rowsPerPageOptions={[
                                            5,
                                            10,
                                            25,
                                            { label: "All", value: -1 },
                                        ]}
                                        colSpan={3}
                                        count={orderData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                )}
            </Paper>

            {selectRtoDamagedModal && selectedOrder && (
                <RtoDamagedModal
                    orderId={selectedOrder.fulfillment_id}
                    open={selectRtoDamagedModal}
                    setRtoSelectDamagedModal={setRtoSelectDamagedModal}
                    orderProducts={selectedOrder.line_items}
                    productVariants={shopify_product_variants}
                    setSearchValue={setSearchValue}
                    setSearchAwbValue={setSearchAwbValue}
                    awb={searchAwbValue}
                    order_type={selectedOrder.type}
                />
            )}

            {selectReDamagedModal && selectedOrder && (
                <ReDamagedModal
                    orderId={selectedOrder.fulfillment_id}
                    open={selectReDamagedModal}
                    setReSelectDamagedModal={setReSelectDamagedModal}
                    selectedOrder={selectedOrder}
                    orderProducts={selectedOrder.line_items}
                    productVariants={shopify_product_variants}
                    setSearchValue={setSearchValue}
                    setSearchAwbValue={setSearchAwbValue}
                    awb={searchAwbValue}
                    order_type={selectedOrder.type}
                />
            )}

            {openInventoryUpdateModal && (
                <UpdateInventoryModal
                    open={openInventoryUpdateModal}
                    setOpenInventoryUpdateModal={setOpenInventoryUpdateModal}
                    orderData={orderData}
                    // orderData={testOrderData}
                    setIsInQueue={setIsInQueue}
                />
            )}
            {orderType === "RTO" ? (
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdateInventory}
                    >
                        Update Inventory for All Orders
                    </Button>
                </Box>
            ) : (
                ""
            )}

            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/damagedHistory")}
                >
                    Damaged History
                </Button>
            </Box>
        </>
    );
};

export default ScanRTO;
