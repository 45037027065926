import React from "react";
import { trackingLifecycleTableHeadings } from "../../../utils/helperArrays";

const ItemRow = ({ row, index }) => (
    <tr
        className="tracking-table-row-styles"
        style={{ background: index % 2 === 0 ? "#fefefe" : "#f2f2f2" }}
    >
        <td>{row.fulfillment_id}</td>
        <td>{row.package_status}</td>
        <td>TextBox?</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>7</td>
        <td>{row.awb_code}</td>
        <td>{row.fulfillment_id}</td>
        <td>{row.shipping_customer_name + " " + row.shipping_last_name}</td>
        <td>{row.shipping_city}</td>
        <td>{row.shipping_state}</td>
        <td>{row.shipping_country}</td>
        <td>{row.shipping_address + " " + row.shipping_address_2}</td>
        <td>{row.shipping_pincode}</td>
        <td>{row.shipping_phone}</td>
        <td>{row.weight}</td>
        <td>{row.length}</td>
        <td>{row.breadth}</td>
        <td>{row.height}</td>
        <td>{row.payment_method}</td>
        <td>{row.total}</td>
        <td>{row.payment_method === "COD" ? row.total : "0.00"}</td>
        <td>
            {row.order_items.map((p, i) => (
                <span key={i}>{p.sku + "(" + p.units + ") "}</span>
            ))}
        </td>
        <td>{row.order_items.reduce((sum, p) => sum + p.units, 0)}</td>
        <td>{row.pickup_location}</td>
        <td>
            KH.NO-382, F/F, Front Side 100 Phoota Road Village Ghitorni, New
            Delhi-110030, India
        </td>
        <td>110030</td>
        <td>SURFACE</td>
    </tr>
);
const DispatchesTrackingTable = ({ orderData }) => {
    console.log("MERGED", orderData);
    // CREATE A TABLE THAT SCROLLS HORIZONTALLY AND LOOKS LIKE G-SHEETS
    return (
        <div
            style={{
                width: "100%",
                minHeight: "100px",
                backgroundColor: "white",
            }}
        >
            {/* Not using mui */}
            <table
                style={{
                    borderSpacing: "1px",
                    backgroundColor: "#ddd",
                }}
            >
                <thead>
                    <tr style={{ backgroundColor: "green" }}>
                        {trackingLifecycleTableHeadings.map((item, i) => (
                            <th
                                style={{ padding: "5px 10px", color: "white" }}
                                key={i}
                            >
                                {item}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {orderData.map((item, i) => (
                        <ItemRow key={i} index={i} row={item} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DispatchesTrackingTable;
