import UpdateStock from "../components/pages/ChangeStock";
import { lambdaFetch } from "../utils/awsUtils";
import { showModal, showUnauthorizedModal } from "./errorModalActions";
import { toast } from "react-toastify";

const showLoading = () => {
    return {
        type: "SHOW_PO_LOADER",
    };
};

const hideLoading = () => {
    return {
        type: "HIDE_PO_LOADER",
    };
};

export const selectPOProducts = (data) => {
    return {
        type: "SELECT_PRODUCTS",
        products: data,
    };
};

export const getLastPOId = async () => {
    const URL =
        "https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/lbilastpoid";
    try {
        const response = await fetch(URL);
        const data = await response.json();
        console.log(data);
        if (data.statusCode === 200) {
            console.log("PO Fetched SuccessFully");
            return {
                success: true,
                id: data.body,
            };
        } else {
            return {
                success: false,
                message: "Server error",
            };
        }
    } catch (err) {
        return {
            success: false,
            message: err,
        };
    }
};

export const postPOObject =
    ({ poObject, poId }) =>
    async (dispatch) => {
        const payload = {
            ...poObject,
            POID: "LB",
            client: "LBI",
        };
        const URL =
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/po/createpo";
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify(payload),
            });
            const data = await response.json();
            console.log(data);
            if (data.statusCode === 200) {
                console.log("PO Created SuccessFully");
                dispatch(selectPOProducts([]));
                localStorage.setItem("selectedProducts", []);
                // dispatch(hideFullScreenLoader())
            } else {
                alert("Order Details Not Found");
                // dispatch(hideFullScreenLoader())
            }
        } catch (err) {
            console.log(err);
            // dispatch(hideFullScreenLoader())
        }
    };

export const createPurchaseOrder = async (data) => {
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/po/createpo";

    let success = true;
    const errored = [];
    const created = [];
    const POID = [];

    for (let i = 0; i < data.length; i++) {
        console.log("data here", data, data.length, i);
        const poObject = data[i];
        console.log("poOject", poObject);
        const payload = {
            products: poObject.products,
            POID: "LB",
            client: "LBI",
        };
        try {
            console.log("payload", payload);
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify(payload),
            });
            const data = await response.json();
            console.log(data);
            if (data.statusCode === 200) {
                toast.success(
                    "PO created successfully for vendor " + poObject.vendor
                );
                created.push(poObject.vendor);
                POID.push({ vendor: data.body.vendor, POID: data.body.POID });
            } else {
                success = false;
                errored.push(poObject.vendor);
                alert("Error, cannot create PO");
                break;
            }
        } catch (err) {
            success = false;
            errored.push("Caught exception");
            alert("Error, cannot create PO");
            throw err;
        }
    }

    return { success, created, errored, POID };
};

export const getPendingPOProductsData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/status/active";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            const sales_data = data.body.reduce((acc, row) => {
                // sku wise
                const found = acc.find((ac) => ac.sku === row.sk);
                if (found) {
                    found.sizes = Object.keys(row.processedQuantity).reduce(
                        (pendingAcc, s) => {
                            pendingAcc[s] =
                                row.processedQuantity[s].pending +
                                found.sizes[s];
                            return pendingAcc;
                        },
                        {}
                    );
                    return [...acc.filter((ac) => ac.sku !== row.sk), found];
                } else {
                    const newObj = {
                        name: row.name,
                        sku: row.sk,
                        sizes: Object.keys(row.processedQuantity).reduce(
                            (pendingAcc, s) => {
                                pendingAcc[s] =
                                    row.processedQuantity[s].pending;
                                return pendingAcc;
                            },
                            {}
                        ),
                    };
                    acc.push(newObj);
                    return acc;
                }
            }, []);

            dispatch({
                type: "PENDING_PO_PRODUCTS_DATA",
                payload: {
                    sales_data,
                    updated_at: new Date().toLocaleDateString(),
                },
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const putPendingPOProductsData = (payload) => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/updatesinglerecord";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch(getPendingPOProductsData());
            toast.success(`Updated Successfully`);
        } else {
            toast.error(`Error with Status Code ${data.statusCode}`, {
                theme: "colored",
            });
        }

        dispatch(hideLoading());
    } catch (err) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getAllPOByStatus = (status) => async (dispatch) => {
    const URL = `https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/status/${status}`;
    dispatch(showLoading());
    let payload = {
        status,
        LastEvaluatedKey: null,
    };
    let fetching = true;
    let allPO = [];
    try {
        while (fetching) {
            const response = await fetch(URL, {
                method: "GET",
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();

            dispatch(hideLoading());
            if (data.statusCode === 200) {
                allPO.push(...data.body);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }
                toast.success("PO's fetched successfully");
            } else {
                fetching = false;
                allPO = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }
        dispatch({
            type: "SHOW_PO",
            payload: allPO,
        });
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getStockOutwardList = (status) => async (dispatch) => {
    const URL = `https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/status/stockoutwardactive`;
    dispatch(showLoading());
    let payload = {
        status,
        LastEvaluatedKey: null,
    };
    let fetching = true;
    let stockOutwardList = [];
    try {
        while (fetching) {
            const response = await fetch(URL, {
                method: "GET",
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            console.log("data", data);
            dispatch(hideLoading());
            if (data.statusCode === 200) {
                stockOutwardList.push(...data.body);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }
                toast.success("Stock outward list fetched successfully");
            } else {
                fetching = false;
                stockOutwardList = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }
        dispatch({
            type: "SHOW_STOCK_OUTWARD",
            payload: stockOutwardList,
        });
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const recievestock = async (pk, sk, quantity) => {
    let res;
    try {
        console.log("pk", pk, sk);
        const response = await fetch(
            `https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/stockoutward`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    pk,
                    sk,
                }),
            }
        );
        const data = await response.json();

        if (data.statusCode === 200) {
            toast.success("Status updated successfully");
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                status: 500,
            };
            toast.error("Status not updated");
            console.log("error", data);
        }
    } catch (err) {
        res = { status: 500 };
        toast.error("Status not updated");
        console.log("err", err);
    }

    return res;
};

export const changeStockOutwardList =
    (pk, sk, package_status, received_at) => async (dispatch, getState) => {
        const state = getState();
        const { stockOutwardList } = state.PO;
        const newStockOutwardList = stockOutwardList.map((p) => {
            if (p.pk === pk && p.sk === sk) {
                p["package_status"] = package_status;
                p["received_at"] = received_at;
            }
            return p;
        });
        dispatch({
            type: "SHOW_STOCK_OUTWARD",
            payload: newStockOutwardList,
        });
    };

export const changeProcessedQuantity = async (POID, sku, qnty) => {
    let res;
    try {
        console.log("payload", {
            pk: POID,
            sk: sku,
            newProcessedQuantity: qnty,
        });
        const response = await fetch(
            `https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/changeprocessingquantity`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    pk: POID,
                    sk: sku,
                    newProcessedQuantity: qnty,
                }),
            }
        );
        const data = await response.json();

        if (data.statusCode === 200) {
            res = {
                status: 200,
            };
        } else {
            res = {
                status: 500,
            };
            console.log("error", data);
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const changePOList = (pk, sk, qnty) => async (dispatch, getState) => {
    const state = getState();
    const { POList } = state.PO;
    const newPOList = POList.map((p) => {
        if (p.pk === pk && p.sk === sk) {
            p.processedQuantity = qnty;
        }
        return p;
    });
    dispatch({
        type: "SHOW_PO",
        payload: newPOList,
    });
};

export const getPOexcelFileList = async () => {
    let res;
    try {
        const response = await fetch(
            `https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/uploadtos3`,
            {
                method: "GET",
            }
        );
        const data = await response.json();

        if (data.statusCode === 200) {
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                status: 500,
            };
            console.log("error", data);
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const downloadPOexcelFile = async (key) => {
    let res;
    try {
        const response = await fetch(
            `https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/uploadtos3/download`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    key,
                }),
            }
        );
        console.log("response", response.status);
        const data = await response.json();
        console.log("data", data);
        if (data.statusCode === 200) {
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                status: 500,
            };
            console.log("error", data);
        }
    } catch (err) {
        res = { status: 500 };

        console.log("err", err);
    }

    return res;
};

export const getProcessedQuantity = () => async (dispatch) => {
    const URL = `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/po/getprocessedqnty`;
    dispatch(showLoading());
    let payload = {
        LastEvaluatedKey: null,
    };
    let fetching = true;
    let allPO = [];
    try {
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify(payload),
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();

            dispatch(hideLoading());
            if (data.statusCode === 200) {
                console.log("data processed", data);
                allPO.push(...data.body);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }
                toast.success("Processed Qnty Fetched successfully");
            } else {
                console.log("data processed qnty", data);
                fetching = false;
                allPO = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }
        dispatch({
            type: "SHOW_PROCESSED_QNTY",
            payload: allPO,
        });
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const updateLittleBoxProcessedQnty = (payload) => async (dispatch) => {
    console.log("here");
    const URL = `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/po/changeprocessedqnty`;

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            console.log("updated", data);
            toast.success("Updated Successfully");
            dispatch({
                type: "UPDATE_PROCESSED_QNTY",
                payload: data.body,
            });
        } else {
            toast.error("Update Not Successful");
        }
    } catch (err) {
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const editPONote = (payload) => async (dispatch) => {
    console.log("here");
    const URL = `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/po/editnote`;

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            console.log("updated", data);
            toast.success("Note added");
            dispatch({
                type: "UPDATE_PROCESSED_QNTY",
                payload: data.body,
            });
        } else {
            toast.error("Update Not Successful");
        }
    } catch (err) {
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const editProcessedQntyNote = async (payload) => {
    console.log("here");
    const URL = `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/po/editnote`;

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            console.log("updated", data);
            toast.success("Note added");
            return { statusCode: 200 };
        } else {
            toast.error("Update Not Successful");
            return { statusCode: 500 };
        }
    } catch (err) {
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
        return { statusCode: 500 };
    }
};

export const createProcessedHistory = async (history) => {
    const URL = `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/create_item`;

    const payload = {
        company: `Processed_History#${history.sku}`,
        fulfillment_id: new Date().getTime(),
        ...history,
    };
    console.log(payload);
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({ payload }),
        });
        const data = await response.json();
        console.log(data);
        if (data.statusCode === 200) {
            toast.success("History Created");
            return {
                statusCode: 200,
            };
        } else {
            toast.error("History Not Created");
            return {
                statusCode: 500,
            };
        }
    } catch (err) {
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
        return {
            statusCode: 500,
        };
    }
};
export const getProcessedHistory = async (sku) => {
    let res;
    try {
        const response = await lambdaFetch(
            `https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/po/get_processed_history`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    sku,
                }),
            }
        );
        const data = await response.json();

        if (data.statusCode === 200) {
            res = {
                status: 200,
                body: data.body,
            };
        } else {
            res = {
                status: 500,
            };
        }
    } catch (err) {
        res = { status: 500 };
    }

    return res;
};

//function below used in Inventory Snapshot
export const getPoSnapshotByDate = async (selected_date, mode) => {
    try {
        //   const currentDate = new Date();
        //   const selected_date = currentDate.toISOString().slice(0, 10);

        let fetching = true;
        let PoStock = [];
        let LastEvaluatedKey = null;

        while (fetching) {
            const response = await lambdaFetch(
                "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventorySnapshot/getPoStockHistory",
                {
                    method: "POST",
                    body: JSON.stringify({
                        type: mode,
                        date: selected_date,
                        LastEvaluatedKey,
                    }),
                }
            );
            const data = await response.json();
            if (data.statusCode === 200) {
                LastEvaluatedKey = data.LastEvaluatedKey;

                if (!LastEvaluatedKey) {
                    fetching = false;
                }
                PoStock = [...PoStock, ...data.body];
            } else {
                toast.error("Failed to fetch PO details for today.");
                return {
                    statusCode: 500,
                };
            }
        }
        return {
            statusCode: 200,
            body: PoStock,
        };
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong while fetching current PO details.");
        return {
            statusCode: 500,
        };
    }
};

//function below used in Inventory Snapshot
export const getStockSnapshotByDate = async (selected_date) => {
    try {
        //   const currentDate = new Date();
        //   const selected_date = currentDate.toISOString().slice(0, 10);

        let fetching = true;
        let PoStock = [];
        let LastEvaluatedKey = null;

        while (fetching) {
            const response = await lambdaFetch(
                "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventorySnapshot/getPoStockHistory",
                {
                    method: "POST",
                    body: JSON.stringify({
                        type: "stock",
                        date: selected_date,
                        LastEvaluatedKey,
                    }),
                }
            );
            const data = await response.json();
            if (data.statusCode === 200) {
                LastEvaluatedKey = data.LastEvaluatedKey;

                if (!LastEvaluatedKey) {
                    fetching = false;
                }
                PoStock = [...PoStock, ...data.body];
            } else {
                toast.error("Failed to fetch PO details for today.");
                return {
                    statusCode: 500,
                };
            }
        }
        return {
            statusCode: 200,
            body: PoStock,
        };
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong while fetching current PO details.");
        return {
            statusCode: 500,
        };
    }
};

export const createTodaysPoHistory = async (incomingPoStock) => {
    try {
        //   const currentDate = new Date();
        //   const selected_date = currentDate.toISOString().slice(0, 10);

        const response = await lambdaFetch(
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventorySnapshot/createPoStockHistory",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    type: "po",
                    products: incomingPoStock,
                }),
            }
        );
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Successfully updated po stock history.");
            return { statusCode: 200 };
        } else {
            toast.error("Failed to update po stock history");
            return {
                statusCode: 500,
            };
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong while updating PO details.");
        return {
            statusCode: 500,
        };
    }
};

export const createTodaysStockHistory = async (incomingPoStock) => {
    try {
        //   const currentDate = new Date();
        //   const selected_date = currentDate.toISOString().slice(0, 10);

        const response = await lambdaFetch(
            "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/inventorySnapshot/createPoStockHistory",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    type: "stock",
                    products: incomingPoStock,
                }),
            }
        );
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Successfully updated stock history.");
            return { statusCode: 200 };
        } else {
            toast.error("Failed to update stock history");
            return {
                statusCode: 500,
            };
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong while updating PO details.");
        return {
            statusCode: 500,
        };
    }
};
