import { Paper, Skeleton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { fabricVendors } from "../../utils/helperArrays";
import ReceiveFabricForm from "./ReceiveFabricForm";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialFabricList } from "../../actions/materialActions";

const ReceiveFabric = () => {
  const { materialFabricList } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (materialFabricList.length === 0) dispatch(getMaterialFabricList());
    // if (materialColorList.length === 0) dispatch(getMaterialColorList());
  }, []);
  return (
    <Paper
      sx={{
        p: 4,
        minHeight: "600px",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Receive Fabric</h2>
      {materialFabricList.length === 0 ? (
        <Stack spacing={1} my={3}>
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

          <Skeleton variant="rectangular" width={500} height={60} />
          <Skeleton variant="rounded" width={500} height={60} />
        </Stack>
      ) : (
        <ReceiveFabricForm
          vendorList={fabricVendors}
          fabrics={materialFabricList}
        />
      )}
    </Paper>
  );
};

export default ReceiveFabric;
