import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allInventory } from "../actions/inventoryActions";
import { Box, Skeleton } from "@mui/material";
import { useState } from "react";

const checkAllDimensionsPresent = (product) => {
  if (
    "length" in product &&
    "breadth" in product &&
    "height" in product &&
    product.length > 0 &&
    product.height > 0 &&
    product.breadth > 0 &&
    product.XLDeadWeight &&
    product.XSDeadWeight
  ) {
    return true;
  } else {
    return false;
  }
};

const MessageBoard = () => {
  const [stockPresentnonDimensionsCount, setStockPresentnonDimensionsCount] =
    useState(0);
  const auth = useSelector((state) => state.auth);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inventory.length > 0) {
      const productsWithDimensions = inventory.reduce((acc, p) => {
        const found = acc.find((ac) => ac.SKU === p.SKU);
        if (found) {
          found.count = found.count + p.count;
          return acc;
        } else {
          if (checkAllDimensionsPresent(p)) {
            const newObj = {
              SKU: p.SKU,
              count: p.count,
              length: p.length,
              breadth: p.breadth,
              height: p.height,
              XSDeadWeight: p.XSDeadWeight,
              XLDeadWeight: p.XLDeadWeight,
            };
            acc.push(newObj);
          } else {
            const newObj = {
              SKU: p.SKU,
              count: p.count,
            };
            acc.push(newObj);
          }
        }
        return acc;
      }, []);
      const dimensionsAbsentSkus = productsWithDimensions.filter(
        (p) => !("length" in p)
      );
      const dimensionsAbsentSkusWithStock = dimensionsAbsentSkus.filter(
        (td) => "count" in td && td.count > 0
      ).length;
      setStockPresentnonDimensionsCount(dimensionsAbsentSkusWithStock);
    }
  }, [inventory]);

  useEffect(() => {
    if (!inventory.length > 0 && auth) dispatch(allInventory());
  }, [auth]);

  return (
    <div>
      {inventory.length === 0 && (
        <Skeleton variant="rectangular" width={"100%"} height={40} />
      )}
      {/* for inventory */}
      {stockPresentnonDimensionsCount > 0 && (
        <Box
          sx={{
            width: "100%",
            bgcolor: "lightgreen",
            textAlign: "center",
            py: 1,
          }}
        >
          <h4>
            Warning: SKU's has stock but dimensions not entered -{" "}
            {stockPresentnonDimensionsCount}
          </h4>
        </Box>
      )}
    </div>
  );
};

export default MessageBoard;
