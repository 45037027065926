import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

import {
  getOrdersInS3ByDate,
  getShiprocketOrdersByProcessedAt,
} from "../../actions/ordersActions";

import ShiprocketOrderTable from "../shiprocket/ShiprocketOrderTable";
import OrderReport from "../shiprocket/OrderReport";
import Filter from "../shiprocket/Filter";

import {
  createPDFforManifest,
  createPDFforManifestForShiprocket,
} from "../../utils/pdfUtils";
import { PageSkeleton } from "../dashboard/helperComponents";
import { createXLforDelhivery } from "../../utils/excelUtils";

function splitArray(arr, numberOfArrays) {
  if (numberOfArrays < 1) {
    throw new Error("Number of arrays should be greater than or equal to 1");
  }

  const chunkSize = Math.ceil(arr.length / numberOfArrays);
  const result = [];

  for (let i = 0; i < numberOfArrays; i++) {
    const start = i * chunkSize;
    const end = start + chunkSize;
    const chunk = arr.slice(start, end);
    result.push(chunk);
  }

  return result;
}

const DuplicateOrders = () => {
  const todayDate = new Date().toISOString().split("T")[0];
  // 2024-11-07
  const [date, setDate] = useState(todayDate);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isNoOrders, setIsNoOrders] = useState(false);
  const [errorList, setErrorList] = useState([]);
  // filter values
  const [statuses, setStatuses] = useState([]);
  const [comments, setComments] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [dispatchTimes, setDispatchTimes] = useState([]);
  //   select values
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedComment, setSelectedComment] = useState("All");
  const [selectedCourier, setSelectedCourier] = useState("All");
  const [selectedProcessed, setSelectedProcessed] = useState("All");
  const [selectedPayment, setSelectedPayment] = useState("All");
  const [selectedDispatchTime, setSelectedDispatchTime] = useState("All");
  const [numberOfParts, setNumberOfParts] = useState(0);
  const [excelData, setExcelData] = useState([]);

  const auth = useSelector((state) => state.auth);

  //   use effects
  useEffect(() => {
    if (excelData.length > 0) {
      // csvLink.current.link.click();
      excelData.forEach((e, i) => {
        const csvLink = document.getElementById(`csv-link-${i}`);
        if (csvLink) {
          csvLink.click();
        }
      });
    }
  }, [excelData]);
  //
  const handleSearch = async () => {
    setIsNoOrders(false);
    setLoading(true);
    const ordersInS3 = await getOrdersInS3ByDate(date);
    if (ordersInS3.statusCode === 200) {
      if (ordersInS3.body.length > 0) {
      } else {
        setOrders([]);
        setIsNoOrders(true);
      }
    }
    setLoading(false);
  };
  const handleChangeParts = (e) => {
    const val = parseInt(e.target.value);
    if (isNaN(val)) {
      setNumberOfParts(0);
    } else {
      setNumberOfParts(val);
    }
  };
  const handleDownload = () => {
    if (selectedCourier === "All") {
      toast.error("Select a courier");
    } else {
      // split first
      const splitPayload = splitArray(tableData, numberOfParts);
      let excelArr = [];
      for (let i = 0; i < splitPayload.length; i++) {
        const dataSplit = splitPayload[i].map((o) => ({
          ...o,
          fulfillment_id: o.fulfillment_id.toString(),
        }));
        //   create manifest
        createPDFforManifestForShiprocket(dataSplit, selectedCourier);
        //   create excel
        const xbData = createXLforDelhivery(dataSplit); // This just console logs the JSON. We need to make csv and download.
        excelArr.push(xbData);
      }
      setExcelData(excelArr);
    }
  };

  return (
    <div>
      {auth && (
        <>
          <div>
            <h3>Shiprocket Bulk Orders</h3>
            <Stack direction={"row"} gap={2} my={2}>
              <TextField
                type="date"
                size="medium"
                variant="standard"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                label="processed date"
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={loading}
              >
                Search
              </Button>
            </Stack>
            {loading ? (
              <>
                <PageSkeleton />
              </>
            ) : (
              <>
                {isNoOrders && <h3>No orders found</h3>}
                {orders.length > 0 && (
                  <Box>
                    <Box component={Paper} my={1} py={2} px={2}>
                      <Stack
                        direction={"row"}
                        gap={2}
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          type="text"
                          value={numberOfParts}
                          onChange={handleChangeParts}
                          variant="outlined"
                          label="Parts"
                          size="small"
                        />
                        <Button variant="outlined" onClick={handleDownload}>
                          Download
                        </Button>
                        {errorList.length > 0 && (
                          <CSVLink data={errorList} filename={`Error_List.csv`}>
                            <Button variant="outlined" color="error">
                              Error List
                            </Button>
                          </CSVLink>
                        )}
                      </Stack>
                    </Box>
                    {tableData.length > 0 ? (
                      <ShiprocketOrderTable
                        tableData={tableData}
                        orderTakeoutDate={date}
                      />
                    ) : (
                      <h3>Data Not Found</h3>
                    )}
                  </Box>
                )}
              </>
            )}
          </div>
          {/* excel download */}
          {excelData.map((ed, i) => (
            <CSVLink
              key={i}
              data={ed}
              id={`csv-link-${i}`}
              // ref={csvLink}
              // target="_blank"
              filename={`${selectedCourier} ${i}`}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default DuplicateOrders;
