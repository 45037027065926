import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allInventory } from "../actions/inventoryActions";
import { Backdrop, Box, CircularProgress } from "@mui/material";

import { SkeletonComponent } from "./dashboard/helperComponents";
import ClothPC from "./dashboard/ClothPC";
import FootwearPC from "./dashboard/FootwearPC";
import RtoLineChart from "./dashboard/RtoLineChart";
import MonthlyPC from "./dashboard/MonthlyPC";
import ReverseLineChart from "./dashboard/ReverseLineChart";

import {
    getForwardDeliveredData,
    getForwardInTransitData,
    getForwardNdrData,
    getMonthlyRTOData,
    getMonthlyRtoDeliveredData,
    getMonthlyRtoIntransitData,
    getRtoData,
    getRtoDeliveredData,
    getRtoInTansitData,
} from "../actions/xbActions";
import {
    getMonthlyReverseDeliveredData,
    getMonthlyReversePendingData,
    getMonthlyReversePickedupData,
} from "../actions/reverseDeliveryActions";
import {
    createPDFForSkuWiseOrderOut,
    createPDFforManifest,
} from "../utils/pdfUtils";
import { pdfData } from "../utils/pdfdata";
import {
    fulfillBulkInShopify,
    underProcessOrders,
} from "../actions/ordersActions";
import AutomationStatus from "./AutomationStatus";
import { getOrderAmountAnalytics } from "../utils/helpers";
import TodayOrderAnalytics from "./dashboard/TodayOrderAnalytics";
import Order45DaysAnalytics from "./dashboard/Order45DaysAnalytics";
import InventoryValue from "./InventoryValue";
import MessageBoard from "./MessageBoard";
// import LiveOrdersCount from "./dashboard/LiveOrdersCount";
import InScanAnalytics from "./reverseRecon/InScanAnalytics";

const Dashboard = () => {
    const dispatch = useDispatch();
    const { stock } = useSelector((state) => state.inventoryDetails);
    const { loading } = useSelector((state) => state.loader);
    const {
        rto_orders,
        rto_intransit,
        rto_delivered,
        forward_delivered,
        forward_intransit,
        forward_ndr,
        monthly_rto,
    } = useSelector((state) => state.xbDetails);
    // console.log(forward_delivered)
    const {
        reverse_pickedup_monthly,
        reverse_notpicked_monthly,
        reverse_delivered_monthly,
    } = useSelector((state) => state.reverseDetails);
    const [rtoChartData, setRTOChartData] = useState([]);
    const [reverseChartData, setReverseChartData] = useState([]);

    // function splitArray(arr, numberOfArrays) {
    //   if (numberOfArrays < 1) {
    //     throw new Error("Number of arrays should be greater than or equal to 1");
    //   }

    //   const chunkSize = Math.ceil(arr.length / numberOfArrays);
    //   const result = [];

    //   for (let i = 0; i < numberOfArrays; i++) {
    //     const start = i * chunkSize;
    //     const end = start + chunkSize;
    //     const chunk = arr.slice(start, end);
    //     result.push(chunk);
    //   }

    //   return result;
    // }
    // create manifest
    // useEffect(() => {
    //   const splitPayload = splitArray(pdfData, 1);

    //   for (let i = 0; i < splitPayload.length; i++) {
    //     createPDFforManifest(splitPayload[i]);
    //     // dispatch(underProcessOrders(splitPayload[i]));
    //     // dispatch(fulfillBulkInShopify(splitPayload[i]));
    //   }
    // }, []);
    // useEffect(() => {
    //   if (stock.length > 0) {
    //     console.log("stock", stock);
    //     const inv = stock.reduce((acc, s) => {
    //       const found = acc.find((ac) => ac.SKU === s.SKU);
    //       if (!found) {
    //         const newProduct = {
    //           ...s,
    //         };
    //         acc.push(newProduct);
    //       }
    //       return acc;
    //     }, []);
    //     console.log("inv", inv);
    //   }
    // }, [stock]);
    useEffect(() => {
        const todayDate = new Date();
        const getDateRange = (date) => {
            date.setUTCHours(0, 0, 0, 0);
            return `${date.toISOString().split("T")[0]}T00:00:00+05:30`;
        };

        const requiredRTODate = getDateRange(
            new Date(new Date().setDate(new Date().getDate() - 15))
        );
        const requiredReverseDate = getDateRange(
            new Date(new Date().setDate(new Date().getDate() - 30))
        );

        const changeDateFormat = (date) => {
            const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
            const reqMonth =
                (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
            const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
                "-"
            )}T00:00:00+05:30`;
            return reqFormat;
        };
        const firstDayLastMonth = changeDateFormat(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1)
        );
        // const lastDayLastMonth = changeDateFormat(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0))
        // const firstDayPresentMonth = changeDateFormat(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1))
        const lastDayPresentMonth = changeDateFormat(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0)
        );

        if (forward_delivered.length === 0)
            dispatch(
                getForwardDeliveredData({
                    package_status: "Delivered",
                    from_date: firstDayLastMonth,
                    to_date: lastDayPresentMonth,
                })
            );
        if (forward_intransit.length === 0)
            dispatch(
                getForwardInTransitData({
                    package_status: "sync#IT",
                    from_date: firstDayLastMonth,
                    to_date: lastDayPresentMonth,
                })
            );
        if (forward_ndr.length === 0)
            dispatch(
                getForwardNdrData({
                    package_status: "sync#UD",
                    from_date: firstDayLastMonth,
                    to_date: lastDayPresentMonth,
                })
            );
        if (monthly_rto.length === 0) {
            dispatch(
                getMonthlyRTOData({
                    package_status: "sync#RTO",
                    from_date: firstDayLastMonth,
                    to_date: lastDayPresentMonth,
                })
            );
            dispatch(
                getMonthlyRtoIntransitData({
                    package_status: "sync#RTO-IT",
                    from_date: firstDayLastMonth,
                    to_date: lastDayPresentMonth,
                })
            );
            dispatch(
                getMonthlyRtoDeliveredData({
                    package_status: "RTO",
                    from_date: firstDayLastMonth,
                    to_date: lastDayPresentMonth,
                })
            );
        }

        if (stock.length === 0) dispatch(allInventory());

        if (rto_orders.length === 0 || rto_intransit.length === 0) {
            dispatch(
                getRtoData({
                    package_status: "sync#RTO",
                    from_date: requiredRTODate,
                })
            );
            dispatch(
                getRtoInTansitData({
                    package_status: "sync#RTO-IT",
                    from_date: requiredRTODate,
                })
            );
        }
        if (rto_delivered.length === 0)
            dispatch(
                getRtoDeliveredData({
                    package_status: "RTO",
                    from_date: requiredRTODate,
                })
            );

        if (reverse_pickedup_monthly.length === 0)
            dispatch(
                getMonthlyReversePickedupData({
                    from_date: requiredReverseDate,
                })
            );
        if (reverse_notpicked_monthly.length === 0)
            dispatch(
                getMonthlyReversePendingData({ from_date: requiredReverseDate })
            );
        if (reverse_delivered_monthly.length === 0)
            dispatch(
                getMonthlyReverseDeliveredData({
                    from_date: requiredReverseDate,
                })
            );
    }, []);

    const footwearPieChart = useMemo(() => {
        const footwear = stock.filter(
            (x) => x.type === "Shoes" || x.type === "Men Shoes"
        );
        return footwear;
    }, [stock]);

    const clothPieChart = useMemo(() => {
        const cloth = stock.filter(
            (x) =>
                x.type === "Clothing" ||
                x.type === "Men's Clothing" ||
                x.type === "Pants" ||
                x.type === "Lingerie and Athleisure"
        );
        return cloth;
    }, [stock]);

    const rtoIntransitData = useMemo(() => {
        const reducedDates = [...rto_orders, ...rto_intransit].reduce(
            (acc, item) => {
                const row = acc.find(
                    (p) => p.rto_declared_on === item.rto_declared_on
                );
                if (row) {
                    row.total = row.total + 1;
                } else {
                    const newProduct = {
                        rto_declared_on: item.rto_declared_on,
                        total: 1,
                    };
                    acc.push(newProduct);
                }
                return acc;
            },
            []
        );

        const generateDates = Array.from({ length: 15 }).map((d, index) => {
            const date = new Date(
                new Date().setDate(new Date().getDate() - 15)
            );
            date.setDate(index + new Date(date).getDate());
            date.setUTCHours(0, 0, 0, 0);
            return {
                rto_declared_on: date.toISOString(),
                total: 0,
            };
        });
        const filledUpRTO_IT_Dates = generateDates.map((p) => {
            const row = reducedDates.find(
                (q) =>
                    q.rto_declared_on?.split("T")[0] ===
                    p.rto_declared_on?.split("T")[0]
            );
            if (row) {
                return { rto_declared_on: p.rto_declared_on, total: row.total };
            } else {
                return p;
            }
        });
        return filledUpRTO_IT_Dates
            .map((x) => ({
                rto_declared_on: new Date(x.rto_declared_on),
                total: x.total,
            }))
            .sort(
                (a, b) =>
                    new Date(a.rto_declared_on) - new Date(b.rto_declared_on)
            );
    }, [rto_orders, rto_intransit]);

    const rtoDeliveredData = useMemo(() => {
        const reducedDates = rto_delivered.reduce((acc, item) => {
            const row = acc.find(
                (p) => p.rto_declared_on === item.rto_declared_on
            );
            if (row) {
                row.total = row.total + 1;
            } else {
                const newProduct = {
                    rto_declared_on: item.rto_declared_on,
                    total: 1,
                };
                acc.push(newProduct);
            }
            return acc;
        }, []);

        const generateDates = Array.from({ length: 15 }).map((d, index) => {
            const date = new Date(
                new Date().setDate(new Date().getDate() - 15)
            );
            date.setDate(index + new Date(date).getDate());
            date.setUTCHours(0, 0, 0, 0);
            return {
                rto_declared_on: date.toISOString(),
                total: 0,
            };
        });
        const filledUpRTO_D_Dates = generateDates.map((p) => {
            const row = reducedDates.find((q) => {
                // if (!q.rto_declared_on) {
                //   console.log("rto ", q);
                // }
                return (
                    q.rto_declared_on &&
                    q.rto_declared_on.split("T")[0] ===
                        p.rto_declared_on.split("T")[0]
                );
            });
            if (row) {
                return { rto_declared_on: p.rto_declared_on, total: row.total };
            } else {
                return p;
            }
        });
        return filledUpRTO_D_Dates
            .map((x) => ({
                rto_declared_on: new Date(x.rto_declared_on),
                total: x.total,
            }))
            .sort(
                (a, b) =>
                    new Date(a.rto_declared_on) - new Date(b.rto_declared_on)
            );
    }, [rto_delivered]);

    const forwardDeliveredData = useMemo(() => {
        const prevMonth =
            new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;
        const pastMonthDates = forward_delivered.filter(
            (p) => new Date(p.sync_date).getMonth() === prevMonth
        ).length;
        const presentMonthDates = forward_delivered.filter(
            (p) => new Date(p.sync_date).getMonth() === new Date().getMonth()
        ).length;
        return { pastMonthDates, presentMonthDates };
    }, [forward_delivered]);

    const forwardInTransitData = useMemo(() => {
        console.log("forward intransit", forward_intransit.length);
        const prevMonth =
            new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;
        const pastMonthDates = forward_intransit.filter(
            (p) => new Date(p.sync_date).getMonth() === prevMonth
        ).length;
        const presentMonthDates = forward_intransit.filter(
            (p) => new Date(p.sync_date).getMonth() === new Date().getMonth()
        ).length;
        return { pastMonthDates, presentMonthDates };
    }, [forward_intransit]);

    const forwardNdrData = useMemo(() => {
        const prevMonth =
            new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;
        const pastMonthDates = forward_ndr.filter(
            (p) => new Date(p.sync_date).getMonth() === prevMonth
        ).length;
        const presentMonthDates = forward_ndr.filter(
            (p) => new Date(p.sync_date).getMonth() === new Date().getMonth()
        ).length;
        return { pastMonthDates, presentMonthDates };
    }, [forward_ndr]);

    const rtoMonthlyData = useMemo(() => {
        const prevMonth =
            new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;
        const pastMonthDates = monthly_rto.filter(
            (p) => new Date(p.sync_date).getMonth() === prevMonth
        ).length;
        const presentMonthDates = monthly_rto.filter(
            (p) => new Date(p.sync_date).getMonth() === new Date().getMonth()
        ).length;
        return { pastMonthDates, presentMonthDates };
    }, [monthly_rto]);

    const rtoLineChartData = useMemo(() => {
        const data = [
            {
                color: "rgb(0,0,0)",
                key: "RTO InTransit",
                values: rtoIntransitData,
            },
            {
                color: "rgb(255,0,0)",
                key: "RTO Delivered",
                values: rtoDeliveredData,
            },
        ];
        setTimeout(() => setRTOChartData(data), 2000);
    }, [rtoIntransitData, rtoDeliveredData]);

    const reversePickeupData = useMemo(() => {
        const reducedDates = reverse_pickedup_monthly.reduce((acc, item) => {
            const row = acc.find((p) => p.sync_date === item.sync_date);
            if (row) {
                row.total = row.total + 1;
            } else {
                const newProduct = {
                    sync_date: item.sync_date,
                    total: 1,
                };
                acc.push(newProduct);
            }
            return acc;
        }, []);

        const generateDates = Array.from({ length: 30 }).map((d, index) => {
            const date = new Date(
                new Date().setDate(new Date().getDate() - 30)
            );
            date.setDate(index + new Date(date).getDate());
            date.setUTCHours(0, 0, 0, 0);
            return {
                sync_date: date.toISOString(),
                total: 0,
            };
        });
        const filledUp_RPickup_Dates = generateDates.map((p) => {
            const row = reducedDates.find(
                (q) => q.sync_date.split("T")[0] === p.sync_date.split("T")[0]
            );
            if (row) {
                return { sync_date: p.sync_date, total: row.total };
            } else {
                return p;
            }
        });
        return filledUp_RPickup_Dates
            .map((x) => ({ sync_date: new Date(x.sync_date), total: x.total }))
            .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date));
    }, [reverse_pickedup_monthly]);

    const reverseNotPickeupData = useMemo(() => {
        const reducedDates = reverse_notpicked_monthly.reduce((acc, item) => {
            const row = acc.find((p) => p.sync_date === item.sync_date);
            if (row) {
                row.total = row.total + 1;
            } else {
                const newProduct = {
                    sync_date: item.sync_date,
                    total: 1,
                };
                acc.push(newProduct);
            }
            return acc;
        }, []);

        const generateDates = Array.from({ length: 30 }).map((d, index) => {
            const date = new Date(
                new Date().setDate(new Date().getDate() - 30)
            );
            date.setDate(index + new Date(date).getDate());
            date.setUTCHours(0, 0, 0, 0);
            return {
                sync_date: date.toISOString(),
                total: 0,
            };
        });
        const filledUp_NRPickup_Dates = generateDates.map((p) => {
            const row = reducedDates.find(
                (q) => q.sync_date.split("T")[0] === p.sync_date.split("T")[0]
            );
            if (row) {
                return { sync_date: p.sync_date, total: row.total };
            } else {
                return p;
            }
        });
        return filledUp_NRPickup_Dates
            .map((x) => ({ sync_date: new Date(x.sync_date), total: x.total }))
            .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date));
    }, [reverse_notpicked_monthly]);

    const reverseDeliveredData = useMemo(() => {
        const reducedDates = reverse_delivered_monthly.reduce((acc, item) => {
            const row = acc.find((p) => p.sync_date === item.sync_date);
            if (row) {
                row.total = row.total + 1;
            } else {
                const newProduct = {
                    sync_date: item.sync_date,
                    total: 1,
                };
                acc.push(newProduct);
            }
            return acc;
        }, []);

        const generateDates = Array.from({ length: 30 }).map((d, index) => {
            const date = new Date(
                new Date().setDate(new Date().getDate() - 30)
            );
            date.setDate(index + new Date(date).getDate());
            date.setUTCHours(0, 0, 0, 0);
            return {
                sync_date: date.toISOString(),
                total: 0,
            };
        });
        const filledUp_NRPickup_Dates = generateDates.map((p) => {
            const row = reducedDates.find(
                (q) => q.sync_date.split("T")[0] === p.sync_date.split("T")[0]
            );
            if (row) {
                return { sync_date: p.sync_date, total: row.total };
            } else {
                return p;
            }
        });
        return filledUp_NRPickup_Dates
            .map((x) => ({ sync_date: new Date(x.sync_date), total: x.total }))
            .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date));
    }, [reverse_delivered_monthly]);

    const reverseLineChartData = useMemo(() => {
        const data = [
            {
                color: "rgb(0,0,0)",
                key: "Reverse PickedUp",
                values: reversePickeupData,
            },
            {
                color: "rgb(255,0,0)",
                key: "Reverse Not PickedUp",
                values: reverseNotPickeupData,
            },
            {
                color: "rgb(0, 255, 0)",
                key: "Reverse Delivered",
                values: reverseDeliveredData,
            },
        ];
        setTimeout(() => setReverseChartData(data), 2000);
    }, [reversePickeupData, reverseNotPickeupData, reverseDeliveredData]);

    return (
        <>
            {/* {loading && (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress size={100} color="inherit" />
      </Backdrop>
      )} */}
            {/* <AutomationStatus />
      <InventoryValue /> */}
            {/* <LiveOrdersCount /> */}
            <MessageBoard />
            <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
                <AutomationStatus />
                <InventoryValue />
            </div>
            <Order45DaysAnalytics />
            <TodayOrderAnalytics />
            <div style={{ display: "flex" }}>
                <>
                    {!loading ? (
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            <Box>
                                <FootwearPC stock={footwearPieChart} />
                            </Box>
                            <Box style={{ padding: "0 15px 0 15px" }}>
                                <ClothPC stock={clothPieChart} />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                "& div": {
                                    marginRight: "15px",
                                },
                            }}
                        >
                            <SkeletonComponent />
                            <SkeletonComponent />
                        </Box>
                    )}
                </>
                <br />
                <>
                    {!loading ? (
                        <Box>
                            <MonthlyPC
                                forwardDeliveredData={forwardDeliveredData}
                                forwardInTransitData={forwardInTransitData}
                                forwardNdrData={forwardNdrData}
                                rtoMonthlyData={rtoMonthlyData}
                            />
                        </Box>
                    ) : (
                        <SkeletonComponent />
                    )}
                </>
            </div>
            <br />
            <InScanAnalytics />
            <Box style={{ display: "flex" }}>
                <Box>
                    {!loading ? (
                        <Box>
                            <RtoLineChart rtoLineChartData={rtoChartData} />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                "& div": {
                                    marginRight: "15px",
                                },
                            }}
                        >
                            <SkeletonComponent />
                        </Box>
                    )}
                </Box>
                <Box>
                    {!loading ? (
                        <Box style={{ paddingLeft: "15px" }}>
                            <ReverseLineChart
                                reverseLineChartData={reverseChartData}
                            />
                        </Box>
                    ) : (
                        <SkeletonComponent />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default Dashboard;
