import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Chip,
  OutlinedInput,
  Switch,
  FormControlLabel,
  LinearProgress,
} from "@mui/material";
import { ordersByQuery } from "../../../actions/influencerActions";

const RoasData = ({
  orderNumbers,
  triggerShowRoas,
  setTriggerShowRoas,
  totals,
}) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showOnlySubtotal, setShowOnlySubtotal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrders = async () => {
    setIsLoading(true);
    setProgress(0);
    const fetchedOrders = [];

    for (let i = 0; i < orderNumbers.length; i += 10) {
      const batch = orderNumbers.slice(i, i + 10);
      const promises = batch.map(async (orderNumber) => {
        const searchQuery = `name:${orderNumber}`;
        const result = await ordersByQuery(searchQuery);

        return result
          .filter((o) => orderNumbers.includes(o.name))
          .map((o) => ({
            id: o.name,
            total: parseFloat(o.totalPriceSet.presentmentMoney.amount),
            shipping: parseFloat(
              o.totalShippingPriceSet.presentmentMoney.amount
            ),
          }));
      });

      const batchResults = await Promise.all(promises);
      batchResults.forEach((res) => fetchedOrders.push(...res));
      setProgress(((i + 10) / orderNumbers.length) * 100);
    }

    setOrders(fetchedOrders);
    setIsLoading(false);
  };

  useEffect(() => {
    if (triggerShowRoas) {
      setOrders([]);
      fetchOrders();
      setTriggerShowRoas(false);
    }
  }, [triggerShowRoas]);

  useEffect(() => {
    if (selectedOrders.length > 0) {
      setFilteredOrders(
        orders.filter((order) => selectedOrders.includes(order.id))
      );
    } else {
      setFilteredOrders(orders);
    }
  }, [selectedOrders, orders]);

  const totalAmount = filteredOrders.reduce(
    (acc, order) => acc + order.total,
    0
  );
  const totalShipping = filteredOrders.reduce(
    (acc, order) => acc + order.shipping,
    0
  );

  return (
    <Box mt={4}>
      {isLoading && (
        <Box mb={2}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}

      <Typography variant="h6" fontWeight={600} align="center" mb={2}>
        Influencer Orders
      </Typography>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <FormControl fullWidth>
          <InputLabel>Select Orders</InputLabel>
          <Select
            multiple
            value={selectedOrders}
            onChange={(e) => setSelectedOrders(e.target.value)}
            input={<OutlinedInput label="Select Orders" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((order) => (
                  <Chip key={order} label={order} />
                ))}
              </Box>
            )}
          >
            {orders.map((order) => (
              <MenuItem key={order.id} value={order.id}>
                {order.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Switch
              checked={showOnlySubtotal}
              onChange={() => setShowOnlySubtotal(!showOnlySubtotal)}
              color="primary"
            />
          }
          label="Show Only Subtotal"
        />
      </Box>
      {orders.length === 0 ? (
        <Typography align="center" sx={{ mt: 2, fontStyle: "italic" }}>
          No orders available. Please run the query.
        </Typography>
      ) : filteredOrders.length === 0 ? (
        <Typography align="center" sx={{ mt: 2, fontStyle: "italic" }}>
          No orders match the selected filters.
        </Typography>
      ) : showOnlySubtotal ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Total Price</b>
                </TableCell>
                <TableCell>
                  <b>Shipping</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>₹{totalAmount.toFixed(2)}</b>
                </TableCell>
                <TableCell>
                  <b>₹{totalShipping.toFixed(2)}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Order ID</b>
                </TableCell>
                <TableCell>
                  <b>Total Price</b>
                </TableCell>
                <TableCell>
                  <b>Shipping</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>₹{order.total.toFixed(2)}</TableCell>
                  <TableCell>₹{order.shipping.toFixed(2)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <b>Total</b>
                </TableCell>
                <TableCell>
                  <b>₹{totalAmount.toFixed(2)}</b>
                </TableCell>
                <TableCell>
                  <b>₹{totalShipping.toFixed(2)}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box mt={4} textAlign="center">
        <Typography variant="h6">
          ROAS ={" "}
          {"Total sale / ((40% of total amount spent) + (shipping cost))"}
        </Typography>
        <Typography variant="h6">
          {`${totals.totalAmount?.toFixed(2)} / ((40% of ${totalAmount.toFixed(
            2
          )}) + ${totalShipping.toFixed(2)}) = ${(
            totals.totalAmount /
            (0.4 * totalAmount + totalShipping)
          ).toFixed(2)}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default RoasData;
