import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  TableSortLabel,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const ActiveInfluencers = () => {
  const { influencers: allInfluencers, loading } = useSelector(
    (state) => state.influencerDetails
  );

  const influencers = allInfluencers.filter((inf) => inf.approved_at);

  const [search, setSearch] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "displayName",
    direction: "asc",
  });
  const [dateRange, setDateRange] = useState({ from: "", to: "" });

  if (loading)
    return <CircularProgress sx={{ display: "block", margin: "20px auto" }} />;
  if (!influencers || influencers.length === 0)
    return <Typography>No active influencer found.</Typography>;

  // Sorting function
  const sortedData = [...influencers].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key])
      return sortConfig.direction === "asc" ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key])
      return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  // Filtering function
  const filteredData = sortedData.filter((influencer) => {
    const lowerSearch = search.toLowerCase();
    const matchesSearch =
      influencer.displayName?.toLowerCase().includes(lowerSearch) ||
      influencer.defaultEmailAddress?.emailAddress
        ?.toLowerCase()
        .includes(lowerSearch) ||
      influencer.defaultPhoneNumber?.phoneNumber?.includes(lowerSearch) ||
      influencer.influencer_data?.social_media_platform
        ?.toLowerCase()
        .includes(lowerSearch) ||
      influencer.influencer_data?.account_handle
        ?.toLowerCase()
        .includes(lowerSearch) ||
      influencer.influencer_data?.state?.toLowerCase().includes(lowerSearch);

    // Date filtering
    const influencerDate = new Date(influencer.registered_at);
    const fromDate = dateRange.from ? new Date(dateRange.from) : null;
    const toDate = dateRange.to ? new Date(dateRange.to) : null;
    const matchesDateRange =
      (!fromDate || influencerDate >= fromDate) &&
      (!toDate || influencerDate <= toDate);

    return matchesSearch && matchesDateRange;
  });

  // Change sorting
  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  // Edit action
  const handleEdit = (id) => {
    console.log(`Edit influencer with ID: ${id}`);
    // Add your edit logic here (e.g., open a modal or navigate to an edit page)
  };

  return (
    <TableContainer component={Paper} sx={{ p: 2 }}>
      {/* Filters */}
      <TextField
        label="Search"
        size="small"
        sx={{ m: 1, width: "30%" }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search by name, email, phone, platform, handle, state"
      />
      <TextField
        label="From Date"
        type="date"
        size="small"
        sx={{ m: 1 }}
        InputLabelProps={{ shrink: true }}
        value={dateRange.from}
        onChange={(e) =>
          setDateRange((prev) => ({ ...prev, from: e.target.value }))
        }
      />
      <TextField
        label="To Date"
        type="date"
        size="small"
        sx={{ m: 1 }}
        InputLabelProps={{ shrink: true }}
        value={dateRange.to}
        onChange={(e) =>
          setDateRange((prev) => ({ ...prev, to: e.target.value }))
        }
      />

      {/* Table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === "displayName"}
                direction={sortConfig.direction}
                onClick={() => handleSort("displayName")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Platform</TableCell>
            <TableCell>Handle</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Audience</TableCell>
            <TableCell>Max Line Items</TableCell>
            <TableCell>Contents Count</TableCell>
            <TableCell>Affiliate Link</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === "registered_at"}
                direction={sortConfig.direction}
                onClick={() => handleSort("registered_at")}
              >
                Registered At
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((influencer) => (
            <TableRow key={influencer.id}>
              <TableCell>{influencer.displayName}</TableCell>
              <TableCell>
                {influencer.defaultEmailAddress?.emailAddress || "N/A"}
              </TableCell>
              <TableCell>
                {influencer.defaultPhoneNumber?.phoneNumber || "N/A"}
              </TableCell>
              <TableCell>
                {influencer.influencer_data?.social_media_platform || "N/A"}
              </TableCell>
              <TableCell>
                {influencer.influencer_data?.account_handle || "N/A"}
              </TableCell>
              <TableCell>
                {influencer.influencer_data?.state || "N/A"}
              </TableCell>
              <TableCell>
                {influencer.influencer_data?.audience_count || "N/A"}
              </TableCell>
              <TableCell>
                {influencer.max_lineitems
                  ? `${influencer.max_lineitems.max_count} items (RS. ${influencer.max_lineitems.max_cost})`
                  : "N/A"}
              </TableCell>
              <TableCell>
                {Object.entries(influencer.contents_count || {}).map(
                  ([key, value]) => (
                    <div key={key}>
                      {key}:{" "}
                      {typeof value === "object"
                        ? `${value.count} (${value.description})`
                        : value}
                    </div>
                  )
                )}
              </TableCell>
              <TableCell>
                {influencer.influencer_data?.affiliate_link ? (
                  <a
                    href={influencer.influencer_data.affiliate_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>{influencer.registered_at}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => handleEdit(influencer.id)}
                  color="inherit"
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "10px",
                  }}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActiveInfluencers;
