import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateMaterial, uploadImage } from "../../../actions/materialActions";

const UpdateMaterialForm = ({ material, allMaterials, setMaterials }) => {
  const [width, setWidth] = useState(0);
  const [fabricRate, setFabricRate] = useState(0);
  const [pFabricRate, setPFabricRate] = useState(0);
  const [GSM, setGSM] = useState(0);
  const [url, setUrl] = useState("");

  useEffect(() => {
    setWidth(material.width ?? 0);
    setFabricRate(material.fabric_rate ?? 0);
    setPFabricRate(material.p_fabric_rate ?? 0);
    setGSM(material.GSM ?? 0);
    setUrl(material.imageUrl ?? "");
  }, [material]);

  const changeWidth = (e) => {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
      setWidth(0);
    } else {
      setWidth(num);
    }
  };
  const changeFabricRate = (e) => {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
      setFabricRate(0);
    } else {
      setFabricRate(num);
    }
  };
  const changePFabricRate = (e) => {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
      setPFabricRate(0);
    } else {
      setPFabricRate(num);
    }
  };

  const changeGSM = (e) => {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
      setGSM(0);
    } else {
      setGSM(num);
    }
  };

  const handleUploadClick = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => {
        setUrl(reader.result);
        e.target.value = "";
      };
    }
  };

  const handleUpdate = async () => {
    if (material.imageUrl === url) {
      const payload = {
        company: material.company,
        barcode: material.barcode,
        width,
        fabricRate,
        pFabricRate,
        GSM,
        imageUrl: url,
      };
      const data = await updateMaterial(payload);
      if (data.statusCode === 200) {
        const newMaterials = allMaterials.map((m) => {
          if (
            m.barcode === material.barcode &&
            m.company === material.company
          ) {
            return {
              ...m,
              width,
              fabric_rate: fabricRate,
              p_fabric_rate: pFabricRate,
              GSM,
              imageUrl: url,
            };
          } else {
            return m;
          }
        });
        setMaterials(newMaterials);
      }
    } else {
      const imageUploadPayload = {
        productId: `Material-${material.fabricId}${material.colorId}`,
        dataUrl: url,
      };
      const imageUploadResponse = await uploadImage(imageUploadPayload);
      if (imageUploadResponse.statusCode === 500) {
        return;
      }
      const payload = {
        company: material.company,
        barcode: material.barcode,
        width,
        fabricRate,
        pFabricRate,
        GSM,
        imageUrl: imageUploadResponse.url,
      };
      const data = await updateMaterial(payload);
      if (data.statusCode === 200) {
        const newMaterials = allMaterials.map((m) => {
          if (
            m.barcode === material.barcode &&
            m.company === material.company
          ) {
            return {
              ...m,
              width,
              fabric_rate: fabricRate,
              p_fabric_rate: pFabricRate,
              GSM,
              imageUrl: url,
            };
          } else {
            return m;
          }
        });
        setMaterials(newMaterials);
      }
    }
  };
  return (
    <Box my={1} p={4}>
      <Stack direction={"row"} gap={3} my={2}>
        <TextField
          style={{ width: "200px" }}
          label="Width"
          value={width}
          onChange={changeWidth}
          variant="standard"
        />
        <TextField
          style={{ width: "200px" }}
          label="Fabric Rate"
          value={fabricRate}
          onChange={changeFabricRate}
          variant="standard"
        />
        <TextField
          style={{ width: "200px" }}
          label="Digital Fabric Rate"
          value={pFabricRate}
          onChange={changePFabricRate}
          variant="standard"
        />
        <TextField
          style={{ width: "200px" }}
          label="GSM"
          value={GSM}
          onChange={changeGSM}
          variant="standard"
        />
      </Stack>
      <div>
        <label htmlFor="contained-button-file">
          <Button variant="outlined" component="span">
            {url ? "Change Image" : "Select Image"}
            <input
              accept="image/*"
              id="contained-button-file"
              style={{
                display: "none",
              }}
              type="file"
              onChange={handleUploadClick}
            />
          </Button>
        </label>
      </div>
      {url && (
        <img
          height={200}
          style={{
            marginTop: "10px",
            objectFit: "contain",
          }}
          src={url}
          alt="Not found"
        />
      )}
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "end",
        }}
      >
        <Button variant="contained" sx={{ my: 1 }} onClick={handleUpdate}>
          Update
        </Button>
      </Stack>
    </Box>
  );
};

export default UpdateMaterialForm;
