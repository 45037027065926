import { toast } from "react-toastify";
import { lambdaFetch } from "../utils/awsUtils";
import { showUnauthorizedModal } from "./errorModalActions";
import { ClientId } from "../utils/UserPool";

const lastAuthUser = localStorage.getItem(
  "CognitoIdentityServiceProvider." + ClientId + ".LastAuthUser"
);
const tokenName =
  "CognitoIdentityServiceProvider." +
  ClientId +
  "." +
  lastAuthUser +
  ".idToken";
const IDtoken = localStorage.getItem(tokenName);

const BASE_URL =
  "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/lbi-influencer/admin";
export const allInfluencers = () => {
  const URL = `${BASE_URL}/influencers`;

  const getAllInfluencers = async (IDtoken, URL) => {
    const response = await lambdaFetch(URL, {
      method: "POST",
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    if (data.statusCode === 200) {
      return data?.body?.influencers;
    } else return [];
  };

  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    getAllInfluencers(IDtoken, URL)
      .then((data) => {
        // console.log("🚀 ~ .then ~ data:", data);
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        dispatch({
          type: "ALL_INFLUENCERS",
          payload: data,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to fetch influencers", {
          theme: "colored",
        });
      })
      .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
  };
};

export const activeInfluencer = (body, influencer) => {
  const URL = `${BASE_URL}/influencers/approve`;
  const activateInfluencer = async (IDtoken, URL, body) => {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify(body),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    if (data.statusCode === 200) {
      return data.approved_at;
    } else return null;
  };

  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    activateInfluencer(IDtoken, URL, body)
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        dispatch({
          type: "UPDATE_INFLUENCER",
          payload: {
            ...influencer,
            ...body,
            approved_at: data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to activate influencer", {
          theme: "colored",
        });
      })
      .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
  };
};

export const rejectInfluencer = (body, influencer) => {
  const URL = `${BASE_URL}/influencers/reject`;
  const helperReject = async (IDtoken, URL, body) => {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify(body),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    if (data.statusCode === 200) {
      return data.rejected_at;
    } else return null;
  };

  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    helperReject(IDtoken, URL, body)
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        dispatch({
          type: "UPDATE_INFLUENCER",
          payload: {
            ...influencer,
            ...body,
            rejected_at: data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to reject influencer", {
          theme: "colored",
        });
      })
      .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
  };
};
export const allOrders = () => {
  const URL = `${BASE_URL}/orders`;

  const getAllOrders = async (IDtoken, URL) => {
    const pageInfo = {
      endCursor: null,
      hasNextPage: true,
    };
    const orders = [];
    while (pageInfo.hasNextPage) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify(pageInfo),
      });
      if (response.status === 403) {
        return 403;
      }
      const data = await response.json();
      console.log("🚀 ~ getAllOrders ~ data:", data);
      if (data.statusCode === 200 && data?.body?.orders) {
        orders.push(...data.body.orders);
      }
      pageInfo.endCursor = data?.body?.pageInfo?.endCursor || "";
      pageInfo.hasNextPage = data?.body?.pageInfo?.hasNextPage || false;
    }

    return orders;
  };

  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    getAllOrders(IDtoken, URL)
      .then((data) => {
        console.log("🚀 ~ .then ~ data:", data);
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        dispatch({
          type: "ALL_ORDERS",
          payload: data,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to fetch orders", {
          theme: "colored",
        });
      })
      .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
  };
};

export const ordersByQuery = async (searchQuery, orderFragment) => {
  const URL = `${BASE_URL}/orders/query`;

  console.log(
    `%c🔎 Fetching orders with query:`,
    "color: cyan; font-weight: bold;",
    searchQuery
  );

  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({
        searchQuery,
        ...(orderFragment ? { orderFragment } : {}),
      }),
    });

    console.log(
      `%c📡 API Response Status: ${response.status}`,
      "color: orange; font-weight: bold;"
    );

    if (response.status === 403) {
      console.log(`%c⛔ Access Denied (403)`, "color: red; font-weight: bold;");
      return 403;
    }

    const data = await response.json();

    if (data.statusCode === 200) {
      console.log(
        `%c✅ Orders fetched successfully!`,
        "color: green; font-weight: bold;"
      );
      return data?.body?.orders;
    } else {
      console.log(`%c⚠️ No orders found`, "color: yellow; font-weight: bold;");
      return [];
    }
  } catch (error) {
    console.log(
      `%c❌ Error fetching orders:`,
      "color: red; font-weight: bold;",
      error
    );
    return [];
  }
};
