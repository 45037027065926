import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, MenuItem, Paper, Stack, TextField } from "@mui/material";

import { getOrdersFromS3ForPdfGeneration } from "../../actions/ordersActions";

import { PageSkeleton } from "../dashboard/helperComponents";
import { createXLforDelhivery } from "../../utils/excelUtils";
import ManualPdfGnTable from "../ordersWidgets/ManualPdfGnTable";
import { allInventory } from "../../actions/inventoryActions";
import { createPDFforManifest } from "../../utils/pdfUtils";
import { allProducts } from "../../actions/productsActions";

const ManualPdfGeneration = () => {
  const todayDate = new Date().toISOString().split("T")[0];
  // 2024-11-07
  const [date, setDate] = useState(todayDate);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isNoOrders, setIsNoOrders] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const [parts, setParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState("");

  const auth = useSelector((state) => state.auth);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const products = useSelector((state) => state.productDetails.products);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventory.length > 0) dispatch(allInventory());
    if (!products.length > 0 && auth) dispatch(allProducts());
  }, []);

  //   use effects
  useEffect(() => {
    if (excelData.length > 0) {
      // csvLink.current.link.click();
      excelData.forEach((e, i) => {
        const csvLink = document.getElementById(`csv-link-${i}`);
        if (csvLink) {
          csvLink.click();
        }
      });
    }
  }, [excelData]);

  const handleSearch = async () => {
    setIsNoOrders(false);
    setLoading(true);
    const ordersInS3 = await getOrdersFromS3ForPdfGeneration(date);
    if (ordersInS3.statusCode === 200) {
      if (ordersInS3.body.length > 0) {
        const s3Parts = ordersInS3.body.map((o) => o.part);
        setParts(s3Parts);
        setSelectedPart(s3Parts[0]);
        // change order order_items url to inventory image url
        setOrders(
          ordersInS3.body.map((os) => {
            return {
              ...os,
              orders: os.orders.map((o) => ({
                ...o,
                order_items: o.order_items.map((ot) => {
                  const findProduct = products.find((p) =>
                    p.variants.find((v) => v.sku === ot.sku.split(".")[0])
                  );
                  if (findProduct) {
                    return {
                      ...ot,
                      url: findProduct.image.src,
                    };
                  } else {
                    return ot;
                  }
                }),
              })),
            };
          })
        );
      } else {
        setOrders([]);
        setIsNoOrders(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedPart) {
      const foundPart = orders.find((o) => o.part === selectedPart);
      if (foundPart) {
        setTableData(foundPart.orders);
      }
    }
  }, [selectedPart, orders]);

  const handleDownload = () => {
    console.log("download ---");
    createPDFforManifest(tableData);
    const delhiveryData = createXLforDelhivery(tableData);
    setExcelData([delhiveryData]);
  };

  return (
    <div>
      {auth && inventory.length > 0 && products.length > 0 ? (
        <>
          <div>
            <h2
              style={{
                textAlign: "center",
              }}
            >
              Delhivery Pdf Generation
            </h2>
            <Stack direction={"row"} gap={2} my={2}>
              <TextField
                type="date"
                size="medium"
                variant="standard"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                label="processed date"
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={loading}
              >
                Search
              </Button>
            </Stack>
            {loading ? (
              <>
                <PageSkeleton />
              </>
            ) : (
              <>
                {isNoOrders && <h3>No orders found</h3>}
                {orders.length > 0 && (
                  <Box>
                    <Box component={Paper} my={1} py={2} px={2}>
                      <Stack
                        direction={"row"}
                        gap={2}
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          sx={{
                            width: "450px",
                          }}
                          label="Dispatch Time"
                          type="text"
                          value={selectedPart}
                          onChange={(e) => setSelectedPart(e.target.value)}
                          size="small"
                          select
                        >
                          {parts.map((x, i) => (
                            <MenuItem key={i} value={x}>
                              {x}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Button variant="outlined" onClick={handleDownload}>
                          Download
                        </Button>
                      </Stack>
                    </Box>
                    {tableData.length > 0 ? (
                      <ManualPdfGnTable tableData={tableData} />
                    ) : (
                      <h3>Data Not Found</h3>
                    )}
                  </Box>
                )}
              </>
            )}
          </div>
          {/* excel download */}
          {excelData.map((ed, i) => (
            <CSVLink
              key={i}
              data={ed}
              id={`csv-link-${i}`}
              // ref={csvLink}
              // target="_blank"
              filename={`Delhivery ${i}`}
            />
          ))}
        </>
      ) : (
        <>
          <PageSkeleton />
        </>
      )}
    </div>
  );
};

export default ManualPdfGeneration;
