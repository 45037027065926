import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { allInventory } from "../../actions/inventoryActions";
import { Backdrop, CircularProgress } from "@mui/material";
import EditProductsForm from "./EditProductsForm";
import { getPatterns } from "../../actions/materialActions";
import { toast } from "react-toastify";

const EditProducts = () => {
  const { stock } = useSelector((state) => state.inventoryDetails);
  const { loading } = useSelector((state) => state.loader);

  const [patternLoading, setPatternLoading] = useState(false);
  const [patterns, setPatterns] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stock.length === 0) dispatch(allInventory());
    getPatterns()
      .then((data) => {
        if (data.statusCode === 200) {
          setPatterns(data.body.map((o) => o.barcode));
        }
        setPatternLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setPatternLoading(false);
      });
  }, []);

  return (
    <>
      <>
        {!stock.length > 0 || loading || patternLoading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={stock.length === 0 || loading}
          >
            <CircularProgress size={100} color="inherit" />
          </Backdrop>
        ) : (
          <EditProductsForm patterns={patterns} />
        )}
      </>
    </>
  );
};

export default EditProducts;
