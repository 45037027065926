import React, { useState } from "react";
import DiscountCodeSelector from "../../influencerComponents/analytics/DiscountCodeSelector";
import OrderNumberSelector from "../../influencerComponents/analytics/OrderNumberSelector";
import OrdersTable from "../../influencerComponents/analytics/OrdersTable";
import RoasData from "../../influencerComponents/analytics/RoasData";
import { Box, Typography, Container, Grid, Button } from "@mui/material";

const InfluencerAnalytics = () => {
  const [codes, setCodes] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [triggerShowOrders, setTriggerShowOrders] = useState(false);
  const [triggerShowRoas, setTriggerShowRoas] = useState(false);
  const [totals, setTotals] = useState({ totalAmount: 0, totalShipping: 0 });

  const handleTotalChange = ({ totalAmount, totalShipping }) => {
    setTotals({ totalAmount, totalShipping });
  };

  const handleRun = () => {
    if (orderNumbers.length === 0) {
      if (!window.confirm("No order numbers found. Do you want to proceed?")) {
        return;
      }
      setTriggerShowOrders(true);
    } else {
      setTriggerShowOrders(true);
      setTriggerShowRoas(true);
    }
  };

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h5" fontWeight={600} align="center" mb={3}>
        Influencer Analytics
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <DiscountCodeSelector codes={codes} setCodes={setCodes} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <OrderNumberSelector
            orderNumbers={orderNumbers}
            setOrderNumbers={setOrderNumbers}
          />
        </Grid>
      </Grid>

      {/* Run Button */}
      {codes.length > 0 && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRun}
            size="large"
          >
            Run
          </Button>
        </Box>
      )}

      {/* Show Orders Table if selected */}
      <Box mt={4}>
        <OrdersTable
          codes={codes}
          triggerShowOrders={triggerShowOrders}
          setTriggerShowOrders={setTriggerShowOrders}
          onTotalChange={handleTotalChange}
        />
      </Box>

      {/* Show ROAS Data if applicable */}
      {orderNumbers.length > 0 && (
        <Box mt={4}>
          <RoasData
            orderNumbers={orderNumbers}
            triggerShowRoas={triggerShowRoas}
            setTriggerShowRoas={setTriggerShowRoas}
            totals={totals}
          />
        </Box>
      )}
    </Container>
  );
};

export default InfluencerAnalytics;
