import React, { useEffect, useState } from 'react'
import { getOrdersByProcessedAtByDateRange } from '../../actions/ordersActions';
import { getTodayStockOpeningDetails } from '../../actions/inventoryActions';
import { getCatalogueReconDetailsByDate, getCatalogueReInventoryDetailsByDate, getCurrentDateReconDetails } from '../../actions/reconActions';
import { useSelector, useDispatch } from "react-redux";
import { allInventory } from "../../actions/inventoryActions";
import InventoryManagement from '../inventoryManagement/InventoryManagement';
import { Box, Skeleton, Typography } from '@mui/material';
// const moment = require('moment-timezone');
import moment from 'moment-timezone';
import { getPoSnapshotByDate, getStockSnapshotByDate } from '../../actions/POActions';
import { DataArrayRounded } from '@mui/icons-material';


// get the start and end date(date starts from 4am to 4am)
const getDateRange = (dateObj) => {
  const timeOnly = moment(dateObj).tz("Asia/Kolkata").format("HH:mm:ss")
  const wholeDate = moment(dateObj).tz("Asia/Kolkata").format()
  const startBuffer = moment("00:00:00", "HH:mm:ss");
  const endBuffer = moment("04:00:00", "HH:mm:ss");
  if (moment(timeOnly, "HH:mm:ss").isBetween(startBuffer, endBuffer)) {
    const oldStartDate = moment(dateObj).tz("Asia/Kolkata").hour(4).minute(0).second(0).format();
    const newStartDate = moment(oldStartDate).subtract(1, "days").format()
    const endDate = moment(dateObj).tz("Asia/Kolkata").hour(4).minute(0).second(0).format();
    // console.log("startDate ...", newStartDate)
    // console.log("endDate ...", endDate)
    return {startDate : newStartDate, endDate}
  }
  const startDate = moment(dateObj).tz("Asia/Kolkata").hour(4).minute(0).second(0).format();
  const endDate = moment(startDate).add(1, "days").format()
  // console.log("startDate", startDate)
  // console.log("endDate", endDate)
  return {startDate, endDate}
}

const InventorySnapshot = () => {
  const {startDate, endDate} = getDateRange(new Date())
  console.log(startDate, endDate)

  const { stock } = useSelector((state) => state.inventoryDetails);
  const [dispatchedOrders, setDispatchedOrders] = useState([])
  const [openingStock, setOpeningStock] = useState([])
  const [reconDetails, setReconDetails] = useState([])
  const [poStockHistory, setPoStockHistory] = useState([]);
  const [stockHistory , setStockHistory] = useState([]);
  const [damageDetails ,setDamageDetails] = useState([]);
  const [revertDetails ,setRevertDetails] = useState([]);
  const [reInventoryDetails, setReInventoryDetails] = useState([]);

  const[loading, setLoading] = useState(false)
  const [isError , setIsError] = useState(false)

  const dispatch = useDispatch()

  
  // fetch all the required data Response (PO, DispatchedOrders, ReconDetails, OpeningStock, Inventory)
  const fetchAllData = async () => {
    setLoading(true)
    const formatedDate = new Date().toISOString().slice(0,10)
    const todayDispatchedOrdersRes  = await getOrdersByProcessedAtByDateRange(startDate,endDate)
    const openingStockRes = await getTodayStockOpeningDetails();
    const reconDetailsRes = await getCatalogueReconDetailsByDate(formatedDate,"recon", "singleDate");
    const damageDetailsRes = await getCatalogueReconDetailsByDate(formatedDate,"damage", "rangeDate");
    const poStockHistoryRes = await getPoSnapshotByDate(new Date().toISOString().slice(0, 10),"po")
    const todayStockHistoryRes = await getPoSnapshotByDate(new Date().toISOString().slice(0, 10),"stock")       
    const todayRevertHistoryRes = await getPoSnapshotByDate(new Date().toISOString().slice(0, 10),"revert") 
    const todayReInventoryHistoryRes = await getCatalogueReInventoryDetailsByDate(new Date().toISOString().slice(0, 10)) 
    console.log("todayDispatchedOrdersRes ", todayDispatchedOrdersRes)

    // const todayStockHistoryRes = await getStockSnapshotByDate(new Date().toISOString().slice(0, 10))

    if(todayStockHistoryRes.statusCode === 500 || 
      todayDispatchedOrdersRes.statusCode === 500 || 
      openingStockRes.statusCode === 500 || 
      reconDetailsRes.statusCode === 500 || 
      poStockHistoryRes.statusCode === 500 || 
      damageDetailsRes.statusCode === 500 || 
      todayRevertHistoryRes.statusCode === 500 ||
      todayReInventoryHistoryRes === 500){
      setIsError(true)
    }else{
      // add data to use state
      setDispatchedOrders(todayDispatchedOrdersRes.data || [])
      setOpeningStock(openingStockRes.body || [])
      setReconDetails(reconDetailsRes.body || [])
      setPoStockHistory(poStockHistoryRes.body || [])
      setStockHistory(todayStockHistoryRes.body || [])
      setDamageDetails(damageDetailsRes.body || [])
      setRevertDetails(todayRevertHistoryRes.body || [])
      setReInventoryDetails(todayReInventoryHistoryRes.body || [])
    }

    setLoading(false)
  }

  useEffect(()=> {
    fetchAllData()
    if(stock.length  === 0){
      dispatch(allInventory())
    }
  },[])

  useEffect(()=> {
    const filteredOrders = dispatchedOrders
    .map(order => {
        // Filter order_items that match the SKU condition
        const matchedItems = order.order_items.filter(item => item.sku.split(".")[0] === "WC1206");

        // If every item in order_items matches the condition, return the order with relevant data
        if (matchedItems.length === order.order_items.length && matchedItems.length > 0) {
            return { 
                name: order.fulfillment_id, 
                matched_items: matchedItems.map(item => ({ sku: item.sku, units: item.units })) 
            };
        }
        return null;
    })
    .filter(order => order !== null);
    console.log("filteredOrders with SKU", filteredOrders)
},[dispatchedOrders])

  return (
    <div>
      {/* skeleton loader  not only loading when everything is fetched then show table*/}
      {/* if error , show error message */}
      {/* <InventoryManagement inventory = {stock} dispatchedOrders =
      {dispatchedOrders} reconOrders = {reconDetails} openingStock = {openingStock}/> */}

       {/* Skeleton Loader while fetching */}
       {loading && (
        <>
          <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 2 }} />
          <Skeleton variant="rectangular" height={300} />
        </>
      )}

      {/* Error Message */}
      {isError && !loading && (
        <Box textAlign="center" p={3} border={1} borderColor="error.main" borderRadius={2}>
          <Typography color="error" variant="h6">Error fetching data. Please try again later.</Typography>
        </Box>
      )}

      {/* Show table only when data is loaded */}
      {!loading && !isError && (
        <InventoryManagement 
          inventory={stock} 
          dispatchedOrders={dispatchedOrders} 
          reconOrders={reconDetails} 
          openingStock={openingStock} 
          poStockHistory = {poStockHistory}
          stockHistory = {stockHistory}
          damagedOrders = {damageDetails}
          revertOrders = {revertDetails}
          reInventoryOrders = {reInventoryDetails}
        />
      )}
    </div>
  )
}

export default InventorySnapshot