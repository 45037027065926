import { lambdaFetch } from "../utils/awsUtils";
import { toast } from "react-toastify";

export const submitAwbSuccess = (data) => ({
  type: "submitAwbSuccess",
  payload: data,
});

export const fetchScanSingleDateSuccess = (data) => ({
  type: "fetchScanSingleDateSuccess",
  payload: data,
});

export const fetchScanSingleDateSuccessDamage = (data) => ({
  type: "fetchScanSingleDateSuccessDamage",
  payload: data,
})

export const getReconDetailsByFulfillmentId = async (fulfillment_id) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/getSingleRecon",
        {
          method: "POST",
          body: JSON.stringify({
            fulfillment_id: fulfillment_id,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      // console.log(data, "data");
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct ID");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const getAllReconDetailsByDateRange = async (start, end) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/getAllRecon",
        {
          method: "POST",
          body: JSON.stringify({
            starting_date: start,
            ending_date: end,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      // console.log(data, "data");
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct Dates");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};
export const getAllReconDetailsBySingleDate = async (selected_date) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/getAllRecon",
        {
          method: "POST",
          body: JSON.stringify({
            selected_date: selected_date,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct Dates");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const getReconOrdersbyDatebyProcessedAt = async (selected_date, mode) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/getReconByDateByprocessedAt",
        {
          method: "POST",
          body: JSON.stringify({
            selected_date: selected_date,
            mode : mode,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.body];
      } else {
        toast.error("Enter Correct Dates");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

 
export const getOrderDetailsByFullfilmentId = async (idName, idNum) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orderDetailsByF_id",
        {
          method: "POST",
          body: JSON.stringify({
            ID: idName,
            value: idNum,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      // console.log(data, "data");
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct Dates");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const  getAllScanSingleDate = (day,mode) => async (dispatch) => {
  try {
    let fetching = true;
    let InScanDetails = [];
    let LastEvaluatedKey = null;
    console.log("day", day);

    while (fetching) {
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/getAllInscanBydate",
        {
          method: "POST",
          body: JSON.stringify({
            date: day,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      console.log("order scaned in the that day", data);
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        InScanDetails = [...InScanDetails, ...data.body];
      } else {
        toast.error("Something went Wrong, Try again");
        return {
          statusCode: 500,
        };
      }
    }
    if(mode === "scaning"){
      dispatch(fetchScanSingleDateSuccess(InScanDetails));
    }
    else if(mode === "damage"){
      dispatch(fetchScanSingleDateSuccessDamage(InScanDetails));
    }
    
    return {
      statusCode: 200,
      body: InScanDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};


export const submitAwbScan = async (awb, timestamp) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/inscan";

  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ awb, timestamp }),
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      toast.success("Updated Successfully");
      return data.body;
    } else if (data.statusCode === 404) {
      toast.error(data.body);
      return false;
    } else {
      toast.error(
        `Error Uploading data into database with statusCode ${data.statusCode}`,
        {
          theme: "colored",
        }
      );
      return false;
    }
  } catch (err) {
    toast.error("Something Went Wrong With Connecting To Server", {
      theme: "colored",
    });
    return false;
  }
};

export const updateRecon = async (fulfillment_id, attribute_name, attribute_value) => {
  const URL =
    "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/updateAttributeInRecon"

  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fulfillment_id, attribute_name, attribute_value })
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      return true
    }  else {
      return false;
    }
  } catch (err) {
    toast.error("Something Went Wrong With Connecting To Server", {
      theme: "colored",
    });
    return false;
  }
};




//Get today recon details
export const getCurrentDateReconDetails = async () => {
  try {
    const currentDate = new Date();
    const selected_date = currentDate
      .toISOString()
      .slice(0, 10);

    let fetching = true;
    let TodayReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      console.log(
        "here"
      )
      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/allReconOrderByProcessAt",
        {
          method: "POST",
          body: JSON.stringify({
            selected_date: selected_date,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      console.log("Recon Fetched data:", data);
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        TodayReconDetails = [...TodayReconDetails, ...data.data];
      } else {
        toast.error("Failed to fetch recon details for today.");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: TodayReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong while fetching current date recon details.");
    return {
      statusCode: 500,
    };
  }
};

// export const getCurrentDateReconDetails = async () => {
//   try {
//     const currentDate = new Date();
//     const selected_date = currentDate.toISOString().slice(0, 10);

//     let fetching = true;
//     let TodayReconDetails = [];
//     let LastEvaluatedKey = null;

//     while (fetching) {
//       console.log("Fetching recon details...");

//       const response = await fetch(
//         "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/getReconCatalougesStockByDate",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             selected_date: selected_date,
//             mode: "recon",
//             LastEvaluatedKey,
//           }),
//         }
//       );

//       const data = await response.json();
//       console.log("Recon Fetched data:", data);

//       if (data.statusCode === 200) {
//         TodayReconDetails = [...TodayReconDetails, ...data.data];
//         LastEvaluatedKey = data.LastEvaluatedKey;

//         if (!LastEvaluatedKey) {
//           fetching = false;
//         }
//       } else {
//         toast.error("Failed to fetch recon details for today.");
//         return {
//           statusCode: 500,
//         };
//       }
//     }

//     return {
//       statusCode: 200,
//       body: TodayReconDetails,
//     };
//   } catch (error) {
//     console.error("Error fetching recon details:", error);
//     toast.error("Something went wrong while fetching current date recon details.");
//     return {
//       statusCode: 500,
//     };
//   }
// };

export const markDamagedRTOorder = async (orderBody) => {
  const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/handleRtoDamage";
  try {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify(orderBody),
      });
      console.log("Response in markDamagedRTOorder", response);
      const data = await response.json();
      console.log("Data in markDamagedRTOorder", data);
      // data has  body :{
      //   orderUpdated: false,
      //   inventoryUpdated: false
      // }
      if (data.statusCode === 200) {
          toast.success("Updated Stock Successfully");
          return data.body;
      } else {
          toast.error("Error in Updating Stock In Inventory", {
              theme: "colored",
          });
          console.log(data);
      }
  } catch (err) {
      console.log("here", err);
      toast.error("Something Went Wrong With Connecting To Database", {
          theme: "colored",
      });
  }
};


export const markDamagedREOorder = async (orderBody) => {
  const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/handleREDamage";
  try {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify(orderBody),
      });
      console.log("Response in markDamagedREOorder", response);
      const data = await response.json();
      console.log("Data in markDamagedRTOorder", data);
      // data has  body :{
      //   orderUpdated: false,
      //   inventoryUpdated: false
      // }
      if (data.statusCode === 200) {
          toast.success("Damaged Mark and Updated Stock Successfully");
          return data.body;
      } else {
          toast.error("Error in Updating Stock In Inventory", {
              theme: "colored",
          });
          console.log(data);
      }
  } catch (err) {
      console.log("here", err);
      toast.error("Something Went Wrong With Connecting To Database", {
          theme: "colored",
      });
  }
};

// update reverse inventory
export const replenishREorder = (payload) => async (dispatch) => {
    // console.log(id)
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/replenishReverseOrder";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        // console.log(data)
        if (data.statusCode === 200) {
            // dispatch({
            //     type: "UPDATE_RTO_STOCK",
            //     payload: id,
            // });
            // dispatch(hideFullScreenLoader());
            toast.success("Stock Added In Inventory Successfully");
        } else {
            toast.error("Error in Adding Stock In Inventory", {
                theme: "colored",
            });
            console.log(data);
            // dispatch(hideFullScreenLoader());
        }
    } catch (err) {
        // dispatch(hideLoader());
        console.log("here", err);
        toast.error("Something Went Wrong", {
            theme: "colored",
        });
    }
};

export const sendToReconQueue = async (orders) => {
  const URL =
      "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/ReconQueue";
  try {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify(orders),
      });
      console.log("Response in Queue", response);
      const data = await response.json();
      console.log("Rec Res", data)
      if (data.statusCode === 200) {
          toast.success("Send to the Queue is successfull");
          return {
            statusCode : 200,
            body : data.body.body
          };
      } else {
        toast.error("Sending Failed to Queue !!");
          return {
            statusCode : 500,
            body : data.body
          };
      }
  } catch (err) {
      console.log("here", err);
      return {
        statusCode : 500,
        body : err
      };
  }
};

export const getCatalogueReconDetailsByDate = async (selected_date,mode,dateType) => {
  try {
    // const currentDate = new Date();
    // const selected_date = currentDate.toISOString().slice(0, 10);
    console.log("selected_date from getCatalogueReconDetailsByDate", selected_date)

    let fetching = true;
    let TodayReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      console.log("Fetching recon details...");

      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/getReconCatalougesStockByDate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selected_date: selected_date,
            mode: mode,
            dateType: dateType,
            LastEvaluatedKey,
          }),
        }
      );

      const data = await response.json();
      console.log("Recon Fetched data:", data);

      if (data.statusCode === 200) {
        TodayReconDetails = [...TodayReconDetails, ...data.data];
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        toast.error("Failed to fetch recon details for today.");
        return {
          statusCode: 500,
        };
      }
    }

    return {
      statusCode: 200,
      body: TodayReconDetails,
    };
  } catch (error) {
    console.error("Error fetching recon details:", error);
    toast.error("Something went wrong while fetching current date recon details.");
    return {
      statusCode: 500,
    };
  }
};

export const getCatalogueReInventoryDetailsByDate = async (selected_date) => {
  try {
    // const currentDate = new Date();
    // const selected_date = currentDate.toISOString().slice(0, 10);
    console.log("selected_date from getCatalogueReconDetailsByDate", selected_date)

    let fetching = true;
    let TodayReInventoryDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      console.log("Fetching reInventory details...");

      const response = await lambdaFetch(
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/recon/getReinventoryDatafromCataloguesTable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: selected_date,
            LastEvaluatedKey,
          }),
        }
      );

      const data = await response.json();
      console.log("ReInventory Fetched data:", data);

      if (data.statusCode === 200) {
        TodayReInventoryDetails = [...TodayReInventoryDetails, ...data.body.items];
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        toast.error("Failed to fetch ReInventory details for today.");
        return {
          statusCode: 500,
        };
      }
    }

    return {
      statusCode: 200,
      body: TodayReInventoryDetails,
    };
  } catch (error) {
    console.error("Error fetching ReInventory details:", error);
    toast.error("Something went wrong while fetching current date recon details.");
    return {
      statusCode: 500,
    };
  }
};