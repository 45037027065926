import { Button, Paper, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllReconDetailsByDateRange, getAllScanSingleDate, getReconOrdersbyDatebyProcessedAt } from "../../actions/reconActions";
import { AgCharts } from "ag-charts-react";

const getLast15Days = () => {
    let dates = [];
    for (let i = 0; i < 7; i++) {
        let date = new Date();
        date.setDate(date.getDate() - i);
        dates.push(date.toISOString().split('T')[0]);
    }
    return dates;
};

const PieChart = ({ data, isLoading }) => {
    const navigate = useNavigate();
    const [options, setOptions] = useState({
        data: [
            // { category: "Total Scanned Orders", value: data.totalScannedOrders },
            { category: "Total Inventory Updated", value: data.totalInventoryUpdated
            },
            { category: "Total Orders Left to update Inventory", value: data.totalOrdersLefttoUpdate },
        ],
        title: {
            text: `Total Orders scanned in last 10 days : ${data.totalScannedOrders}`,
        },
        series: [{
            type: "pie",
            angleKey: "value",
            calloutLabelKey: "category",
            sectorLabelKey: "value",
            sectorLabel: {
                color: "white",
                fontWeight: "bold",
                formatter: ({ value }) => value.toLocaleString(),
            },
            tooltip: {
                renderer: ({ datum }) => ({
                    content: `${datum.category}: ${datum.value}`
                })
            }
        }],
        legend: {
            enabled: true,
            position: "bottom"
        }
    });
    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="60%" margin="auto">
            <h3>RTO & R/E Inscan Analytics of last 10 days</h3>
            {isLoading ? <CircularProgress /> : <AgCharts options={options} style={{ width: '150%' }} />}
            <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={() => navigate("/inscanStatus")}>
                Check RTO and R/E Inscan Details
            </Button>
        </Box>
    );
    // return <AgCharts options={options} />;
};



const InScanAnalytics = () => {
    const [last15DaysScannedData, setLast15DaysScannedData] = useState([])
    const [aggregateData, setAggregateData] = useState([])   
     const [isError, setIsError] = useState(false)
        const [isLoading, setIsLoading] = useState(false)

        const last15days = getLast15Days();

    const fetchData = async () => {
        setIsLoading(true) 
        const last15DaysscannedDataRes = await getReconOrdersbyDatebyProcessedAt(last15days[last15days.length - 1], "dateRange");
        console.log("last15DaysscannedDataRes :", last15DaysscannedDataRes)
        if (last15DaysscannedDataRes.statusCode === 500) {
            setIsError(true);
            setIsLoading(false);
        } else {
            setLast15DaysScannedData(last15DaysscannedDataRes.body || []);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
        console.log(getLast15Days())
    }, []);

    useEffect(() => {
        if (last15DaysScannedData.length > 0) {
            // Calculate aggregate data from 
            const aData = last15days.reduce((acc, date) => {
                const allOrdersbyDate = last15DaysScannedData.filter(
                    (order) => order.processed_at?.split("T")[0] === date
                );
                const inventoryUpdatedOrders = allOrdersbyDate.filter((order) => "isVerified" in order);
                const inventoryNotUpdatedOrders = allOrdersbyDate.filter((order) => !("isVerified" in order));
            
                acc.totalInventoryUpdated += inventoryUpdatedOrders.length || 0;
                acc.totalOrdersLefttoUpdate += inventoryNotUpdatedOrders.length || 0;
            
                return acc;
            }, { totalScannedOrders: last15DaysScannedData.length, totalInventoryUpdated: 0, totalOrdersLefttoUpdate: 0 });
            

            setAggregateData(aData)
            setIsLoading(false);
        }
    }, [last15DaysScannedData]);



    useEffect(() => {
        console.log("aData :", aggregateData)
    },[aggregateData])

    useEffect(() => {
        console.log("last15DaysScannedData :", last15DaysScannedData)
    },[last15DaysScannedData])



    return (
        <Box>
            <PieChart data={aggregateData} isLoading={isLoading} />
        </Box>
    )
};

export default InScanAnalytics;