export const productTypes = [
  {
    value: "",
    label: "None",
  },
  {
    value: "Clothing",
    label: "Clothing",
  },
  {
    value: "Plus-Curve",
    label: "Plus-Curve",
  },
  {
    value: "Men's Clothing",
    label: "Men's Clothing",
  },
  {
    value: "Shoes",
    label: "Footwear",
  },
  {
    value: "Pants",
    label: "Denim Wear",
  },
  {
    value: "Men Shoes",
    label: "Men Footwear",
  },
  {
    value: "Lingerie and Athleisure",
    label: "Lingerie and Athleisure",
  },
];

export const paymentTypes = [
  {
    value: "",
    label: "None",
  },
  {
    value: "Cash on Delivery (COD)",
    label: "COD",
  },
  {
    value: "paid",
    label: "Prepaid",
  },
];

export const clothingSizes = ["XS", "S", "M", "L", "XL"];

export const shoesSizes = ["35", "36", "37", "38", "39", "40", "41"];

export const denimSizes = ["24", "26", "28", "30", "32", "34"];

export const menSizes = ["6", "7", "8", "9", "10"];
export const plusCurveSizes = ["0XL", "1XL", "2XL", "3XL", "4XL"];

export const getSizesByType = (type) => {
  if (type === "Clothing") return clothingSizes;
  if (type === "Men's Clothing") return clothingSizes;
  if (type === "Shoes") return shoesSizes;
  if (type === "Pants") return denimSizes;
  if (type === "Men Shoes") return menSizes;
  if (type === "Lingerie and Athleisure") return clothingSizes;
  if (type === "Plus-Curve") return plusCurveSizes;
};

export const filterByDate = [
  { value: 0, label: "All Days" },
  { value: 7, label: "Last 7 Days" },
  { value: 14, label: "Last 14 Days" },
  { value: 21, label: "Last 21 Days" },
];

export const getSalesType = [
  {
    value: "1",
    label: "All Order Sales Report",
  },
  // {
  //   value: "2",
  //   label: "Unfulfilled Order/Draft Order/Abandoned Checkout Sales Report",
  // },
];

export const filterTypes = [
  {
    value: "all",
    label: "All Orders",
  },
  {
    value: "fulfilled",
    label: "Fullfilled Orders",
  },
  {
    value: "unfulfilled",
    label: "Unfullfilled Orders",
  },
  {
    value: "partial",
    label: "Partial Orders",
  },
];

export const footwearCategories = [
  {
    value: "",
    label: "None",
  },
  {
    value: "FL",
    label: "Flats",
  },
  {
    value: "HL",
    label: "Heels",
  },
  {
    value: "PL",
    label: "Platform",
  },
  {
    value: "FF",
    label: "Flatform",
  },
  {
    value: "DMU",
    label: "Mules",
  },
  {
    value: "BT",
    label: "Boots",
  },
];
export const clothCategories = [
  {
    value: "",
    label: "None",
  },
  {
    value: "TP",
    label: "Tops",
  },
  {
    value: "DR",
    label: "Dress",
  },
  {
    value: "TR",
    label: "Trousers",
  },
  {
    value: "ST",
    label: "Sets",
  },
  {
    value: "TS",
    label: "T-Shirts",
  },
  {
    value: "SK",
    label: "Skirts",
  },
  {
    value: "HD",
    label: "Hoodies",
  },
  {
    value: "WJ",
    label: "Women's Jacket",
  },
  {
    value: "MJ",
    label: "Men's Jacket",
  },
  {
    value: "CT",
    label: "Crop Top",
  },
];

export const plusCurveCategories = [
  {
    value: "PTP",
    label: "PC Tops",
  },
  {
    value: "PDR",
    label: "PC Dress",
  },
  {
    value: "PTR",
    label: "PC Trousers",
  },
  {
    value: "PST",
    label: "PC Sets",
  },
  {
    value: "PTS",
    label: "PC T-Shirts",
  },
  {
    value: "PSK",
    label: "PC Skirts",
  },
  {
    value: "PHD",
    label: "PC Hoodies",
  },
  {
    value: "PWJ",
    label: "PC Women's Jacket",
  },
  {
    value: "PMJ",
    label: "PC Men's Jacket",
  },
  {
    value: "PCT",
    label: "PC Crop Top",
  },
];
export const denimCategories = [
  {
    value: "JN",
    label: "Jeans",
  },
];

export const menShoesCategories = [
  {
    value: "MS",
    label: "Shoes",
  },
];

export const menClothingCategories = [
  {
    value: "MT",
    label: "Men Trousers",
  },
  {
    value: "MB",
    label: "Men Boxers",
  },
  {
    value: "MO",
    label: "Men's Matching Sets",
  },
  {
    value: "MH",
    label: "Men Hoodies",
  },
];

export const LingerieandAthleisureCategories = [
  {
    value: "LG",
    label: "Lingerie",
  },
  {
    value: "AT",
    label: "Athleisure",
  },
];

export const categoryTypes = (type) => {
  if (type === "Clothing") return clothCategories;
  else if (type === "Men's Clothing") return menClothingCategories;
  else if (type === "Plus-Curve") return plusCurveCategories;
  else if (type === "Shoes") return footwearCategories;
  else if (type === "Pants") return denimCategories;
  else if (type === "Men Shoes") return menShoesCategories;
  else if (type === "Lingerie and Athleisure")
    return LingerieandAthleisureCategories;
};

export const boxes = [
  {
    id: "1",
    length: 28,
    breadth: 10,
    height: 10,
    combination: "box1",
  },
  {
    id: "2",
    length: 28,
    breadth: 16,
    height: 10,
    combination: "box2",
  },
  {
    id: "3",
    length: 28,
    breadth: 26,
    height: 10,
    combination: "box1 + box2",
  },
  {
    id: "4",
    length: 28,
    breadth: 16,
    height: 20,
    combination: "box2 + box2",
  },
  {
    id: "5",
    length: 28,
    breadth: 16,
    height: 30,
    combination: "box1 + box2 + box2",
  },
  {
    id: "6",
    length: 28,
    breadth: 16,
    height: 30,
    combination: "box2 + box2 + box2",
  },
  {
    id: "7",
    length: 28,
    breadth: 22,
    height: 1,
    combination: "cloth1",
  },
  {
    id: "8",
    length: 28,
    breadth: 22,
    height: 2,
    combination: "cloth2",
  },
  {
    id: "9",
    length: 28,
    breadth: 22,
    height: 3,
    combination: "cloth1 + cloth2",
  },
  {
    id: "10",
    length: 28,
    breadth: 22,
    height: 4,
    combination: "cloth2 + cloth2",
  },
  {
    id: "11",
    length: 28,
    breadth: 22,
    height: 5,
    combination: "cloth1 + cloth2 + cloth2",
  },
  {
    id: "12",
    length: 28,
    breadth: 22,
    height: 6,
    combination: "cloth2 + cloth2 + cloth2",
  },
  {
    id: "13",
    length: 28,
    breadth: 11,
    height: 11,
    combination: "box1 + cloth1",
  },
  {
    id: "14",
    length: 28,
    breadth: 12,
    height: 12,
    combination: "box1 + cloth2",
  },
  {
    id: "15",
    length: 28,
    breadth: 16,
    height: 11,
    combination: "box2 + cloth1",
  },
  {
    id: "16",
    length: 28,
    breadth: 16,
    height: 12,
    combination: "box2 + cloth2",
  },
  {
    id: "17",
    length: 28,
    breadth: 26,
    height: 11,
    combination: "box1 + box2 + cloth1",
  },
  {
    id: "18",
    length: 28,
    breadth: 26,
    height: 12,
    combination: "box1 + box2 + cloth2",
  },
  {
    id: "19",
    length: 28,
    breadth: 16,
    height: 21,
    combination: "box2 + box2 + cloth1",
  },
  {
    id: "20",
    length: 28,
    breadth: 16,
    height: 22,
    combination: "box2 + box2 + cloth2",
  },
  {
    id: "21",
    length: 28,
    breadth: 14,
    height: 14,
    combination: "box1 + cloth2 + cloth2",
  },
  {
    id: "22",
    length: 28,
    breadth: 13,
    height: 13,
    combination: "box1 + cloth1 + cloth2",
  },
  {
    id: "23",
    length: 28,
    breadth: 16,
    height: 14,
    combination: "box2 + cloth2 + cloth2",
  },
  {
    id: "24",
    length: 28,
    breadth: 16,
    height: 13,
    combination: "box2 + cloth1 + cloth2",
  },
  {
    id: "25",
    length: 28,
    breadth: 20,
    height: 10,
    combination: "box3",
  },
  {
    id: "26",
    length: 28,
    breadth: 20,
    height: 11,
    combination: "box3 + cloth1",
  },
  {
    id: "27",
    length: 28,
    breadth: 20,
    height: 12,
    combination: "box3 + cloth2",
  },
  {
    id: "28",
    length: 28,
    breadth: 20,
    height: 13,
    combination: "box3 + cloth1 + cloth2",
  },
  {
    id: "29",
    length: 28,
    breadth: 20,
    height: 14,
    combination: "box3 + cloth2 + cloth2",
  },
  {
    id: "30",
    length: 28,
    breadth: 20,
    height: 20,
    combination: "box3 + box3",
  },
  {
    id: "31",
    length: 28,
    breadth: 20,
    height: 30,
    combination: "box3 + box3 + box3",
  },
  {
    id: "32",
    length: 28,
    breadth: 20,
    height: 20,
    combination: "box1 + box3",
  },
  {
    id: "33",
    length: 28,
    breadth: 20,
    height: 20,
    combination: "box2 + box3",
  },
  {
    id: "34",
    length: 28,
    breadth: 20,
    height: 30,
    combination: "box2 + box2 + box3",
  },
  {
    id: "35",
    length: 28,
    breadth: 20,
    height: 30,
    combination: "box1 + box3 + box3",
  },
  {
    id: "36",
    length: 28,
    breadth: 20,
    height: 21,
    combination: "box1 + box3 + cloth1",
  },
  {
    id: "37",
    length: 28,
    breadth: 20,
    height: 22,
    combination: "box1 + box3 + cloth2",
  },
  {
    id: "38",
    length: 28,
    breadth: 20,
    height: 21,
    combination: "box2 + box3 + cloth1",
  },
  {
    id: "39",
    length: 28,
    breadth: 20,
    height: 22,
    combination: "box2 + box3 + cloth2",
  },
  {
    id: "40",
    length: 28,
    breadth: 20,
    height: 21,
    combination: "box3 + box3 + cloth1",
  },
  {
    id: "41",
    length: 28,
    breadth: 20,
    height: 22,
    combination: "box3 + box3 + cloth1",
  },
  {
    id: "42",
    length: 28,
    breadth: 20,
    height: 30,
    combination: "box2 + box3 + box3",
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const skuTypes = [
  {
    value: "FL",
    label: "Footwear",
  },
  {
    value: "HL",
    label: "Footwear",
  },
  {
    value: "PL",
    label: "Footwear",
  },
  {
    value: "FF",
    label: "Footwear",
  },
  {
    value: "DMU",
    label: "Footwear",
  },
  {
    value: "BT",
    label: "Footwear",
  },
  {
    value: "TP",
    label: "Clothing",
  },
  {
    value: "DR",
    label: "Clothing",
  },
  {
    value: "TR",
    label: "Clothing",
  },
  {
    value: "ST",
    label: "Clothing",
  },
  {
    value: "TS",
    label: "Clothing",
  },
  {
    value: "JN",
    label: "Clothing",
  },
  {
    value: "MS",
    label: "Footwear",
  },
  {
    value: "SK",
    label: "Clothing",
  },
  {
    value: "HD",
    label: "Clothing",
  },
  {
    value: "MJ",
    label: "Clothing",
  },
  {
    value: "WJ",
    label: "Clothing",
  },
  {
    value: "WS",
    label: "Clothing",
  },
  {
    value: "MT",
    label: "Clothing",
  },
  {
    value: "MH",
    label: "Clothing",
  },
  {
    value: "LG",
    label: "Clothing",
  },
  {
    value: "AT",
    label: "Clothing",
  },
  {
    value: "MB",
    label: "Clothing",
  },
  {
    value: "MO",
    label: "Clothing",
  },
  {
    value: "CT",
    label: "Clothing",
  },
  {
    value: "WC",
    label: "Clothing",
  },
  {
    value: "MC",
    label: "Clothing",
  },
  {
    value: "PCDR",
    label: "Clothing",
  },
  {
    value: "PDR",
    label: "Clothing",
  },
  {
    value: "PTP",
    label: "Clothing",
  },
  {
    value: "PTR",
    label: "Clothing",
  },
  {
    value: "PSK",
    label: "Clothing",
  },
  {
    value: "PST",
    label: "Clothing",
  },
];

export const footwearVendor = [
  {
    value: "SUMIT MISS CLOG",
    label: "SUMIT MISS CLOG",
  },
  {
    value: "JAI MALIK",
    label: "JAI MALIK",
  },
  {
    value: "Littlebox India",
    label: "Littlebox India",
  },
];
export const clothingVendor = [
  {
    value: "BINITA CREATION",
    label: "BINITA CREATION",
  },
  {
    value: "JK FASHION",
    label: "JK FASHION",
  },
  {
    value: "Littlebox India",
    label: "Littlebox India",
  },
  {
    value: "TRENDY RABIT",
    label: "TRENDY RABIT",
  },
];
export const denimVendor = [
  {
    value: "Littlebox India",
    label: "Littlebox India",
  },
];

export const menShoesVendor = [
  {
    value: "Littlebox India",
    label: "Littlebox India",
  },
];

export const vendorTypes = (type) => {
  if (type === "Clothing") return clothingVendor;
  if (type === "Men's Clothing") return clothingVendor;
  else if (type === "Shoes") return footwearVendor;
  else if (type === "Pants") return denimVendor;
  else if (type === "Men Shoes") return menShoesVendor;
  else if (type === "Lingerie and Athleisure") return clothingVendor;
  else if (type === "Plus-Curve") return clothingVendor;
};

export const statusArray = [
  {
    value: "Active",
    label: "active",
  },
  {
    value: "Draft",
    label: "draft",
  },
];

export const templateSuffixArray = [
  {
    value: "dresses-template",
    label: "dresses-template",
  },
  {
    value: "flats-mules-template",
    label: "flats-mules-template",
  },
  {
    value: "platform-template",
    label: "platform-template",
  },
  {
    value: "preorder",
    label: "preorder",
  },
  {
    value: "t-shirt-template",
    label: "t-shirt-template",
  },
  {
    value: "top-template",
    label: "top-template",
  },
  {
    value: "trouser-set-template",
    label: "trouser-set-template",
  },
  {
    value: "men-shoes",
    label: "men-shoes",
  },
  {
    value: "men-trousers",
    label: "men-trousers",
  },
  {
    value: "men-hoodies",
    label: "men-hoodies",
  },
  {
    value: "men-jacket",
    label: "men-jacket",
  },
  {
    value: "women-jacket",
    label: "women-jacket",
  },
  {
    value: "lingerie_athleisure",
    label: "lingerie_athleisure",
  },
  {
    value: "Plus-Curve",
    label: "Plus-Curve",
  },
  {
    value: "skirt-template",
    label: "skirt-template",
  },
  {
    value: "trouser-template",
    label: "trouser-template",
  },
  {
    value: "men-matching-sets",
    label: "men-matching-sets",
  },
];

export const tagsCategoriesArray = [
  "black",
  "black flats",
  "denim",
  "dresses",
  "flats",
  "heels",
  "mules",
  "new in",
  "platforms",
  "sets",
  "slides",
  "summer",
  "tops",
  "trouser",
  "tshirt",
  "oxfords",
  "winter",
  "hoodies",
  "men_shoes",
  "men_hoodie",
  "flatforms",
  "men_trouser",
  "skirts",
  "gorpcore",
  "men_jacket",
  "women_jacket",
  "lingerie",
  "athleisure",
  "plus-curve",
  "feeling-sexy",
  "feeling-edgy",
  "feeling-cute",
  "feeling-elegant",
  "feeling-minimalist",
  "feeling-cozy",
  "feeling-free",
  "feeling-kpop",
  "rich feel",
  "pc-tops",
  "pc-dress",
  "pc-trousers",
  "pc-sets",
  "pc-tshirts",
  "pc-skirts",
  "pc-hoodies",
  "pc-womens-jacket",
  "pc-mens-jacket",
  "pc-crop-top",
  "cocktail dresses",
  "bodycon dresses",
  "midi dresses",
  "velvet dresses",
  "maxi dresses",
  "mini dresses",
  "floral dresses",
  "backless dresses",
  "crop tops",
  "tank tops",
  "corset tops",
  "halter neck tops",
  "sleeveless tops",
  "off-shoulder tops",
  "full sleeve tops",
  "cargo pants",
  "baggy pants",
  "korean pants",
  "wide leg pants",
  "top and trouser set",
  "top and skirt set",
  "blazer and dress set",
  "combo",
  "top combos",
  "trouser combos",
];

export const tagsArray = ["None", "Shopify", "Hypd", "Zaamo", "Wishlink"];

export const tagsArrayNew = [
  "None",
  "Shopify",
  "Zaamo",
  "Wishlink",
  "GoAffPro",
];

export const OrderTypeOptions = ["All", "COD", "Prepaid"];

export const TotalAttemptOptions = ["1", "2", "3", "4", "5", "6", ">6"];

export const PendingDayOptions = [
  "1-5",
  "5-10",
  "10-15",
  "15-20",
  "20-25",
  ">25",
];

export const LastDeliveryStatusOptions = [
  "Customer not available x Customer not available at given address and not reachable over phone",
  "Customer not available x Customer out of station",
  "Customer not available x Customer wants delivery at different address",
  "Customer not available x Office or Residence closed",
  "Customer not available x Security/Gate restriction and customer not reachable. Entry not permitted in office/building",
  "Customer Refused To Accept",
  "Customer wants future delivery",
  "Customer Wants Open Delivery",
  "Door step delivery not attempted",
  "Force majeure situation",
  "Wrong Pincode",
  "Address incomplete",
  "COD Amount Not Ready",
];

export const lastReverseStatusOptions = [
  "Address incomplete & Customer not reachable",
  "Customer requested for future pickup",
  "Customer shifted",
  "Handed over to other couriers",
  "Office/Residence closed",
  "Pending",
  "Pick Up Cancelled by Client",
  "Pickup cancelled by customer after OFP",
  "RPCancel",
  "Investigate",
];

export const FootwearRatio = [
  {
    label: "Flats",
    value: { 36: 25, 37: 25, 38: 20, 39: 15, 40: 10, 41: 5 },
  },
  {
    label: "Heels",
    value: { 36: 33, 37: 20, 38: 20, 39: 15, 40: 7, 41: 5 },
  },
  {
    label: "Platform",
    value: { 36: 33, 37: 20, 38: 20, 39: 15, 40: 7, 41: 5 },
  },
  {
    label: "Flatform",
    value: { 36: 25, 37: 25, 38: 20, 39: 15, 40: 10, 41: 5 },
  },
  {
    label: "Mules",
    value: { 36: 25, 37: 25, 38: 20, 39: 15, 40: 10, 41: 5 },
  },
  {
    label: "Boots",
    value: { 36: 25, 37: 25, 38: 20, 39: 15, 40: 10, 41: 5 },
  },
];

export const ClothingRatio = [
  {
    label: "Tops",
    value: { XS: 33, S: 25, L: 20, M: 15, XL: 7 },
  },
  {
    label: "Dress",
    value: { XS: 33, S: 25, L: 20, M: 15, XL: 7 },
  },
  {
    label: "Trousers",
    value: { XS: 33, S: 25, L: 20, M: 15, XL: 7 },
  },
  {
    label: "Sets",
    value: { XS: 33, S: 25, L: 20, M: 15, XL: 7 },
  },
  {
    label: "T-Shirts",
    value: { XS: 33, S: 25, L: 20, M: 15, XL: 7 },
  },
  {
    label: "Skirts",
    value: { XS: 33, S: 25, L: 20, M: 15, XL: 7 },
  },
  {
    label: "Hoodies",
    value: { XS: 33, S: 25, L: 20, M: 15, XL: 7 },
  },
];

export const MenShoeRatio = [
  {
    label: "Shoes",
    value: { 6: 25, 7: 25, 8: 20, 9: 15, 10: 10 },
  },
];

export const materialFabricArray = [
  {
    code: "RB",
    name: "Rib",
  },
  {
    code: "SN",
    name: "Sandwich",
  },
  {
    code: "SS",
    name: "Supreme Sandwich",
  },
  {
    code: "RC",
    name: "Rib Cord",
  },
  {
    code: "ID",
    name: "Imported Denim",
  },
  {
    code: "SM",
    name: "Softy Moss",
  },
  {
    code: "TR",
    name: "Terry",
  },
  {
    code: "SK",
    name: "Shakira",
  },
  {
    code: "CT",
    name: "Cotton",
  },
  {
    code: "CTT",
    name: "Cotton Terry",
  },
  {
    code: "SJ",
    name: "Single Jersey",
  },
  {
    code: "FC",
    name: "Fleece",
  },
  {
    code: "IL",
    name: "Interlock Spandex",
  },
  {
    code: "SP",
    name: "Spandex Net",
  },
  {
    code: "BT",
    name: "Boss Twill",
  },
  {
    code: "NR",
    name: "Not Required",
  },
  {
    code: "NY",
    name: "Nylon",
  },
  { code: "CR", name: "Caramel" },
  { code: "PQK", name: "PQ Knit" },
  { code: "PK", name: "POLY KNIT" },
  { code: "BK", name: "Box Knit" },
  { code: "PTW", name: "Poly Twill" },
  { code: "IS", name: "Invention System" },
  { code: "B", name: "Bentley" },
  { code: "CD", name: "Cab Denim" },
  { code: "PC", name: "Prgaue Cotton" },
  { code: "FT", name: "FB Twill" },
  { code: "KC", name: "Korean Cigar" },
  { code: "001", name: "Pixel knite" },
  { code: "002", name: "European BSY" },
  { code: "003", name: "Poly Terry 300" },
  { code: "006", name: "Prague Cotton" },
  { code: "007", name: "BOW BUBBLE" },
  { code: "008", name: "DOT BUBBLE" },
  { code: "009", name: "SUGARCANE" },
  { code: "010", name: "VISCOSE HEAVY LINEN" },
  { code: "011", name: "Mango crepe" },
  { code: "012", name: "Banana crepe plus" },
  { code: "013", name: "Latte crepe" },
  { code: "014", name: "Apra FX twill" },
  { code: "015", name: "Poly Rayon" },
  { code: "016", name: "Summercool Heavy" },
  { code: "017", name: "Rare Viscoce" },
  {
    code: "018",
    name: "Poly crepe",
  },
  { code: "019", name: "POLY GGT HEAVY" },
  { code: "020", name: "POLY LINEN" },
  { code: "021", name: "RADIANT" },
  { code: "022", name: "Velvet Lycra" },
  { code: "023", name: "P Satin" },
  { code: "024", name: "125 TWILL" },
  { code: "025", name: "225 TWILL" },
  { code: "026", name: "butterscotch" },
  { code: "027", name: "Cord Velvet" },
  { code: "028", name: "Korean Lycra" },
  { code: "029", name: "Spandex Leather" },
  { code: "030", name: "SKUBA LEATHER" },
  { code: "031", name: "4 Way Lycra" },
  { code: "032", name: "American Crepe" },
  { code: "033", name: "Hammered Velvet" },
  { code: "034", name: "MOROCCO ORGANZA" },
  { code: "035", name: "Velvet floral spandex mesh" },
  { code: "036", name: "Primo Foil" },
  { code: "037", name: "Karara" },
  { code: "038", name: "Ponte De Roma" },
  { code: "039", name: "Silky Velvet" },
  { code: "040", name: "Chiffon" },
  { code: "041", name: "Softnet" },
  { code: "042", name: "POPLIN" },
  { code: "043", name: "ORGANZA" },
  { code: "044", name: "PLAIN BSY" },
  { code: "045", name: "FEATHER INTERLOCK" },
  { code: "046", name: "FLEXI S/JERSEY" },
  { code: "047", name: "Shifly Work" },
];

export const materialColorArray = [
  { code: "001", name: "White" },
  { code: "002", name: "Black" },
  { code: "003", name: "Chocolate Fondae" },
  { code: "004", name: "Apricot" },
  { code: "005", name: "Hot Pink" },
  { code: "006", name: "Dusty Blue" },
  { code: "007", name: "Piecan Pie" },
  { code: "008", name: "Burnt Orange" },
  { code: "009", name: "Avocado" },
  { code: "010", name: "Eclectic Blue" },
  { code: "011", name: "Mocha" },
  { code: "012", name: "Tanin(Minimal Brown)" },
  { code: "013", name: "Olive Green" },
  { code: "014", name: "Mud Brown" },
  { code: "015", name: "Dusty Pink" },
  { code: "016", name: "Rama Green" },
  { code: "017", name: "Teal Blue" },
  { code: "018", name: "Neon" },
  { code: "019", name: "Lavender" },
  { code: "020", name: "Grey" },
  { code: "021", name: "Off White" },
  { code: "023", name: "Green" },
  // { code: "024", name: "Sage Green" },
  { code: "025", name: "White Swan" },
  { code: "026", name: "Rain Wash" },
  { code: "027", name: "Hot Fudge" },
  { code: "028", name: "Mud" },
  { code: "029", name: "Latte" },
  // { code: "030", name: "Chocolate" },
  { code: "031", name: "Brown" },
  { code: "032", name: "65571" },
  // { code: "033", name: "Cosmic Latte" },
  { code: "034", name: "65215" },
  { code: "035", name: "Blue" },
  { code: "036", name: "65220" },
  { code: "037", name: "498" },
  { code: "038", name: "Powder Blue" },
  // { code: "039", name: "Digital Print" },
  { code: "040", name: "491" },
  { code: "041", name: "Cream(Apricot)" },
  { code: "042", name: "Pista" },
  { code: "043", name: "241" },
  { code: "044", name: "Bottle Green" },
  { code: "045", name: "454" },
  { code: "046", name: "455" },
  { code: "047", name: "456" },
  { code: "048", name: "492" },
  { code: "049", name: "495" },
  { code: "050", name: "496" },
  { code: "051", name: "497" },
  { code: "052", name: "499" },
  { code: "053", name: "500" },
  { code: "054", name: "501" },
  { code: "055", name: "502" },
  { code: "056", name: "503" },
  { code: "057", name: "504" },
  { code: "058", name: "Not required" },
  { code: "059", name: "Navy Blue" },
  { code: "060", name: "Light brown" },
  { code: "061", name: "Elderberry" },
  { code: "062", name: "cappucino" },
  { code: "063", name: "Brown 1045" },
  { code: "064", name: "Baby pink" },
  { code: "065", name: "Dark Blue" },
  { code: "066", name: "Light Blue" },
  { code: "067", name: "Mud Gold" },
  { code: "068", name: "Smoke Green" },
  { code: "069", name: "Dark Green" },
  { code: "070", name: "650" },
  { code: "071", name: "507" },
  { code: "072", name: "508" },
  { code: "073", name: "Butter cream" },
  { code: "074", name: "Black PinStripe" },
  { code: "075", name: "453" },
  { code: "076", name: "705" },
  { code: "077", name: "707" },
  { code: "078", name: "708-68110" },
  { code: "079", name: "704" },
  { code: "080", name: "712" },
  { code: "081", name: "709" },
  { code: "082", name: "713" },
  { code: "083", name: "714" },
  { code: "084", name: "716" },
  { code: "085", name: "LIGHT GREY" },
  { code: "086", name: "DARK GREY" },
  { code: "087", name: "Khaki 4" },
  { code: "088", name: "Coco J-281" },
  { code: "089", name: "Smokey Brown 236-H" },
  { code: "090", name: "Brownie J-223" },
  { code: "091", name: "Vanilla J-283" },
  { code: "092", name: "Herbal 278-J" },
  { code: "093", name: "Herbal 280-E" },
  { code: "094", name: "Coco 280-D" },
  { code: "095", name: "68085" },
  { code: "096", name: "BLUE 19-4033 TPG" },
  { code: "097", name: "Apricot 233D" },
  { code: "098", name: "Olive 233F" },
  { code: "099", name: "767 - 13903" },
  { code: "100", name: "754 - 13913" },
  { code: "101", name: "Khaki 236E" },
  { code: "102", name: "Cream 234D" },
  { code: "103", name: "brown 233J" },
  { code: "104", name: "745-13932" },
  { code: "105", name: "2401-800" },
  { code: "106", name: "2403-801" },
  { code: "107", name: "BEIGE A-314" },
  { code: "108", name: "BARBIE H-314" },
  { code: "111", name: "748-13936" },
  { code: "112", name: "234 D (CREAM)" },
  { code: "113", name: "747-13837" },
  { code: "114", name: "750-13934" },
  { code: "115", name: "MILKY WHITE" },
  { code: "116", name: "FRENCH BLUE J-941" },
  { code: "117", name: "PEACH / (LIGHT-RUST J-931)" },
  { code: "118", name: "RUST J-938" },
  { code: "119", name: "KHADI L-04" },
  { code: "120", name: "WHITE J-777" },
  { code: "121", name: "751-13933" },
  { code: "122", name: "Dusty Pink 12" },
  { code: "123", name: "Black 5" },
  { code: "124", name: "Maroon 8" },
  { code: "125", name: "Green 15" },
  { code: "126", name: "Herbal" },
  { code: "127", name: "Baby Pink - 8005" },
  { code: "128", name: "Herbal 395-C" },
  { code: "129", name: "Beige" },
  { code: "130", name: "62558 (Toile be Juoy)" },
  { code: "131", name: "Rust-10119" },
  { code: "132", name: "Cream-10120" },
  { code: "133", name: "830 RGB" },
  { code: "134", name: "Mud-K-324" },
  { code: "135", name: "Peach 6" },
  { code: "136", name: "822-13904" },
  { code: "137", name: "902-2418" },
  { code: "138", name: "901-2402" },
  { code: "139", name: "Pista 7" },
  { code: "140", name: "19901" },
  { code: "141", name: "19902" },
  { code: "142", name: "19903" },
  { code: "143", name: "19904" },
  { code: "144", name: "29901" },
  { code: "145", name: "29902" },
  { code: "147", name: "69853" },
  { code: "148", name: "69861" },
  { code: "149", name: "2402-799" },
  { code: "150", name: "Dark wine" },
  { code: "151", name: "Dark violet 69901" },
  { code: "152", name: "Stone 2410" },
  { code: "153", name: "Light French 2409" },
  { code: "154", name: "Herbal 978-J" },
  { code: "155", name: "Raspberry 974-J" },
  { code: "156", name: "Light pink 415-B" },
  { code: "157", name: "A2" },
  { code: "158", name: "Dusty Blue 415-H" },
  { code: "159", name: "2412" },
  { code: "160", name: "Blue fin" },
  { code: "161", name: "Blueberry" },
  { code: "162", name: "Cosmic latte" },
  { code: "163", name: "861-2410" },
  { code: "164", name: "871-2413" },
  { code: "165", name: "869-2411" },
  { code: "166", name: "2409" },
  { code: "167", name: "890-2415" },
  { code: "168", name: "892-62548" },
  { code: "169", name: "902-2418" },
  { code: "170", name: "39901" },
  { code: "171", name: "39902" },
  { code: "172", name: "pinstripe white" },
  { code: "173", name: "69901" },
  { code: "174", name: "889-2416" },
  { code: "175", name: "888-2417" },
  { code: "176", name: "419" },
  { code: "177", name: "39903" },
  { code: "178", name: "FRENCH BLUE J998" },
  { code: "179", name: "B.GREY 479-E" },
  { code: "180", name: "B.GREY 479-B" },
  { code: "181", name: "910-70848" },
  { code: "182", name: "2001" },
  { code: "183", name: "KC - 251" },
  { code: "184", name: "50202" },
  { code: "185", name: "50201" },
  { code: "186", name: "20301" },
  { code: "187", name: "OLIVE GREEN 504-C" },
  { code: "188", name: "39904" },
  { code: "189", name: "sky blue 2416" },
  { code: "190", name: "957 A4" },
  { code: "191", name: "Maroon 419" },
  { code: "192", name: "KC-251 RP" },
  { code: "193", name: "69824" },
  { code: "194", name: "D red 465C" },
  { code: "195", name: "D red 479-D" },
  { code: "196", name: "D red 479-C" },
  { code: "197", name: "SKINE 557-G" },
  { code: "198", name: "2420" },
  { code: "199", name: "2422" },
  { code: "200", name: "2425" },
  { code: "201", name: "2426" },
  { code: "202", name: "2433" },
  { code: "203", name: "SKY BLUE 489-B" },
  { code: "204", name: "934-2430" },
  { code: "205", name: "F Blue" },
  { code: "206", name: "Aqua" },
  { code: "207", name: "military green" },
  { code: "208", name: "70115" },
  { code: "209", name: "L.Beige" },
  { code: "210", name: "F.Blue" },
  { code: "211", name: "50203" },
  { code: "212", name: "Sky Blue-5" },
  { code: "213", name: "KHAKI" },
  // { code: "214", name: "BLACK" },
  { code: "215", name: "D-MAROON" },
  { code: "216", name: "ROSE GOLD" },
  { code: "217", name: "2649" },
  { code: "219", name: "light gold" },
  { code: "220", name: "Dark Brown" },
  { code: "221", name: "maroon" },
  { code: "222", name: "Burgundy" },
  { code: "223", name: "Cherry" },
  { code: "224", name: "Wine" },
  { code: "225", name: "989-71687" },
  { code: "226", name: "DARK NAVY BLUE" },
  { code: "227", name: "ICE BLUE" },
  { code: "228", name: "BRICK" },
  { code: "229", name: "802-2501" },
  { code: "230", name: "802-2500" },
  { code: "231", name: "993-2438" },
  { code: "232", name: "998-71838" },
  { code: "233", name: "6011" },
  { code: "234", name: "D Peach" },
  { code: "235", name: "Rose" },
  { code: "236", name: "2435" },
  { code: "237", name: "2437" },
  { code: "238", name: "1865" },
  { code: "239", name: "978" },
  { code: "240", name: "Red" },
  { code: "241", name: "50206" },
  { code: "242", name: "976-71639" },
  { code: "243", name: "Pink" },
  { code: "244", name: "Mouse" },
  { code: "245", name: "50207" },
  { code: "246", name: "Silver" },
  { code: "247", name: "6011" },
  { code: "248", name: "4011" },
  { code: "249", name: "69825" },
  { code: "250", name: "Coca" },
  { code: "251", name: "SAGE" },
  { code: "252", name: "50205" },
  { code: "253", name: "Melange" },
  { code: "254", name: "50204" },
  { code: "255", name: "80848" },
  { code: "256", name: "M green 70115" },
  { code: "257", name: "D peach" },
  { code: "258", name: "708-67969" },
  { code: "259", name: "Cream" },
  { code: "260", name: "Steel Grey" },
  { code: "261", name: "70101" },
  { code: "262", name: "70102" },
  { code: "263", name: "70103" },
  { code: "264", name: "10111" },
  { code: "265", name: "80101" },
  { code: "266", name: "80102" },
  { code: "267", name: "80103" },
  { code: "268", name: "7011" },
  { code: "269", name: "4012" },
  { code: "270", name: "4013" },
  { code: "271", name: "50212" },
  { code: "272", name: "50213" },
  { code: "273", name: "50214" },
  { code: "274", name: "50215" },
  { code: "275", name: "29905" },
  { code: "276", name: "29904" },
  { code: "277", name: "29903" },
  { code: "278", name: "29906" },
  { code: "279", name: "29907" },
  { code: "280", name: "29908" },
  { code: "281", name: "RUBY" },
  { code: "282", name: "TAUPE" },
  { code: "283", name: "X PRESSO" },
  { code: "284", name: "D olive" },
  { code: "285", name: "69826" },
  { code: "286", name: "Mint" },
  { code: "287", name: "Powder Pink" },
];

export const materialSoleFabricArray = [
  {
    code: "AD",
    name: "Aldo",
  },
  {
    code: "BL",
    name: "Blocker",
  },
  {
    code: "DVJ",
    name: "Devji Sole",
  },
  {
    code: "DVJ1",
    name: "Devji Sole 1",
  },
  {
    code: "DVJ2",
    name: "Devji Sole 2",
  },
  {
    code: "DB",
    name: "Double Blocker",
  },
  {
    code: "PR",
    name: "Prada",
  },
  {
    code: "VS",
    name: "Versace",
  },
  {
    code: "YG",
    name: "Yogi",
  },
  {
    code: "ZR",
    name: "Zara",
  },
];

export const materialSolePlaceArray = [
  { code: "100", name: "AGRA" },
  { code: "101", name: "DELHI_OWN" },
];

export const findMaterialId = (fabric, color) => {
  const fabricArr = [...materialFabricArray, ...materialSoleFabricArray];
  console.log(fabricArr, fabric);
  const fabricFound = fabricArr.find((f) => f.name === fabric);
  const colorFound = materialColorArray.find((c) => c.name === color);
  const colorSet = colorFound ? colorFound : "";
  return fabricFound.code + colorSet.code;
};

//courier name
export const courier_shopify = {
  "Blue Dart Surface": "Bluedart",
  "Ecom Express Surface": "Ecom Express",
  "Ekart Logistics Surface": "Ekart",
  "Ekart 2Kg": "Ekart",
  "Smartr Logistics": "Smartr Logistics",
  "Delhivery Surface": "Delhivery",
  "DTDC Surface": "DTDC",
  Delhivery: "Delhivery",
  "DTDC 500GMS": "DTDC",
  "DTDC 2kg": "DTDC",
  "DTDC 5kg": "DTDC",
};

export const reverseSkuTypes = [
  {
    value: "FL",
    label: "Footwear",
    category: "Flats",
  },
  {
    value: "HL",
    label: "Footwear",
    category: "Heels",
  },
  {
    value: "PL",
    label: "Footwear",
    category: "Platform",
  },
  {
    value: "FF",
    label: "Footwear",
    category: "Flatform",
  },
  {
    value: "DMU",
    label: "Footwear",
    category: "Mules",
  },
  {
    value: "BT",
    label: "Footwear",
    category: "Boots",
  },
  {
    value: "TP",
    label: "Clothing",
    category: "Tops",
  },
  {
    value: "DR",
    label: "Clothing",
    category: "Dresses",
  },
  {
    value: "MJ",
    label: "Clothing",
    category: "Men's Jacket",
  },
  {
    value: "WJ",
    label: "Clothing",
    category: "Women's Jacket",
  },
  {
    value: "TR",
    label: "Clothing",
    category: "Trousers",
  },
  {
    value: "ST",
    label: "Clothing",
    category: "Sets",
  },
  {
    value: "TS",
    label: "Clothing",
    category: "T-Shirts",
  },
  {
    value: "JN",
    label: "Clothing",
    category: "Jeans",
  },
  {
    value: "MS",
    label: "Footwear",
    category: "Men Shoes",
  },
  {
    value: "SK",
    label: "Clothing",
    category: "Skirt",
  },
  {
    value: "HD",
    label: "Clothing",
    category: "Hoodies",
  },
  {
    value: "MT",
    label: "Clothing",
    category: "Men Trousers",
  },
  {
    value: "MH",
    label: "Clothing",
    category: "Men Hoodies",
  },
  {
    value: "LG",
    label: "Lingerie",
    category: "Lingerie",
  },
  {
    value: "AT",
    label: "Athleisure",
    category: "Athleisure",
  },
];

export const findReverseName = (sku) => {
  const skuStart = sku.slice(0, 2);
  const findName = reverseSkuTypes.find((s) => s.value === skuStart);
  if (findName) {
    return findName.label + "/" + findName.category;
  } else {
    return sku;
  }
};

export const skuWiseTypes = [
  {
    sku: "FL",
    type: "Shoes",
    category: "Flats",
  },
  {
    sku: "HL",
    type: "Shoes",
    category: "Heels",
  },
  {
    sku: "PL",
    type: "Shoes",
    category: "Platforms",
  },
  {
    sku: "FF",
    type: "Shoes",
    category: "Flatforms",
  },
  {
    sku: "DMU",
    type: "Shoes",
    category: "Mules",
  },
  {
    sku: "BT",
    type: "Shoes",
    category: "Boots",
  },
  {
    sku: "TP",
    type: "Clothing",
    category: "Tops",
  },
  {
    sku: "MJ",
    type: "Clothing",
    category: "Men's Jacket",
  },
  {
    sku: "WJ",
    type: "Clothing",
    category: "Women's Jacket",
  },
  {
    sku: "DR",
    type: "Clothing",
    category: "Dress",
  },
  {
    sku: "TR",
    type: "Clothing",
    category: "Trousers",
  },
  {
    sku: "ST",
    type: "Clothing",
    category: "Sets",
  },
  {
    sku: "TS",
    type: "Clothing",
    category: "T-shirts",
  },
  {
    sku: "JN",
    type: "Pants",
    category: "Jeans",
  },
  {
    sku: "MS",
    type: "Men Shoes",
    category: "Men shoes",
  },
  {
    sku: "SK",
    type: "Clothing",
    category: "Skirts",
  },
  {
    sku: "HD",
    type: "Clothing",
    category: "Hoodies",
  },
  {
    sku: "MT",
    type: "Men's Clothing",
    category: "Men's Trouser",
  },
  {
    sku: "MB",
    type: "Men's Clothing",
    category: "Men Boxers",
  },
  {
    sku: "MO",
    type: "Men's Clothing",
    category: "Men's Matching Sets",
  },
  {
    sku: "MH",
    type: "Men's Clothing",
    category: "Men Hoodies",
  },
  {
    sku: "LG",
    type: "Lingerie and Athleisure",
    category: "Lingerie",
  },
  {
    sku: "AT",
    type: "Lingerie and Athleisure",
    category: "Athleisure",
  },
  {
    sku: "PSK",
    type: "Plus-Curve",
    category: "Plus Curve Skirts",
  },
  {
    sku: "MO",
    type: "Men's Clothing",
    category: "Men's Outfit",
  },
  {
    sku: "PST",
    type: "Plus-Curve",
    category: "Plus Curve Sets",
  },
  {
    sku: "CT",
    type: "Clothing",
    category: "Crop Top",
  },
  {
    sku: "MC",
    type: "Men's Clothing",
    category: "Men Trousers",
  },
  {
    sku: "MB",
    type: "Men's Clothing",
    category: "Men Boxers",
  },
  {
    sku: "WC",
    type: "Clothing",
    category: "Women's Camise",
  },
  {
    sku: "PTR",
    type: "Plus-Curve",
    category: "Plus Curve Trousers",
  },
  {
    sku: "PTP",
    type: "Plus-Curve",
    category: "Plus Curve Top",
  },
  {
    sku: "PDR",
    type: "Plus-Curve",
    category: "Plus Curve Dress",
  },
  {
    sku: "PCDR",
    type: "Plus-Curve",
    category: "Plus Curve Dress",
  },
];

export const indianStates = [
  "Andaman and Nicobar islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

export const forwardOrderStatus = [
  { value: "DRC", label: "Data Received" },
  { value: "OFP", label: "Out For Pickup" },
  { value: "PND", label: "Pickup Not Done" },
  { value: "PKD", label: "Picked" },
  { value: "IT", label: "IT" },
  { value: "RAD", label: "Reached at Destination" },
  { value: "OFD", label: "Out for Delivery" },
  { value: "DLVD", label: "Delivered" },
  { value: "UD", label: "Undelivered" },
  { value: "RTON", label: "RTO Notified" },
  { value: "RTO", label: "RTO" },
  { value: "RTO-IT", label: "RTO-IT" },
  { value: "RAO", label: "Reached At Origin" },
  { value: "RTO-OFD", label: "RTO Out For Delivery" },
  { value: "RTD", label: "RTO Delivered" },
  { value: "RTU", label: "RTO Undelivered" },
  { value: "PUD", label: "Pickup done" },
  { value: "STD", label: "ShipmentTransit" },
  { value: "STG", label: "Shortage" },
];

export const xbOrderStatus = [
  { label: "DRC", value: ["DRC", "PND", "STG"] },
  {
    label: "IN TRANSIT",
    value: ["OFP", "PKD", "IT", "RAD", "OFD", "PUD", "STD"],
  },
  { label: "NDR", value: ["UD"] },
  { label: "RTO", value: ["RTON", "RTO", "RTO-IT", "RAO", "RTO-OFD", "RTU"] },
  { label: "RTO DELIVERED", value: ["RTD"] },
  { label: "DELIVERED", value: ["DLVD"] },
];
export const delhiveryOrderStatus = [
  {
    label: "Manifested",
    value: ["UD_Manifested"],
  },
  {
    label: "Not picked",
    value: ["UD_Not_Picked"],
  },
  {
    label: "In Transit",
    value: ["UD_In Transit", "UD_In_Transit", "UD_Pending", "UD_Dispatched"],
  },
  { label: "Delivered", value: ["Delivered"] },
  {
    label: "RTO In Transit",
    value: ["RT_In Transit", "RT_In_Transit", "RT_Pending", "RT_Dispatched"],
  },
  { label: "RTO Delivered", value: ["RTO"] },
  {
    label: "RTO All Status",
    value: [
      "RT_In Transit",
      "RT_In_Transit",
      "RT_Pending",
      "RT_Dispatched",
      "RTO",
    ],
  },
];

export const shiprocketStatus = [
  {
    label: "Pickup",
    value: [
      "sh#Pickup_Rescheduled",
      "sh#Pickup_Exception",
      "sh#Pickup_Error",
      "sh#Out_For_Pickup",
      "sh#Pending",
      "sh#Picked_Up",
      "sh#pickup_queued",
      "sh#pickup_scheduled",
    ],
  },
  {
    label: "Manifest",
    value: ["sh#manifest_generated"],
  },
];
// export const comboSKUs = [
//   "TP1549",
//   "TP1540",
//   "TP1548",
//   "TP1547",
//   "TP1550",
//   "TP1543",
//   "TP1542",
//   "TP1546",
//   "TP1545",
//   "TP1544",
//   "TP1541",
//   "TR1332",
//   "TR1331",
//   "TR1330",
//   "TR1328",
//   "TR1327",
//   "TR1326",
//   "TR1325",
//   "TP1572",
//   "TP1571",
//   "TP1570",
//   "TP1569",
//   "TP1568",
//   "TP1567",
//   "TP1566",
//   "TP1565",
//   "TP1564",
//   "TP1563",
//   "TP1562",
//   "TP1561",
//   "TP1560",
//   "TR1296",
//   "TR1295",
//   "TR1295",
//   "TR1290",
//   "TR1289",
//   "TR1288",
//   "TR1287",
//   "TR1272",
//   "TR1265",
//   "ST1263",
//   "TP1505",
//   "TR1216",
//   "TR1215",
//   "TR1212",
//   "TR1209",
//   "TR1198",
//   "TR1197",
//   "TR1204",
//   "TR1203",
// ];

export const comboSKUs = [
  "ST1111",
  "ST1129",
  "ST1138",
  "ST1140",
  "ST1143",
  "ST1145",
  "ST1146",
  "ST1149",
  "ST1150",
  "ST1153",
  "ST1156",
  "ST1157",
  "ST1160",
  "ST1169",
  "ST1171",
  "ST1172",
  "ST1173",
  "ST1174",
  "ST1176",
  "ST1178",
  "ST1179",
  "ST1185",
  "ST1187",
  "ST1192",
  "ST1193",
  "ST1203",
  "ST1209",
  "ST1211",
  "ST1212",
  "ST1213",
  "ST1214",
  "ST1215",
  "ST1216",
  "ST1218",
  "ST1221",
  "ST1222",
  "ST1223",
  "ST1224",
  "ST1227",
  "ST1229",
  "ST1240",
  "ST1242",
  "ST1244",
  "ST1247",
  "ST1248",
  "ST1249",
  "ST1250",
  "ST1254",
  "ST1255",
  "ST1256",
  "ST1258",
  "ST1263",
  "ST1266",
  "ST1270",
  "ST1275",
  "ST1281",
  "ST1282",
  "ST1290",
  "ST1291",
  "ST1292",
  "ST1293",
  "ST1295",
  "ST1301",
  "ST1302",
  "ST1303",
  "ST1306",
  "ST1307",
  "ST1308",
  "ST1309",
  "ST1310",
  "ST1311",
  "ST1314",
  "ST1315",
  "ST1316",
  "ST1317",
  "ST1319",
  "ST1320",
  "ST1321",
  "ST1326",
  "ST1329",
  "ST1330",
  "ST1332",
  "ST1336",
  "ST1337",
  "ST1338",
  "ST1339",
  "ST1340",
  "ST1342",
  "ST1343",
  "ST1346",
  "ST1349",
  "ST1350",
  "ST1351",
  "ST1352",
  "ST1353",
  "ST1356",
  "ST1358",
  "ST1362",
  "ST1363",
  "ST1364",
  "ST1388",
  "ST1389",
  "ST1391",
  "ST1392",
  "ST1393",
  "ST1394",
  "ST1395",
  "ST1397",
  "ST1400",
  "ST1401",
  "ST1403",
  "ST1404",
  "ST1405",
  "ST1406",
  "ST1408",
  "ST1409",
  "ST1410",
  "ST1411",
  "ST1413",
  "ST1415",
  "ST1417",
  "ST1419",
  "ST1420",
  "ST1423",
  "ST1425",
  "ST1429",
  "ST1430",
  "ST1445",
  "ST1446",
  "ST1450",
  "ST1451",
  "ST1452",
  "ST1453",
  "ST1454",
  "ST1455",
  "ST1457",
  "ST1459",
  "ST1468",
  "ST1470",
  "ST1471",
  "ST1475",
  "ST1480",
  "ST1483",
  "ST1484",
  "ST1487",
  "ST1494",
  "ST1496",
  "ST1503",
  "ST1504",
  "ST1506",
  "ST1507",
  "ST1510",
  "ST1511",
  "ST1512",
  "ST1513",
  "ST1516",
  "ST1522",
  "ST1523",
  "ST1530",
  "TR1276",
  "TR1265",
  "TR1331",
  "TR1204",
  "TR1203",
  "TR1288",
  "TR1325",
  "TR1330",
  "TR1202",
  "TR1327",
  "TR1328",
  "TR1287",
  "TR1326",
  "TR1215",
  "TR1296",
  "TP1563",
  "TP1596",
  "TP1550",
  "TP1560",
  "TP1365",
  "TP1420",
  "TP1366",
  "TP1505",
  "TP1458",
  "TP1570",
  "TP1548",
  "TP1566",
  "TP1540",
  "TP1543",
  "TP1544",
  "TP1565",
  "TP1547",
  "TP1421",
  "TP1568",
  "TP1564",
  "TP1549",
  "TP1567",
  "TP1571",
  "TP1572",
  "TR1272",
  "TR1175",
  "TR1166",
  "TR1212",
  "TR1174",
  "TR1216",
  "TR1215",
  "TR1209",
  "TR1167",
  "TR1168",
  "TR1169",
];

export const materialVendors = [
  { code: "001", name: "Inhouse" },
  { code: "002", name: "JK" },
  { code: "003", name: "Binita" },
  { code: "004", name: "Miss Clog" },
  { code: "005", name: "T2B" },
];

export const fabricVendors = [
  { code: "001", name: "S K Mohan" },
  { code: "002", name: "Greyline" },
  { code: "003", name: "Rosewood" },
  { code: "004", name: "Ajay Goel" },
];

export const accessories = [
  { id: "001", zip: "CONCEAL 1", length: "10", unit: "inch" },
  { id: "002", zip: "CONCEAL 2", length: "10", unit: "inch" },
  { id: "003", zip: "CONCEAL 3", length: "14", unit: "inch" },
  { id: "004", zip: "CONCEAL 4", length: "16", unit: "inch", rate: 32 },
  { id: "005", zip: "CONCEAL 5", length: "18", unit: "inch" },
  { id: "006", zip: "CONCEAL 6", length: "20", unit: "inch" },
  { id: "007", zip: "CONCEAL 7", length: "22", unit: "inch" },
  { id: "008", zip: "OPENED ZIP 1", length: "8", unit: "inch" },
  { id: "009", zip: "OPENED ZIP 2", length: "10", unit: "inch" },
  { id: "010", zip: "OPENED ZIP 3", length: "12", unit: "inch" },
  { id: "011", zip: "OPENED ZIP 4", length: "14", unit: "inch" },
  { id: "012", zip: "FLYER ZIP 1", length: "8", unit: "inch" },
  { id: "013", zip: "BOTTOM ZIP 1", length: "12", unit: "inch" },
  { id: "014", zip: "CFC ZIP (WOMEN FLYER)", length: "8", unit: "inch" },
  { id: "015", zip: "C4 (TOP) 1", length: "14", unit: "inch" },
  { id: "016", zip: "C4 (TOP) 2", length: "20", unit: "inch" },
  { id: "017", zip: "ALLUMINIUM SILVER ZIP 1", length: "16", unit: "inch" },
];

export const cuttingVendors = [
  { code: "001", name: "UMESH" },
  { code: "002", name: "SUDISH" },
  { code: "003", name: "VICKY" },
  { code: "004", name: "SHATRUGHAN" },
  { code: "005", name: "PARDEEP" },
  { code: "006", name: "JK" },
  { code: "007", name: "BINITA" },
  { code: "008", name: "MISS CLOG" },
  { code: "009", name: "T2B" },
  { code: "010", name: "VINOD" },
];

export const stitchingVendors = [
  { code: "001", name: "VIJAY" },
  { code: "002", name: "REHAN" },
  { code: "003", name: "SHAFI" },
  { code: "004", name: "JK" },
  { code: "005", name: "BINITA" },
  { code: "006", name: "MISS CLOG" },
  { code: "007", name: "T2B" },
  { code: "008", name: "Pradeep NOIDA" },
];

export const finishingVendors = [
  { code: "001", name: "11 No" },
  { code: "002", name: "10 No" },
];

export const POVendors = [
  { code: "001", name: "UMESH" },
  { code: "002", name: "SUDISH" },
  { code: "003", name: "VICKY" },
  { code: "004", name: "SHATRUGHAN" },
  { code: "005", name: "PARDEEP" },
  { code: "006", name: "JK" },
  { code: "007", name: "BINITA" },
  { code: "008", name: "MISS CLOG" },
  { code: "009", name: "T2B" },
  { code: "010", name: "VINOD" },
];

export const trackingLifecycleTableHeadings = [
  "FulfillmentID",
  "PackingStatus",
  "Remarks",
  "DAY1",
  "DAY2",
  "DAY3",
  "DAY4",
  "DAY5",
  "DAY6",
  "DAY7",
  "AWB",
  "ReferenceNo",
  "CustomerName",
  "City",
  "State",
  "Country",
  "Address",
  "Pincode",
  "Mobile",
  "Weight",
  "Length",
  "Breadth",
  "Height",
  "PaymentType",
  "PackageAmount",
  "CODAmount",
  "Products",
  "Quantity",
  "PickupLocation",
  "ReturnAddress",
  "ReturnPin",
  "ShippingMode",
];
