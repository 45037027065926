import React, { useState } from "react";
import {
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";

const DiscountCodeSelector = ({ codes, setCodes }) => {
  const [newCodes, setNewCodes] = useState("");

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        const parsedCodes = text
          .split(/\r?\n/)
          .map((code) => code.trim())
          .filter(Boolean);
        setCodes((prev) => [...new Set([...prev, ...parsedCodes])]);
      };
      reader.readAsText(file);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const handleAddCodes = (e) => {
    e.preventDefault();
    const parsedCodes = newCodes
      .split(/\r?\n/)
      .map((code) => code.trim())
      .filter(Boolean);
    setCodes((prev) => [...new Set([...prev, ...parsedCodes])]);
    setNewCodes("");
  };

  const handleRemoveCode = (codeToRemove) => {
    setCodes((prev) => prev.filter((code) => code !== codeToRemove));
  };

  const handleClearAll = () => {
    setCodes([]);
  };

  return (
    <Paper
      elevation={4}
      sx={{ p: 3, maxWidth: 450, mx: "auto", mt: 4, borderRadius: 3 }}
    >
      <Typography variant="h6" fontWeight={600} gutterBottom align="center">
        Discount Codes
      </Typography>

      {/* Drag and Drop Upload Section */}
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #1976d2",
          p: 3,
          textAlign: "center",
          borderRadius: 2,
          backgroundColor: "#f5f5f5",
          cursor: "pointer",
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          transition: "0.3s",
          "&:hover": { backgroundColor: "#e3f2fd" },
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 48, color: "#1976d2" }} />
        <Typography variant="body2" mt={1}>
          Drag & Drop or Click to Upload CSV
        </Typography>
      </Box>

      {/* Multi-line Text Area Entry */}
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        mb={2}
        component="form"
        onSubmit={handleAddCodes}
      >
        <textarea
          rows="4"
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            fontSize: "14px",
            resize: "none",
          }}
          placeholder="Paste discount codes, one per line"
          value={newCodes}
          onChange={(e) => setNewCodes(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ whiteSpace: "nowrap" }}
        >
          Add
        </Button>
      </Box>

      {/* List of Added Discount Codes */}
      <List sx={{ maxHeight: 200, overflowY: "auto", p: 0 }}>
        {codes.map((code, index) => (
          <ListItem
            key={index}
            sx={{
              backgroundColor: "#f1f1f1",
              mb: 1,
              borderRadius: 2,
              display: "flex",
              justifyContent: "space-between",
              px: 2,
            }}
          >
            <ListItemText primary={code} sx={{ fontSize: "14px" }} />
            <IconButton size="small" onClick={() => handleRemoveCode(code)}>
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          </ListItem>
        ))}
      </List>

      {/* Clear All Button */}
      {codes.length > 0 && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="outlined" color="secondary" onClick={handleClearAll}>
            Clear All
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default DiscountCodeSelector;
