import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Grid,
} from "@mui/material";
import OrderNumberSelector from "../../influencerComponents/analytics/OrderNumberSelector";
import { ordersByQuery } from "../../../actions/influencerActions";

const orderFragment = `
fragment order on Order {
    name
    createdAt
    tags
    customerJourneySummary {
        customerOrderIndex
        momentsCount {
            count
        }
        firstVisit {
            utmParameters {
                medium
                source
            }
            occurredAt
            sourceDescription
            referralInfoHtml
        }
        lastVisit {
            utmParameters {
                medium
                source
            }
            occurredAt
            sourceDescription
            referralInfoHtml
        }
    }
}`;

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
};

const calculateDaysToConvert = (firstDate, lastDate) => {
  if (!firstDate || !lastDate) return "N/A";
  const first = new Date(firstDate);
  const last = new Date(lastDate);
  const diffTime = Math.abs(last - first);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const CustomerJourney = () => {
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRun = async () => {
    setIsLoading(true);
    const fetchedOrders = {}; // Store orders using orderNumber as keys

    for (let i = 0; i < orderNumbers.length; i += 20) {
      const batch = orderNumbers.slice(i, i + 20);
      const promises = batch.map(async (orderNumber) => {
        const searchQuery = `name:${orderNumber}`;
        const result = await ordersByQuery(searchQuery, orderFragment);
        return { orderNumber, orderData: result[0] || null }; // Assuming result is an array
      });

      const batchResults = await Promise.all(promises);
      batchResults.forEach(({ orderNumber, orderData }) => {
        fetchedOrders[orderNumber] = orderData; // Store result by orderNumber
      });
    }

    // Convert fetchedOrders back into an array following the original orderNumbers sequence
    const sortedOrders = orderNumbers
      .map((orderNumber) => fetchedOrders[orderNumber] || null)
      .filter(Boolean);

    setOrders(sortedOrders);
    setIsLoading(false);
  };

  const handleExportCSV = () => {
    const csvRows = [];
    const headers = [
      "Order Date",
      "Order ID",
      "Order Index",
      "Moments Count",
      "First Visit Date",
      "First Visit UTM Source",
      "First Visit UTM Medium",
      "First Visit Description",
      "Last Visit Date",
      "Last Visit UTM Source",
      "Last Visit UTM Medium",
      "Last Visit Description",
      "Days to Convert",
      "Tags",
    ];
    csvRows.push(headers.join(","));

    orders.forEach((order) => {
      const firstVisit = order.customerJourneySummary?.firstVisit || {};
      const lastVisit = order.customerJourneySummary?.lastVisit || {};
      const firstDate = firstVisit.occurredAt;
      const lastDate = lastVisit.occurredAt;

      const formattedTags = order?.tags
        ? `"${order.tags.map((tag) => tag.replace(/"/g, '""')).join(", ")}"`
        : "N/A";

      const row = [
        formatDate(order.createdAt),
        order.name,
        order.customerJourneySummary?.customerOrderIndex || "N/A",
        order.customerJourneySummary?.momentsCount?.count || "N/A",
        formatDate(firstDate),
        firstVisit.utmParameters?.source || "N/A",
        firstVisit.utmParameters?.medium || "N/A",
        firstVisit.sourceDescription || "N/A",
        formatDate(lastDate),
        lastVisit.utmParameters?.source || "N/A",
        lastVisit.utmParameters?.medium || "N/A",
        lastVisit.sourceDescription || "N/A",
        calculateDaysToConvert(firstDate, lastDate),
        formattedTags,
      ];
      csvRows.push(row.join(","));
    });

    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "customer_journey.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  // const handleExportCSV = () => {
  //   const csvRows = [];
  //   const headers = [
  //     "Order Date",
  //     "Order ID",
  //     "Order Index",
  //     "Moments Count",
  //     "First Visit",
  //     "Last Visit",
  //     "Days to Convert",
  //     "Tags",
  //   ];
  //   csvRows.push(headers.join(","));

  //   orders.forEach((order) => {
  //     const firstVisit = order.customerJourneySummary?.firstVisit || {};
  //     const lastVisit = order.customerJourneySummary?.lastVisit || {};
  //     const firstDate = firstVisit.occurredAt;
  //     const lastDate = lastVisit.occurredAt;

  //     const formatMultilineText = (text) => {
  //       return text.replace(/"/g, '""'); // Escape quotes
  //     };

  //     const firstVisitDetails = `"Date: ${formatDate(
  //       firstDate
  //     )}\r\nUTM Source: ${
  //       firstVisit.utmParameters?.source || "N/A"
  //     }\r\nUTM Medium: ${
  //       firstVisit.utmParameters?.medium || "N/A"
  //     }\r\nDescription: ${firstVisit.sourceDescription || "N/A"}"`;

  //     const lastVisitDetails = `"Date: ${formatDate(lastDate)}\r\nUTM Source: ${
  //       lastVisit.utmParameters?.source || "N/A"
  //     }\r\nUTM Medium: ${
  //       lastVisit.utmParameters?.medium || "N/A"
  //     }\r\nDescription: ${lastVisit.sourceDescription || "N/A"}"`;

  //     const formattedTags = `"${order.tags?.join("\r\n") || "N/A"}"`;

  //     const row = [
  //       formatDate(order.createdAt),
  //       order.name,
  //       order.customerJourneySummary?.customerOrderIndex || "N/A",
  //       order.customerJourneySummary?.momentsCount?.count || "N/A",
  //       firstVisitDetails,
  //       lastVisitDetails,
  //       calculateDaysToConvert(firstDate, lastDate),
  //       formattedTags,
  //     ];
  //     csvRows.push(row.join(","));
  //   });

  //   const blob = new Blob([csvRows.join("\r\n")], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "customer_journey.csv";
  //   a.click();
  //   URL.revokeObjectURL(url);
  // };

  return (
    <Box>
      <Grid container spacing={3} alignItems="start" justifyContent="start">
        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column-reverse">
            <OrderNumberSelector
              orderNumbers={orderNumbers}
              setOrderNumbers={setOrderNumbers}
            />

            <Box display="flex" justifyContent="center" gap={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRun}
                size="large"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Run"}
              </Button>

              {orders.length > 0 && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleExportCSV}
                >
                  Export to CSV
                </Button>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={9}>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "85vh",
              overflow: "auto",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Order Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Order ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Order Index</b>
                  </TableCell>
                  <TableCell>
                    <b>Moments Count</b>
                  </TableCell>
                  <TableCell>
                    <b>First Visit</b>
                  </TableCell>
                  <TableCell>
                    <b>Last Visit</b>
                  </TableCell>
                  <TableCell>
                    <b>Days to Convert</b>
                  </TableCell>
                  <TableCell>
                    <b>Tags</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.length > 0 ? (
                  orders.map((order) => {
                    const firstVisit =
                      order.customerJourneySummary?.firstVisit || {};
                    const lastVisit =
                      order.customerJourneySummary?.lastVisit || {};
                    const firstDate = firstVisit.occurredAt;
                    const lastDate = lastVisit.occurredAt;

                    return (
                      <TableRow key={order.name}>
                        <TableCell>{formatDate(order.createdAt)}</TableCell>
                        <TableCell>{order.name}</TableCell>
                        <TableCell>
                          {order.customerJourneySummary?.customerOrderIndex ||
                            "N/A"}
                        </TableCell>
                        <TableCell>
                          {order.customerJourneySummary?.momentsCount?.count ||
                            "N/A"}
                        </TableCell>
                        <TableCell>
                          {formatDate(firstDate)} <br />
                          <b>UTM Source:</b>{" "}
                          {firstVisit.utmParameters?.source || "N/A"}
                          <br />
                          <b>UTM Medium:</b>{" "}
                          {firstVisit.utmParameters?.medium || "N/A"}
                          <br />
                          <b>Description:</b>{" "}
                          {firstVisit.sourceDescription || "N/A"}
                        </TableCell>
                        <TableCell>
                          {formatDate(lastDate)} <br />
                          <b>UTM Source:</b>{" "}
                          {lastVisit.utmParameters?.source || "N/A"}
                          <br />
                          <b>UTM Medium:</b>{" "}
                          {lastVisit.utmParameters?.medium || "N/A"}
                          <br />
                          <b>Description:</b>{" "}
                          {lastVisit.sourceDescription || "N/A"}
                        </TableCell>
                        <TableCell>
                          {calculateDaysToConvert(firstDate, lastDate)}
                        </TableCell>
                        <TableCell>
                          {order?.tags?.map((tag, index) => (
                            <React.Fragment key={index}>
                              {tag}
                              {index < order.tags.length - 1 && <br />}
                            </React.Fragment>
                          ))}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography variant="h6" color="textSecondary">
                        Please enter all the order numbers in the input field
                        and click "Run" to retrieve customer journey data.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerJourney;
