import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import {
  createReceiveChallanHistory,
  getMaterialList,
  receiveMaterialFromVendor,
} from "../../actions/materialActions";
import { toast } from "react-toastify";
import ReceiveAmtTable from "./ReceiveAmtTable";
import { createMaterialChallan } from "../../utils/pdfUtils";

function removeDuplicates(arr) {
  return arr.filter(
    (item, index, self) => index === self.findIndex((t) => t.code === item.code)
  );
}
const ReceiveFabricForm = ({ vendorList, fabrics }) => {
  const [vendor, setVendor] = useState("");
  const [challanNumber, setChallanNumber] = useState("");
  const [materials, setMaterials] = useState([]);
  // const [fabrics, setFabrics] = useState([]);
  const [colors, setColors] = useState([]);

  const [selectedFabric, setSelectedFabric] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

  const [invoiceDate, setInvoiceDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [quantity, setQuantity] = useState(0);
  const [unitValue, setUnitValue] = useState(0);

  const [materialsChosen, setMaterialsChosen] = useState([]);
  const [receivedBy, setReceivedBy] = useState("");
  const [unit, setUnit] = useState("kg");

  console.log("invoice date", invoiceDate);

  // const handleClearAll = () => {
  //   setFabrics([]);
  //   setColors([]);
  // };

  useEffect(() => {
    if (selectedFabric) {
      setColors([]);
      getMaterialList(selectedFabric).then((data) => {
        if (data.statusCode === 200) {
          const vendorColors = data.body.map((m) => ({
            code: m.colorId,
            name: m.colorName,
          }));
          if (vendorColors.length === 0) {
            toast.error("No colors found for this fabric");
          }
          setMaterials(data.body);
          setColors(vendorColors);
        }
      });
    }
  }, [selectedFabric]);

  const handleNumberInput = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      setQuantity(0);
    } else {
      setQuantity(parseInt(e.target.value));
    }
  };

  const handleUnitValueInput = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      setUnitValue(0);
    } else {
      setUnitValue(parseInt(e.target.value));
    }
  };

  const handleAdd = () => {
    const fabricName = fabrics.find((f) => f.code === selectedFabric).name;
    const colorName = colors.find((c) => c.code === selectedColor).name;
    const isDuplicateId = materialsChosen.find(
      (m) => m.fabricId === selectedFabric && m.colorId === selectedColor
    );
    if (!!isDuplicateId) {
      toast.error("Already Added");
    } else {
      const material = materials.find(
        (m) => m.fabricId === selectedFabric && m.colorId === selectedColor
      );
      if ("avgAmt" in material && "totalAmt" in material) {
        const avgAmt = parseFloat(material.avgAmt);
        const totalAmt = parseInt(material.totalAmt);
        const prevAmt = avgAmt * totalAmt;
        const rollAmt = parseFloat(unitValue / quantity);
        const oldUnitValue = isNaN(parseInt(material.unitValue))
          ? 0
          : parseInt(material.unitValue);
        console.log("old unit value", oldUnitValue);
        const newRollAmt = parseFloat(
          (prevAmt + rollAmt) / (totalAmt + 1)
        ).toFixed(1);
        setMaterialsChosen((prev) => [
          ...prev,
          {
            fabricId: selectedFabric,
            colorId: selectedColor,
            amt: newRollAmt,
            prevAvgAmt: avgAmt,
            totalAmt: totalAmt + 1,
            quantity,
            unitValue: oldUnitValue + unitValue,
            unit,
            fabricName,
            colorName,
          },
        ]);
      } else {
        const rollAmt = parseFloat(unitValue / quantity).toFixed(1);
        const oldUnitValue = isNaN(parseInt(material.unitValue))
          ? 0
          : parseInt(material.unitValue);
        console.log("old unit value", oldUnitValue);
        setMaterialsChosen((prev) => [
          ...prev,
          {
            fabricId: selectedFabric,
            colorId: selectedColor,
            amt: rollAmt,
            quantity,
            unitValue: oldUnitValue + unitValue,
            unit,
            fabricName,
            colorName,
          },
        ]);
      }
    }
  };
  const handleRemove = (fabricId, colorId) => {
    setMaterialsChosen((prev) =>
      prev.filter((p) => p.fabricId !== fabricId && p.colorId !== colorId)
    );
  };

  const handleReceiveStock = async () => {
    console.log("materials chosen", materialsChosen);
    const vendorName = vendorList.find((v) => v.code === vendor).name;
    createMaterialChallan(
      materialsChosen,
      invoiceDate,
      vendorName,
      challanNumber,
      receivedBy
    );
    return;
    const payload = materialsChosen.map((m) => ({
      vendorId: vendor,
      fabricId: m.fabricId,
      colorId: m.colorId,
      amt: m.quantity,
      unitValue: m.unitValue,
      rollAmt: m.amt,
      totalAmt: "totalAmt" in m ? m.totalAmt : 1,
    }));
    console.log(payload);
    const data = await receiveMaterialFromVendor(payload);
    if (data.statusCode === 200) {
      setChallanNumber("");
      setMaterialsChosen([]);
    }
    await createReceiveChallanHistory(
      challanNumber,
      vendor,
      vendorName,
      materialsChosen
    );
  };
  return (
    <Box my={2}>
      {/* select which vendor */}
      <Stack
        direction={"row"}
        gap={2}
        sx={{
          width: "100%",
          justifyContent: "center",
          my: 2,
          marginTop: 4,
          borderRadius: "5px",
          bgcolor: "rgba(234, 235, 254,0.5)",
          py: 3,
        }}
      >
        <TextField
          style={{ width: "150px" }}
          select
          label="Select Vendor"
          value={vendor}
          onChange={(e) => setVendor(e.target.value)}
          variant="standard"
          className="select"
          // fullWidth
        >
          {vendorList.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          style={{ width: "300px" }}
          label="Invoice Number"
          value={challanNumber}
          onChange={(e) => setChallanNumber(e.target.value)}
          variant="standard"
        />
        <TextField
          type="date"
          style={{ width: "150px" }}
          label="Invoice Date"
          value={invoiceDate}
          onChange={(e) => setInvoiceDate(e.target.value)}
          variant="standard"
        />
        <TextField
          style={{ width: "150px" }}
          select
          label="Received By"
          value={receivedBy}
          onChange={(e) => setReceivedBy(e.target.value)}
          variant="standard"
          className="select"
          // fullWidth
        >
          {["Warehouse", "Inhouse"].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {vendor && fabrics.length > 0 && challanNumber && receivedBy && (
        <Box>
          <Stack
            direction={"row"}
            gap={2}
            sx={{
              justifyContent: "center",
              my: 2,
              marginTop: 4,
              borderRadius: "5px",
              bgcolor: "rgba(234, 235, 254,0.5)",
              py: 3,
            }}
          >
            {/* fabric */}
            <TextField
              style={{ width: "150px" }}
              select
              label="Select Fabric"
              value={selectedFabric}
              onChange={(e) => setSelectedFabric(e.target.value)}
              variant="standard"
              className="select"
              // fullWidth
            >
              {fabrics.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            {/* color */}
            <TextField
              style={{ width: "150px" }}
              disabled={colors.length === 0}
              select
              label="Select Color"
              value={selectedColor}
              onChange={(e) => setSelectedColor(e.target.value)}
              variant="standard"
              className="select"
              // fullWidth
            >
              {colors.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: "150px" }}
              variant="standard"
              label="Quantity"
              value={quantity}
              onChange={handleNumberInput}
            />
            <TextField
              sx={{ width: "150px" }}
              variant="standard"
              label="Unit Value"
              value={unitValue}
              onChange={handleUnitValueInput}
            />
            <TextField
              style={{ width: "100px" }}
              select
              label="Select Unit"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              variant="standard"
              className="select"
              // fullWidth
            >
              {["kg", "m"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {/* add */}
            <Button variant="contained" sx={{ px: 4 }} onClick={handleAdd}>
              Add
            </Button>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-evenly",
              bgcolor: "#eaebfe",
              py: 1,
              fontSize: "20px",
              fontWeight: "bold",
              my: 3,
            }}
          >
            <div>{`Date - ${new Date().toLocaleDateString("en-in")}`}</div>
            <div>{`Received From - ${
              vendorList.find((v) => v.code === vendor).name
            }`}</div>
            <div>{`Invoice No. ${challanNumber}`}</div>
          </Stack>
          <ReceiveAmtTable data={materialsChosen} handleRemove={handleRemove} />
          {/* fabric and color */}
        </Box>
      )}
      {materialsChosen.length > 0 && (
        <Stack direction={"row"} sx={{ justifyContent: "end", my: 2 }}>
          <Button variant="contained" onClick={handleReceiveStock}>
            Receive Stock
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default ReceiveFabricForm;
