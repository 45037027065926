import {
    Box,
    Backdrop,
    CircularProgress,
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Chip,
    TableFooter,
    TablePagination,
    IconButton,
    Avatar,
    Switch,
    FormControlLabel,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { toast } from "react-toastify";
import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { getAllReconDetailsByDateRange, getAllReconDetailsBySingleDate, getReconOrdersbyDatebyProcessedAt, submitAggregateData } from '../../actions/reconActions';
import { useTheme } from "@emotion/react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";


const getLast30Days = () => {
    let dates = [];
    for (let i = 0; i < 10; i++) {
        let date = new Date();
        date.setDate(date.getDate() - i);
        dates.push(date.toISOString().split('T')[0]);
    }
    return dates;
};

const showToastMessage = (msg, isError) => {
    if (isError) {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
        });
    } else {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <ArrowUpwardIcon />
                ) : (
                    <ArrowDownwardIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeftIcon />
                ) : (
                    <KeyboardArrowRightIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const InscanStatus = () => {
    const [last30DaysScannedData, setLast30DaysScannedData] = useState([])
    const [selectedDayData, setSelectedDayData] = useState([])
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState("")
    const [aggregateData, setAggregateData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderType, setOrderType] = useState("RTO")
    const [filteredOrders, setFilteredOrders] = useState([]);

    const last30days = getLast30Days();
    // console.log(last30days);

    const dateHandleClick = (date) => {
        setSelectedDate(date);
        setSelectedDayData((aggregateData.filter((eachDayData) => eachDayData.day === date))[0].scannedOrders)
    }

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleDateSubmit = async () => {
        const isWithinLast30Days = last30days.includes(selectedDate);

        if (isWithinLast30Days) {
            dateHandleClick(selectedDate);
        } else {
            try {
                const data = await getReconOrdersbyDatebyProcessedAt(selectedDate, "single_date");
                console.log("getAllReconDetailsBySingleDate :", data)
                if (data.statusCode === 200) {
                    setSelectedDayData(data.body)
                } else {
                    showToastMessage("No data available for the selected date.", true);
                    setSelectedDayData(null);
                }
            } catch (e) {
                showToastMessage("Failed to fetch data for the selected date.", true);
                setSelectedDayData(null);
                console.log("Error", e);
            }
        }
    };



    const fetchData = async () => {
        setIsLoading(true)
        const last30DaysscannedDataRes = await getReconOrdersbyDatebyProcessedAt(last30days[last30days.length - 1], "dateRange");
        if (last30DaysscannedDataRes.statusCode === 500) {
            setIsError(true);
            setIsLoading(false);
        } else {
            setLast30DaysScannedData(last30DaysscannedDataRes.body || []);
            setIsLoading(false);
        }
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - selectedDayData.length) : 0;

    // Ensure that the page is within the valid range
    useEffect(() => {
        const maxPage = Math.max(0, Math.ceil(selectedDayData.length / rowsPerPage) - 1);
        if (page > maxPage) {
            setPage(maxPage);
        }
    }, [selectedDayData.length, rowsPerPage, page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // HANDLE SORT CONFIG CHANGES


    useEffect(() => {
        fetchData();
    }, []);



    useEffect(() => {
        if (last30DaysScannedData.length > 0) {
            // Calculate aggregate data from 
            const aData = last30days.reduce((acc, date) => {
                const allOrdersbyDate = last30DaysScannedData.filter((order) => order.processed_at?.split("T")[0] === date);
                const inventoryUpdatedOrders = allOrdersbyDate.filter((f) => "isVerified" in f);

                const newObj = {
                    day: date,
                    scannedOrders: allOrdersbyDate,
                    totalScanned: allOrdersbyDate.length,
                    totalInventoryUpdatedOrders: inventoryUpdatedOrders.length
                }
                acc.push(newObj);
                return acc;
            }, [])

            setAggregateData(aData)
            setIsLoading(false);
        }
    }, [last30DaysScannedData]);



    useEffect(() => {
        setFilteredOrders(selectedDayData.filter(order => order.type === orderType));
    }, [selectedDayData, orderType]);

      const exportToExcel = async (type) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Scan Data");
    
        worksheet.columns = [
          { header: "Sl No", key: "sl", width: 10 },
          { header: "AWB", key: "awb_code", width: 20 },
          { header: "Fulfillment ID", key: "fulfillment_id", width: 20 },
          { header: "Inscan Date", key: "sync_date", width: 30 },
          { header: "Inscan Time", key: "sync_time", width: 30 },
          { header: "Courier", key: "courier", width: 20 },
          { header: "Status", key: "isverified", width: 20 },
        ];
        const RtoData = selectedDayData.filter((order) => order.type === type && order.processed_at.slice(0,10) === selectedDate);
        RtoData.forEach((order, index) => {
          worksheet.addRow({
            sl: index + 1,
            awb_code: order.awb_code,
            fulfillment_id: order.fulfillment_id,
            sync_date: new Date(order.processed_at).toLocaleDateString(
              "en-IN"
            ),
            sync_time: new Date(order.processed_at).toLocaleTimeString(
              "en-IN",
              { timeZone: "Asia/Kolkata" }
            ),
            courier: order?.courier_name || "N/A",
            isverified: order.isVerified ? "Scanned and Updated" : "Scanned"
          });
        });
    
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob,  `scanData${type}${new Date().toISOString().split('T')[0]}.xlsx`);
    
        showToastMessage("Data exported to Excel successfully", false);
      };



    useEffect(() => {
        console.log("last30DaysScannedData", last30DaysScannedData);
    }, [last30DaysScannedData]);

    useEffect(() => {
        console.log("selectedDayData", selectedDayData)
        console.log("aggregateData", aggregateData)

    }, [selectedDayData])

    useEffect(() => {
        console.log("aggregateData", aggregateData)
    }, [aggregateData])



    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
                Quick Scanned Order Status
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {last30days.map((day, i) => (
                    <Chip key={i} label={day} variant="outlined" disabled={isLoading} sx={{ fontSize: 14, px: 1.5, py: 0.5 }} onClick={() => dateHandleClick(day)} />
                ))}
            </Box>

            <Box>
                <h4>Select a Date or Enter a Date : </h4>
            </Box>

            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                <Box>
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                    <Button onClick={handleDateSubmit}>Submit</Button>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={2} // Reduced bottom margin
                sx={{
                    backgroundColor: "#f5f5f5",
                    padding: "8px", // Reduced padding
                    borderRadius: "6px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    gap: 1, // Less gap between switch & stats
                    width: "100%",
                    maxWidth: "400px", // Slightly narrower
                    margin: "auto",
                }}
            >
                {/* Top Section - Toggle Switch */}
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="body2" // Smaller font
                        sx={{ fontWeight: "bold", color: "#333", marginRight: "6px" }}
                    >
                        RE
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small" // Smaller switch
                                disabled={isLoading}
                                checked={orderType === "RTO"}
                                onChange={() => setOrderType(orderType === "RTO" ? "R/E" : "RTO")}
                                sx={{
                                    "& .MuiSwitch-thumb": { width: 18, height: 18 }, // Smaller switch thumb
                                    "& .MuiSwitch-track": { width: 60, height: 16 }, // Smaller track
                                }}
                            />
                        }
                    />
                    <Typography
                        variant="body2" // Smaller font
                        sx={{ fontWeight: "bold", color: "#333", marginLeft: "6px" }}
                    >
                        RTO
                    </Typography>
                </Box>

                {/* Bottom Section - Stats */}
                <Box
                    display="grid"
                    gridTemplateRows="repeat(2, auto)"
                    gap={0.5} // Reduced gap
                    sx={{
                        backgroundColor: "#fff",
                        padding: "6px", // Smaller padding
                        borderRadius: "6px",
                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                    }}
                >
                    {/* First Row - Total Orders */}
                    <Box
                        sx={{
                            backgroundColor: "#e3f2fd",
                            padding: "4px", // Less padding
                            borderRadius: "4px",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="caption" fontWeight="bold"> {/* Smaller text */}
                            Total Orders: {selectedDayData.length} | Updated: {selectedDayData.filter((d) => d.isVerified).length}
                        </Typography>
                    </Box>

                    {/* Second Row - Two Sections (RTO & RE) */}
                    <Box display="flex" gap={0.5}>
                        {/* RTO Orders Section */}
                        <Box
                            sx={{
                                backgroundColor: "#ffebee",
                                padding: "4px",
                                borderRadius: "4px",
                                flex: 1,
                                textAlign: "center",
                            }}
                        >
                            <Typography variant="caption" fontWeight="bold">
                                RTO: {selectedDayData.filter((d) => d.type === "RTO").length} (Total) | {selectedDayData.filter((d) => d.isVerified && d.type === "RTO").length} (Updated)
                            </Typography>
                        </Box>

                        {/* RE Orders Section */}
                        <Box
                            sx={{
                                backgroundColor: "#e8f5e9",
                                padding: "4px",
                                borderRadius: "4px",
                                flex: 1,
                                textAlign: "center",
                            }}
                        >
                            <Typography variant="caption" fontWeight="bold">
                                RE: {selectedDayData.filter((d) => d.type === "R/E").length} (Total) | {selectedDayData.filter((d) => d.isVerified && d.type === "R/E").length} (Updated)
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>


            {filteredOrders.length > 0 ? (
                <Paper elevation={3} style={{ gap: "8px" }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sl No</TableCell>
                                    <TableCell>AWB</TableCell>
                                    <TableCell>Fulfillment ID</TableCell>
                                    <TableCell>Inscan Time</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Ordered Items</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredOrders
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((order, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                            <TableCell>{order.awb_code}</TableCell>
                                            <TableCell>{order.fulfillment_id}</TableCell>
                                            <TableCell>
                                                {new Date(order.processed_at).toLocaleDateString("en-IN")}<br />
                                                {new Date(order.processed_at).toLocaleTimeString("en-IN", { timeZone: "Asia/Kolkata" })}
                                            </TableCell>
                                            <TableCell>{order.type}</TableCell>
                                            <TableCell>
                                                {order.line_items?.map((row, i) => (
                                                    <div key={i} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        <Avatar src={row.url} sx={{ width: 60, height: 60, objectFit: "cover" }} variant="square" />
                                                        <span style={{ marginLeft: "15px" }}>{row.sku}</span>
                                                    </div>
                                                ))}
                                            </TableCell>{}
                                            <TableCell>
                                                <Chip  label={order.isVerified ? "Scanned and Updated" : "Scanned"} color={order.isVerified ? "success" : "error"} variant="contained"  sx={{ fontSize: 14, px: 1.5, py: 0.5 }} />

                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                                        count={filteredOrders.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : (
                <Typography variant="h6" align="center" sx={{ mt: 3, color: "gray" }}>
                    No Orders Scanned
                </Typography>
            )}
              <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => exportToExcel("RTO")}
                  disabled={isLoading}
                >
                  Export to Excel (RTO)
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => exportToExcel("R/E")}
                  disabled={isLoading}
                >
                  Export to Excel (R/E)
                </Button>
              </Box>
        </Box>
        


    );

};

export default InscanStatus;


