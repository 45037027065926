import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetInventoryStock } from "../../actions/inventoryActions";
import { getSizesByType } from "../../utils/helperArrays";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Avatar,
  Button,
  TextField,
  Modal,
  Grid,
} from "@mui/material";
import ProgressBar from "../../ProgressBar";

export default function UpdateStockModal({
  data,
  setModalOpen,
  setSelectedRowData,
}) {
  const loading = useSelector((state) => state.inventoryDetails.loading);
  const [open, setOpen] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [inventoryStock, setInventoryStock] = useState([]);
  const [toast, setToast] = useState(false);

  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (toast) {
      setInventoryStock([]);
      setSizes([]);
      setToast(false);
    }
  }, [toast]);

  useEffect(() => setInventoryStock([]), [data.type]);

  useEffect(() => {
    setOpen(true);
    const sizeArray = getSizesByType(data.type);
    setSizes(sizeArray);
  }, [data]);

  useEffect(() => {
    sizes.forEach((row) => {
      setInventoryStock((prev) => [
        ...prev,
        {
          barcode:
            row === "Free Size (Fits XS to L)"
              ? `LB${data.SKU}FS`
              : `LB${data.SKU}${row}`,
          count: 0,
        },
      ]);
    });
  }, [data, sizes]);

  const handleSubmit = async () => {
    dispatch(resetInventoryStock(inventoryStock));
    setToast(true);
    setOpen(false);
    setSelectedRowData({});
    setInventoryStock([]);
    setSizes([]);
    setModalOpen(false);
  };

  useEffect(()=> {
    console.log("inventoryStock : ", inventoryStock)
  },[inventoryStock])

  return (
    <>
      {loading ? (
        <ProgressBar />
      ) : (
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
            setInventoryStock([]);
            setSizes([]);
            setModalOpen(false);
            setSelectedRowData({});
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <h3 style={{ display: "inline" }}>
                SKU: <span style={{ color: "red" }}>{data.SKU}</span>
              </h3>
              <h3 style={{ display: "inline", paddingLeft: "40px" }}>
                Cost Price:{" "}
                <span style={{ fontWeight: "400" }}>{data.costPrice}</span>
              </h3>
            </div>
            <br />
            <br />
            <Box style={{ padding: 0 }}>
              <Grid container spacing={2} style={{ alignItems: "center" }}>
                <Grid item xs={4}>
                  <Avatar
                    src={data.imageUrl}
                    alt="https://www.elegantthemes.com/blog/wp-content/uploads/2018/02/502-error.png"
                    sx={{
                      width: "75%",
                      height: "75%",
                    }}
                    variant="square"
                  />
                </Grid>
                <Grid item xs={8}>
                  <TableContainer sx={{ width: "100%" }}>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {sizes.map((i) => (
                            <TableCell align="center" key={i}>
                              {" "}
                              {i}{" "}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {sizes.map((row) => (
                            <TableCell align="center" key={row}>
                              {data[row]}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          {inventoryStock.map((item, i) => (
                            <TableCell key={i}>
                              <TextField
                                label={sizes[i]}
                                value={item.count}
                                type="string"
                                onChange={(e) => {
                                  let count = parseInt(e.target.value, 10);
                                  if (Number.isNaN(count)) {
                                    count = 0;
                                  }
                                  const newData = inventoryStock.map((row) => {
                                    if (item.barcode === row.barcode) {
                                      return {
                                        ...row,
                                        count,
                                      };
                                    } else {
                                      return row;
                                    }
                                  });
                                  setInventoryStock(newData);
                                }}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />

                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleSubmit}
                  >
                    Reset Stock
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
