import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Skeleton, Stack, TextField } from "@mui/material";

import {
    getAllDispatchesByProcessedDate,
    getAllDTRACKdataByProcessedDate,
} from "../../actions/ordersActions";
import DispatchesTrackingTable from "../ordersWidgets/dispatchtracking/DispatchesTrackingTable";

const DispatchesTracker = () => {
    const auth = useSelector((state) => state.auth);
    const [orders, setOrders] = useState([]);
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!auth) {
            return;
        }
        if (orders.length === 0) {
            // FETCH ALL BY DEFAULT TODAY
            const todayDate = new Date().toISOString().split("T")[0];
            getDataFromDB(todayDate);
        }
    }, [auth]);

    useEffect(() => {
        if (value !== "") {
            // FETCH ORDERS BY DATE
            setOrders([]);
            const pickedDate = new Date(value);
            const date = pickedDate.toISOString().split("T")[0];
            getDataFromDB(date);
        }
    }, [value]);

    //FETCH function
    const getDataFromDB = (date) => {
        setLoading(true);
        Promise.all([
            getAllDispatchesByProcessedDate(date),
            getAllDTRACKdataByProcessedDate(date),
        ])
            .then(([dispatchResponse, trackingResponse]) => {
                if (
                    dispatchResponse.statusCode === 200 &&
                    trackingResponse.statusCode === 200
                ) {
                    createTableData(
                        dispatchResponse.body,
                        trackingResponse.body
                    );
                } else {
                    toast("Error fetching data. Please refresh!");
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log("Promise.all error:", err);
                toast("An error occurred at server");
                setLoading(false);
            });
    };

    const createTableData = (dispatchedOrders, trackingData) => {
        const merged = dispatchedOrders.reduce((acc, i) => {
            const tracking = trackingData.find(
                (t) => t.fulfillment_id === i.fulfillment_id
            );
            if (!tracking) {
                console.log("No tracking found for a fulfillment");
                acc.push(i);
            } else {
                const mergedOrder = {
                    ...i,
                    lifecycle: tracking,
                };
                acc.push(mergedOrder);
            }
            return acc;
        }, []);
        setOrders(merged);
    };

    const Progress = () => {
        return (
            <div>
                <Skeleton variant="rectangular" width={210} height={40} />
                <Stack direction={"row"} gap={2}>
                    <Skeleton
                        variant="rectangular"
                        width={200}
                        height={40}
                        sx={{ my: 1 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        width={200}
                        height={40}
                        sx={{ my: 1 }}
                    />
                </Stack>
                <Skeleton variant="rectangular" width={"100%"} height={400} />
            </div>
        );
    };

    return (
        <div>
            <h2>Select Date</h2>
            <br />
            <p>Invoice Date</p>
            <TextField
                type="date"
                size="medium"
                variant="outlined"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="processed date"
            />
            <br />
            <br />

            {loading ? (
                <Progress />
            ) : (
                <>
                    <p>Total: {orders?.length}</p>
                    {orders.length > 0 && (
                        <DispatchesTrackingTable orderData={orders} />
                    )}
                </>
            )}
        </div>
    );
};

export default DispatchesTracker;
