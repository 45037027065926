import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TableSortLabel,
  Paper,
  Box,
  IconButton,
  Collapse
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const tableHeadings = [
  { headingName: "SKU", value: "sku" },
  { headingName: "Opening", value: "openingStockAmt" },
  { headingName: "RTO", value: "RTOAmt" },
  { headingName: "R/E", value: "REAmt" },
  { headingName: "PO", value: "poAmt" },
  { headingName: "stock added", value: "stockAdded" },
  { headingName: "damaged", value: "DamagedAmt" },
  { headingName: "Dispatched", value: "dispatchedAmt" },
  { headingName: "Revert", value: "revertAmt" },
  { headingName: "Reinventory", value: "reInventoryAmt" },   
  { headingName: "closing", value: "closing" },
  { headingName: "Real Inventory", value: "inventoryAmt" },
  { headingName: "Click", value: "none" },
];

const TableHeadings = ({ orderBy, order, handleSort }) => {
  const createTableCell = (input) => {
    if (input.value === "none") {
      return <div>{input.headingName}</div>;
    } else {
      return (
        <TableSortLabel
          active={orderBy === input.value}
          direction={orderBy === input.value ? order : "asc"}
          onClick={() => handleSort(input.value)}
        >
          {input.headingName}
        </TableSortLabel>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeadings.map((th, i) => (
          <TableCell key={i}>{createTableCell(th)}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Row = ({ row, index, page, rowsPerPage }) => {
  const [open, setOpen] = useState(false);


  return (
    <>
      <TableRow key={index} sx={{ background: row.isMatched ? "white" : "#faaaaa" }}>
        <TableCell>{row.sku}</TableCell>
        <TableCell>{row.openingStockAmt}</TableCell>
        <TableCell>{row.RTOAmt}</TableCell>
        <TableCell>{row.REAmt}</TableCell>
        <TableCell>{row.poAmt}</TableCell>
        <TableCell>{row.stockAdded}</TableCell>
        <TableCell>{row.DamagedAmt}</TableCell>
        <TableCell>{row.dispatchedAmt}</TableCell>
        <TableCell>{row.revertAmt}</TableCell>
        <TableCell>{row.reInventoryAmt}</TableCell>
        <TableCell>{row.closing}</TableCell>
        <TableCell>{row.inventoryAmt}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Size</TableCell>
                    <TableCell>Opening</TableCell>
                    <TableCell>RTO</TableCell>
                    <TableCell>R/E</TableCell>
                    <TableCell>PO</TableCell>
                    <TableCell>Stock Added</TableCell>
                    <TableCell>Damaged</TableCell>
                    <TableCell>Dispatched</TableCell>
                    <TableCell>Revert</TableCell>
                    <TableCell>Reinventory</TableCell>
                    <TableCell>Closing</TableCell>
                    <TableCell>Real Inventory</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((detail, idx) => {
                    if(row.sku === "WC1206"){
                      console.log("row",row)
                    }
                    return (
                      <TableRow key={idx}>
                        <TableCell>{detail.size}</TableCell>
                        <TableCell>{detail.openingStockAmt}</TableCell>
                        <TableCell>{detail.RTOAmt}</TableCell>
                        <TableCell>{detail.REAmt}</TableCell>
                        <TableCell>{detail.poAmt}</TableCell>
                        <TableCell>{detail.stockAdded}</TableCell>
                        <TableCell>{detail.DamagedAmt}</TableCell>
                        <TableCell>{detail.dispatchedAmt}</TableCell>
                        <TableCell>{detail.revertAmt}</TableCell>
                        <TableCell>{detail.reInventoryAmt ?? 0}</TableCell>
                        <TableCell>{detail.closing}</TableCell>
                        <TableCell>{detail.inventoryAmt}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const aggregateSKUs = (data) => {
  const aggregatedData = {};

  data.forEach((row) => {
    if (!aggregatedData[row.sku]) {
      aggregatedData[row.sku] = {
        sku: row.sku,
        openingStockAmt: 0,
        RTOAmt: 0,
        REAmt: 0,
        poAmt: 0,
        stockAdded: 0,
        DamagedAmt: 0,
        dispatchedAmt: 0,
        revertAmt: 0,
        closing: 0,
        reInventoryAmt: 0,
        inventoryAmt: 0,
        isMatched: true,
        details: []
      };
    }

    aggregatedData[row.sku].openingStockAmt += row.openingStockAmt;
    aggregatedData[row.sku].RTOAmt += row.RTOAmt;
    aggregatedData[row.sku].REAmt += row.REAmt;
    aggregatedData[row.sku].poAmt += row.poAmt;
    aggregatedData[row.sku].stockAdded += row.stockAdded;
    aggregatedData[row.sku].DamagedAmt += row.DamagedAmt;
    aggregatedData[row.sku].dispatchedAmt += row.dispatchedAmt;
    aggregatedData[row.sku].revertAmt += row.revertAmt;
    aggregatedData[row.sku].reInventoryAmt += row.reInventoryAmt;
    aggregatedData[row.sku].closing += row.closing;
    aggregatedData[row.sku].inventoryAmt += row.inventoryAmt;
    aggregatedData[row.sku].isMatched = aggregatedData[row.sku].isMatched && row.isMatched;
    aggregatedData[row.sku].details.push(row);
  });

  return Object.values(aggregatedData);
};

const InventoryTable = ({ filteredData }) => {
  console.log("filtered data :", filteredData.filter((f)=>f.sku === "WC1206"))
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("openingStockAmt");
  const [order, setOrder] = useState("desc");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const aggregatedData = aggregateSKUs(filteredData);
    const sortedData = [...aggregatedData].sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] - b[orderBy];
      } else {
        return b[orderBy] - a[orderBy];
      }
    });
    setTableData(sortedData);
  }, [filteredData, orderBy, order]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const isAsc = order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };


  return (
    <div>
      <Box sx={{ paddingTop: 4 }}></Box>
      <TableContainer component={Paper} elevation={3} sx={{ padding: 2 }}>
        <Table>
          <TableHeadings order={order} orderBy={orderBy} handleSort={handleSort} />
          <TableBody>
            {tableData.length > 0 ? (
              (rowsPerPage > 0 ? tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData
              ).map((row, index) => (
                <Row key={index} row={row} index={index} page={page} rowsPerPage={rowsPerPage} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">No data available.</TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box />
    </div>
  );
};

export default InventoryTable;