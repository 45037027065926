// LINE:621. Temporary fix to convert "free size" variant to "FS".
import { getOrderWeight, getWeightFromInventory } from "./weightCalculator";

const calculateFulfillmentId = (id) => {
    const baseNumber = String(id).length > 1 ? parseInt(String(id)[0]) : 0;
    const newId = id + 1 * (baseNumber + 1);
    const decimalPlaces = 1 / Math.pow(10, String(newId).length);
    const isLimit = String(newId)[0] === String(baseNumber) || baseNumber === 0;
    if (newId % 10 !== 0 && isLimit) {
        return newId * decimalPlaces;
    } else {
        return (newId + 1) * decimalPlaces;
    }
};

export const formatCurrency = (price) =>
    parseFloat(parseFloat(price).toFixed(2));

export const processingExcludedOrders = (orders, processingOrders) =>
    orders.filter((item) => {
        const isPrepaid = item.payment_method === "Prepaid";
        const found_order = processingOrders.find(
            (i) =>
                // (i.package_status === "packing" ||
                //     i.package_status === "dispatched") &&
                i.original_order_id === item.original_order_id &&
                (i.package_status === "sync#RTO" ||
                    i.package_status === "sync#RTU" ||
                    i.package_status === "sync#RAO" ||
                    i.package_status === "RTD" ||
                    i.package_status === "sync#UD" ||
                    i.package_status === "sync#RTO-IT" ||
                    i.package_status === "sync#RTON" ||
                    i.package_status === "sync#RT_In Transit" ||
                    i.package_status === "sync#RT_In_Transit" ||
                    i.package_status === "sync#RT_Pending" ||
                    i.package_status === "sync#RT_Dispatched" ||
                    i.package_status === "RTO" ||
                    i.package_status === "sync#sh#RTO_IN_PROGRESS" ||
                    i.package_status === "sync#RTO_IN_PROGRESS")
        );
        return isPrepaid || !found_order;
    });

export const arrangeByDate = (orders) =>
    orders.sort((a, b) => new Date(b.date) - new Date(a.date));

export const filterOutFulfilledLineItems = (orders) =>
    orders
        .map((o) => {
            const products = o.order_items.filter(
                (p) => p.fulfillable_quantity > 0
            );
            return {
                ...o,
                order_items: products,
            };
        })
        .filter((order) => order.order_items.length > 0);

export const getDispatchableOrders = (orders, inventory) => {
    console.log("dispatchable order inventory", inventory);
    let updatedInventory = JSON.parse(JSON.stringify(inventory));
    //step number 7
    const fulfillOrders = orders
        .reduce((acc, order) => {
            // In Prepaid orders more than 3 products can be sent
            const isLargePrepaidOrder =
                order.payment_method === "Prepaid" &&
                order.order_items.reduce(
                    (sum, p) => sum + p.fulfillable_quantity,
                    0
                ) > 3;
            const products = order.order_items;
            if (products.length === 1) {
                // STEP 7
                updatedInventory = updatedInventory.map((p) => {
                    if (
                        p.SKU?.toUpperCase() ===
                            products[0].sku?.toUpperCase() &&
                        p.size === products[0].variant_title
                    ) {
                        if (p.count >= products[0].fulfillable_quantity) {
                            const productCount = isLargePrepaidOrder
                                ? products[0].fulfillable_quantity
                                : products[0].fulfillable_quantity > 3
                                ? 3
                                : products[0].fulfillable_quantity;
                            // ADD URL of product
                            order.order_items[0].url = p.imageUrl;
                            order.order_items[0].quantity = productCount;
                            // order.fulfillments + 1 >= 10
                            //   ? (order.fulfillments + 1) * 0.1 +
                            //     0.1 +
                            //     parseInt(order.original_order_id)
                            //   : (order.fulfillments + 1) * 0.1 +
                            //     parseInt(order.original_order_id);
                            const editedOrder = {
                                ...order,
                                status:
                                    products[0].fulfillable_quantity >
                                    productCount
                                        ? "partial"
                                        : "fulfilled",
                                fulfillment_id:
                                    calculateFulfillmentId(order.fulfillments) +
                                    parseInt(order.original_order_id),
                            };
                            acc.push(editedOrder);
                            return {
                                ...p,
                                count: p.count - productCount,
                                updated: true,
                            };
                        } else {
                            return p;
                        }
                    } else {
                        return p;
                    }
                });
            } else {
                // More Products in order
                const found = [];
                products.forEach((p) => {
                    const item = updatedInventory.find(
                        (i) =>
                            i.SKU?.toUpperCase() === p.sku?.toUpperCase() &&
                            i.size === p.variant_title &&
                            i.count >= p.fulfillable_quantity
                    );
                    if (item) {
                        found.push({
                            barcode: item.barcode,
                            count: isLargePrepaidOrder
                                ? p.fulfillable_quantity
                                : p.fulfillable_quantity > 3
                                ? 3
                                : p.fulfillable_quantity,
                            product: p,
                            url: item.imageUrl,
                        });
                    }
                });

                if (found.length === 0) {
                    // No inventory
                    return acc;
                }

                if (found.length === 1) {
                    // Single item found in inventory for orders of multiple items
                    // STEP 8
                    const row = found[0];
                    // const isDiscounted =
                    //     row.product.discount_allocations.length > 0; // row.product.total_discount !== "0.00"; //change
                    if (true) {
                        //TODO MAKE partial order
                        acc.push({
                            ...order,
                            order_items: order.order_items
                                .filter((p) => row.product.id === p.id)
                                .map((p) => ({
                                    ...p,
                                    quantity: row.count,
                                    url: row.url,
                                })),
                            status: "partial",
                            fulfillment_id:
                                calculateFulfillmentId(order.fulfillments) +
                                parseInt(order.original_order_id),
                        });
                        //UPdate inventory
                        updatedInventory = updatedInventory.map((p) => {
                            if (p.barcode === row.barcode) {
                                return {
                                    ...p,
                                    count: p.count - row.count,
                                    updated: true,
                                };
                            } else {
                                return p;
                            }
                        });
                    }
                } else {
                    //Multiple items found
                    // STEP 9
                    //TODO show which products are fulfillable
                    //TODO sort by price
                    const eligibleFulfillableProducts =
                        order.order_items.filter((p) => {
                            const foundProduct = found.find(
                                (i) => i.product.id === p.id
                            );
                            return !!foundProduct;
                        });
                    // Take a maximum of 3 items depending on price
                    const finalProducts = isLargePrepaidOrder
                        ? eligibleFulfillableProducts
                        : eligibleFulfillableProducts.slice(0, 3);
                    const fulfillableProducts = finalProducts
                        .map((p) => {
                            //ADD URL
                            const row = found.filter(
                                (i) => i.product.id === p.id
                            )[0];
                            return {
                                ...p,
                                quantity: row.count,
                                url: row.url,
                            };
                        })
                        .reduce((productsArr, p) => {
                            const prevSum = productsArr.reduce(
                                (sum, i) => sum + i.quantity,
                                0
                            );
                            if (
                                !isLargePrepaidOrder &&
                                prevSum + p.quantity > 3
                            ) {
                                // Don't add this item
                            } else {
                                productsArr.push(p);
                            }
                            return productsArr;
                        }, []);

                    const newStatus =
                        order.order_items.reduce(
                            (sum, i) => sum + i.fulfillable_quantity,
                            0
                        ) >
                        fulfillableProducts.reduce(
                            (sum, i) => sum + i.quantity,
                            0
                        )
                            ? "partial"
                            : "fulfilled";

                    acc.push({
                        ...order,
                        order_items: fulfillableProducts,
                        status: newStatus,
                        fulfillment_id:
                            calculateFulfillmentId(order.fulfillments) +
                            parseInt(order.original_order_id),
                    });
                    updatedInventory = updatedInventory.map((p) => {
                        const row = fulfillableProducts.find(
                            (i) =>
                                i.sku?.toUpperCase() === p.SKU?.toUpperCase() &&
                                i.variant_title === p.size
                        );

                        if (row) {
                            return {
                                ...p,
                                count: p.count - row.quantity,
                                updated: true,
                            };
                        } else {
                            return p;
                        }
                    });
                }
            }
            return acc;
        }, [])
        .map((o) => {
            let changeShippingCharges = false;
            const gcDiscount = o.payment_method === "COD" && o.gift_card;
            if (gcDiscount) {
                const gcDiscountPrice =
                    parseFloat(o.total) - parseFloat(o.total_outstanding);
                if (gcDiscountPrice > parseFloat(o.total_item_price)) {
                    // change shipping
                    changeShippingCharges = parseFloat(o.total_outstanding);
                }
            }

            // Only required fields of line items
            const newLineItemsArr = o.order_items.map((p) => {
                // Gift Card
                let lineGcDiscount = 0;

                if (gcDiscount) {
                    const totalGcDiscount =
                        parseFloat(o.total) - parseFloat(o.total_outstanding);

                    // Sometimes GC amount may exceed total line item price
                    if (totalGcDiscount > parseFloat(o.total_item_price)) {
                        // All line items should be 0
                        lineGcDiscount = parseFloat(p.price);
                    } else {
                        const lineValue =
                            parseFloat(p.price) /
                            o.original_items.reduce(
                                (total, i) =>
                                    total + parseFloat(i.price * i.quantity),
                                0
                            );

                        lineGcDiscount += totalGcDiscount * lineValue;
                    }
                }

                // If Gift Card, we don't get GC discount amount, so take whole discount as GC discount only
                const newDiscount = gcDiscount
                    ? lineGcDiscount
                    : parseFloat(
                          p.discount_allocations.reduce(
                              (sum, i) => parseFloat(i.amount) + sum,
                              0.0
                          ) / p.fulfillable_quantity
                      );

                return {
                    id: p.id,
                    name: p.name,
                    sku: p.sku?.toUpperCase() + "." + p.variant_title,
                    units: p.quantity,
                    selling_price: formatCurrency(p.price),
                    discount:
                        newDiscount > parseFloat(p.price)
                            ? formatCurrency(p.price)
                            : formatCurrency(newDiscount),
                    tax: "0.00",
                    hsn: "",
                    variant_title: p.variant_title,
                    product_id: p.product_id,
                    url: p.url || "",
                };
            });

            if (changeShippingCharges) {
                console.log(
                    "GC order",
                    newLineItemsArr,
                    changeShippingCharges,
                    o.fulfillment_id
                );
            }

            return {
                ...o,
                shipping_charges: changeShippingCharges
                    ? changeShippingCharges
                    : o.shipping_charges,
                order_items: newLineItemsArr,
            };
        })
        .map((o) => {
            //ADJUST THE TOTAL PRICE
            let newTotal = 0;
            const totalPriceOfItems = formatCurrency(
                o.order_items.reduce(
                    (sum, i) =>
                        sum +
                        i.units *
                            (parseFloat(i.selling_price) -
                                parseFloat(i.discount)),
                    0
                )
            );
            // LOGIC to ADD SHIPPING
            if (o.fulfillments === 0) {
                // ADD SHIPPING COST
                newTotal += o.shipping_charges;
            } else {
                const isAlreadyTaken =
                    o.fulfillment_details.filter((f) => f.status === "success")
                        .length > 0;
                if (isAlreadyTaken) {
                    o.shipping_charges = 0;
                } else {
                    newTotal += o.shipping_charges;
                }
            }
            newTotal += totalPriceOfItems;
            return {
                ...o,
                sub_total: totalPriceOfItems,
                total: newTotal,
            };
        })
        .map((o) => ({
            ...o,
            weight: getWeightFromInventory(o.order_items, inventory),
            // weight:
            //   o.payment_method === "Prepaid" &&
            //   o.order_items.reduce((sum, p) => sum + p.units, 0) > 3
            //     ? "NA"
            //     : getOrderWeight(o.order_items),
        }));

    const onlyUpdatedInventory = updatedInventory.filter((i) => i.updated);
    console.log("FULFILLABLE", fulfillOrders);
    console.log("UPDATED INVENTORY", onlyUpdatedInventory);

    return { fulfillOrders, onlyUpdatedInventory };
};

export const dispatchManualHelper = (order, inventory) => {
    // Create a deep copy of the order, to avoid unintentional chnages to original object
    const copiedOrder = JSON.parse(JSON.stringify(order));
    copiedOrder.fulfillable = false; // Assume not fulfillable

    let updatedInventory = JSON.parse(JSON.stringify(inventory));

    // LOGIC HERE
    const products = copiedOrder.order_items;
    if (products.length === 1) {
        // STEP 7
        updatedInventory = updatedInventory.map((p) => {
            if (
                p.SKU?.toUpperCase() === products[0].sku?.toUpperCase() &&
                p.size === products[0].variant_title
            ) {
                if (p.count >= products[0].fulfillable_quantity) {
                    const productCount =
                        products[0].fulfillable_quantity > 3
                            ? 3
                            : products[0].fulfillable_quantity;
                    // ADD URL of product
                    copiedOrder.order_items[0].url = p.imageUrl;
                    copiedOrder.order_items[0].quantity = productCount;
                    copiedOrder.status =
                        products[0].fulfillable_quantity > productCount
                            ? "partial"
                            : "fulfilled";
                    copiedOrder.fulfillment_id =
                        calculateFulfillmentId(copiedOrder.fulfillments) +
                        parseInt(copiedOrder.original_order_id);
                    copiedOrder.fulfillable = true;

                    return {
                        ...p,
                        count: p.count - productCount,
                        updated: true,
                        changedNow: true,
                        diff: productCount,
                        order_id: copiedOrder.original_order_id,
                    };
                } else {
                    return p;
                }
            } else {
                return p;
            }
        });
    } else {
        // More Products in order
        const found = [];
        products.forEach((p) => {
            const item = updatedInventory.find(
                (i) =>
                    i.SKU?.toUpperCase() === p.sku?.toUpperCase() &&
                    i.size === p.variant_title &&
                    i.count >= p.fulfillable_quantity
            );
            if (item) {
                found.push({
                    barcode: item.barcode,
                    count:
                        p.fulfillable_quantity > 3 ? 3 : p.fulfillable_quantity,
                    product: p,
                    url: item.imageUrl,
                });
            }
        });

        if (found.length === 0) {
            // No inventory
            return {
                updatedOrder: copiedOrder,
                updatedStock: updatedInventory,
            };
        }

        if (found.length === 1) {
            // Single item found in inventory for orders of multiple items
            // STEP 8
            const row = found[0];
            const isDiscounted = row.product.discount_allocations.length > 0;
            if (!isDiscounted) {
                //TODO MAKE partial order

                copiedOrder.order_items = products
                    .filter((p) => row.product.id === p.id)
                    .map((p) => ({
                        ...p,
                        quantity: row.count,
                        url: row.url,
                    }));
                copiedOrder.status = "partial";
                copiedOrder.fulfillment_id =
                    calculateFulfillmentId(copiedOrder.fulfillments) +
                    parseInt(copiedOrder.original_order_id);
                copiedOrder.fulfillable = true;

                //UPdate inventory
                updatedInventory = updatedInventory.map((p) => {
                    if (p.barcode === row.barcode) {
                        return {
                            ...p,
                            count: p.count - row.count,
                            updated: true,
                            changedNow: true,
                            diff: row.count,
                            order_id: copiedOrder.original_order_id,
                        };
                    } else {
                        return p;
                    }
                });
            }
        } else {
            //Multiple items found
            // STEP 9
            //TODO show which products are fulfillable
            //TODO sort by price
            const fulfillableProducts = products
                .filter((p) => {
                    const foundProduct = found.find(
                        (i) => i.product.id === p.id
                    );
                    return !!foundProduct;
                })
                .map((p) => {
                    //ADD URL
                    const row = found.filter((i) => i.product.id === p.id)[0];
                    return {
                        ...p,
                        quantity: row.count,
                        url: row.url,
                    };
                })
                .reduce((productsArr, p) => {
                    const prevSum = productsArr.reduce(
                        (sum, i) => sum + i.quantity,
                        0
                    );
                    if (prevSum + p.quantity > 3) {
                        // Don't add this item
                    } else {
                        productsArr.push(p);
                    }
                    return productsArr;
                }, []);

            const newStatus =
                products.reduce((sum, i) => sum + i.fulfillable_quantity, 0) >
                fulfillableProducts.reduce((sum, i) => sum + i.quantity, 0)
                    ? "partial"
                    : "fulfilled";

            copiedOrder.order_items = fulfillableProducts;
            copiedOrder.status = newStatus;
            copiedOrder.fulfillment_id =
                calculateFulfillmentId(copiedOrder.fulfillments) +
                parseInt(copiedOrder.original_order_id);
            copiedOrder.fulfillable = true;

            updatedInventory = updatedInventory.map((p) => {
                const row = fulfillableProducts.find(
                    (i) =>
                        i.sku?.toUpperCase() === p.SKU?.toUpperCase() &&
                        i.variant_title === p.size
                );

                if (row) {
                    return {
                        ...p,
                        count: p.count - row.quantity,
                        updated: true,
                        changedNow: true,
                        diff: row.quantity,
                        order_id: copiedOrder.original_order_id,
                    };
                } else {
                    return p;
                }
            });
        }
    }
    //Track the changed inventory item count
    let changedNow = [];

    if (copiedOrder.fulfillable) {
        let changeShippingCharges = false;
        // Only required fields of line items
        const gcDiscount =
            copiedOrder.payment_method === "COD" && copiedOrder.gift_card;
        if (gcDiscount) {
            const gcDiscountPrice =
                parseFloat(copiedOrder.total) -
                parseFloat(copiedOrder.total_outstanding);
            if (gcDiscountPrice > parseFloat(copiedOrder.total_item_price)) {
                // change shipping
                changeShippingCharges = parseFloat(
                    copiedOrder.total_outstanding
                );
            }
        }
        const newLineItemsArr = copiedOrder.order_items.map((p) => {
            // GIFT CARD
            let lineGcDiscount = 0;

            if (gcDiscount) {
                const totalGcDiscount =
                    parseFloat(copiedOrder.total) -
                    parseFloat(copiedOrder.total_outstanding);

                // Sometimes GC amount may exceed total line item price
                if (
                    totalGcDiscount > parseFloat(copiedOrder.total_item_price)
                ) {
                    // All line items should be 0
                    lineGcDiscount = parseFloat(p.price);
                } else {
                    const lineValue =
                        parseFloat(p.price) /
                        copiedOrder.original_items.reduce(
                            (total, i) =>
                                total + parseFloat(i.price * i.quantity),
                            0
                        );

                    lineGcDiscount += totalGcDiscount * lineValue;
                }
            }

            // If Gift Card, we don't get GC discount amount, so take whole discount as GC discount only
            const newDiscount = gcDiscount
                ? lineGcDiscount
                : parseFloat(
                      p.discount_allocations.reduce(
                          (sum, i) => parseFloat(i.amount) + sum,
                          0.0
                      ) / p.fulfillable_quantity
                  );

            return {
                id: p.id,
                name: p.name,
                sku: p.sku?.toUpperCase() + "." + p.variant_title,
                units: p.quantity,
                selling_price: formatCurrency(p.price),
                discount:
                    newDiscount > parseFloat(p.price)
                        ? formatCurrency(p.price)
                        : formatCurrency(newDiscount),
                tax: "0.00",
                hsn: "",
                variant_title: p.variant_title,
                product_id: p.product_id,
                url: p.url || "",
            };
        });

        // If change in shipping due to Gift Card
        copiedOrder.shipping_charges = changeShippingCharges
            ? changeShippingCharges
            : copiedOrder.shipping_charges;

        copiedOrder.order_items = newLineItemsArr;

        //ADJUST THE TOTAL PRICE
        let newTotal = 0;
        const totalPriceOfItems = formatCurrency(
            copiedOrder.order_items.reduce(
                (sum, i) =>
                    sum +
                    i.units *
                        (parseFloat(i.selling_price) - parseFloat(i.discount)),
                0
            )
        );
        // CHECK IF FIRST FULFILLMENT
        if (copiedOrder.fulfillments === 0) {
            // ADD SHIPPING COST
            newTotal += copiedOrder.shipping_charges;
        } else {
            copiedOrder.shipping_charges = 0;
        }
        newTotal += totalPriceOfItems;

        copiedOrder.sub_total = totalPriceOfItems;
        copiedOrder.total = newTotal;
        changedNow = updatedInventory.filter((i) => i.changedNow === true);
        updatedInventory = updatedInventory.map((p) => {
            const { changedNow, diff, order_id, ...rest } = p;
            return rest;
        });
    }

    return {
        updatedOrder: copiedOrder,
        updatedStock: updatedInventory,
        changedNow,
    };
};

export const randomShuffle = (arr) => {
    const newArr = arr.map((a) => ({ ...a }));
    for (let i = newArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = newArr[i];
        newArr[i] = newArr[j];
        newArr[j] = temp;
    }

    return newArr;
};

export const mapShopifyToShipRocketFormat = (item) => {
    // FORMAT the sku s for hypd inconsistant data
    // For free size variant, using "FS" in dynamo. Chnage in future
    const correctedLineItems = item.line_items.map((p) => ({
        ...p,
        sku: p.sku?.split("_")[0].toUpperCase(),
        variant_title: p.variant_title.length > 4 ? "FS" : p.variant_title,
    }));
    const newOrder = {
        id: item.id, // to be used for shopify put requests
        original_order_id: item.order_number.toString(), // Create fulfillemnt_id before saving in dynamo
        order_date: item.created_at, // Convert to localString when needed
        pickup_location: "GOOD TRIBE PRIVATE LIMITED",
        location_id: "65807843485",
        customer_id: item.customer?.id,
        fulfillments: item.fulfillments?.length,
        fulfillment_details: item.fulfillments,
        billing_customer_name: item.billing_address?.first_name || "",
        billing_last_name: item.billing_address?.last_name,
        billing_address: item.billing_address?.address1,
        billing_address_2: item.billing_address?.address2,
        billing_city: item.billing_address?.city,
        billing_pincode: item.billing_address?.zip,
        billing_state: item.billing_address?.province,
        billing_country: item.billing_address?.country,
        billing_email: item.contact_email
            ? item.contact_email
            : "abc@gmail.com",
        billing_phone: item.billing_address?.phone
            ?.split("")
            .filter((i) => i !== " ")
            .filter((i) => i !== "  ")
            .filter((i) => i !== "-")
            .join("")
            .slice(-10),
        shipping_is_billing:
            item.billing_address?.address1 ===
                item.shipping_address?.address1 &&
            item.billing_address?.zip === item.shipping_address?.zip,
        shipping_customer_name: item.shipping_address?.first_name || "",
        shipping_last_name: item.shipping_address?.last_name,
        shipping_address: item.shipping_address?.address1,
        shipping_address_2: item.shipping_address?.address2,
        shipping_city: item.shipping_address?.city,
        shipping_pincode: item.shipping_address?.zip,
        shipping_country: item.shipping_address?.country,
        shipping_state: item.shipping_address?.province,
        shipping_email: item.contact_email
            ? item.contact_email
            : "abc@gmail.com",
        shipping_phone: item.shipping_address?.phone
            ?.split("")
            .filter((i) => i !== " ")
            .filter((i) => i !== "  ")
            .filter((i) => i !== "-")
            .join("")
            .slice(-10),
        payment_method: item.payment_method,
        shipping_charges: parseFloat(
            item.total_shipping_price_set.presentment_money.amount
        ),
        sub_total: item.sub_total,
        total: item.total_price,
        total_outstanding: item.total_outstanding,
        total_item_price: item.subtotal_price,
        gift_card: item.payment_gateway_names?.includes("gift_card"), // "payment_gateway_names" array must contain gift card (["gift_card","Cash on Delivery (COD)"])
        total_items: item.line_items.reduce((sum, p) => p.quantity + sum, 0),
        order_items: correctedLineItems.filter(
            (p) => p.fulfillment_status !== "fulfilled"
        ),
        original_items: item.original_items || item.line_items,
        payment: item.financial_status,
        status: item.fulfillment_status, //can be null too
        length: "",
        breadth: "",
        height: "",
        weight: "",
        order_id: null,
        shipment_id: makeid(20),
        awb_code: null,
        label_url: "",
        manifest_url: "",
        courier_name: "",
        pickup_status: null,
        package_status: "packing", // Compare from dynamo
        executive_id: "1",
        box_id: null,
        tags: item.tags,
    };
    return newOrder;
};

function makeid(length) {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}
