import { useState } from "react";
import { Box, Button, MenuItem, Paper, Stack, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { addNewMaterial, uploadImage } from "../../../actions/materialActions";
//custom textfield

const AddMaterial = () => {
  const [fabric, setFabric] = useState();
  const [color, setColor] = useState();
  const [unit, setUnit] = useState("kg");
  const [url, setUrl] = useState("");

  const [loading, setLoading] = useState(false);

  const { materialFabricList, materialColorList } = useSelector(
    (state) => state.inventoryDetails
  );

  const handleAdd = async () => {
    setLoading(true);
    const fabricValue = JSON.parse(fabric);
    const colorValue = JSON.parse(color);
    // upload image
    const imageUploadPayload = {
      productId: `Material-${fabricValue.code}${colorValue.code}`,
      dataUrl: url,
    };
    const imageUploadResponse = await uploadImage(imageUploadPayload);
    if (imageUploadResponse.statusCode === 500) {
      setLoading(false);
      return;
    }
    const payload = {
      fabricName: fabricValue.name,
      fabricId: fabricValue.code,
      colorName: colorValue.name,
      colorId: colorValue.code,
      unit,
      imageUrl: imageUploadResponse.url,
    };
    const res = await addNewMaterial(payload);
    if (res.statusCode === 200) {
      setFabric(undefined);
      setColor(undefined);
      setUrl("");
    }
    setLoading(false);
  };
  const handleUploadClick = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      // setImg(() => [...img, ...newFile]);
      // console.log(requiredFile[0].size)
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => {
        setUrl(reader.result);
      };
    }
  };
  return (
    <div>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Add Material Form
      </h2>
      <Paper sx={{ p: 4 }}>
        {/* fabric and color */}
        <Stack direction={"row"} gap={2} sx={{ marginBottom: 3 }}>
          <TextField
            style={{ width: "300px" }}
            select
            label="Select Fabric"
            value={fabric || ""}
            onChange={(e) => setFabric(e.target.value)}
            defaultValue={""}
            variant="standard"
            className="select"
            // fullWidth
          >
            {materialFabricList.map((option) => (
              <MenuItem key={option.code} value={JSON.stringify(option)}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ width: "300px" }}
            select
            label="Select Color"
            value={color || ""}
            defaultValue={""}
            onChange={(e) => setColor(e.target.value)}
            variant="standard"
            className="select"
            // fullWidth
          >
            {materialColorList.map((option) => (
              <MenuItem key={option.code} value={JSON.stringify(option)}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {/* unit kg or m */}
        <TextField
          style={{ width: "100px" }}
          select
          label="Select Unit"
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
          variant="standard"
          className="select"
          // fullWidth
        >
          {["kg", "m"].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        {/* image  */}
        <Box sx={{ marginTop: 3 }}>
          <div>
            <label htmlFor="contained-button-file">
              <Button variant="outlined" component="span">
                {/* {url ? "Change Image" : "Select Image"} */}
                Upload Image
                <input
                  accept="image/*"
                  id="contained-button-file"
                  style={{
                    display: "none",
                  }}
                  type="file"
                  onChange={handleUploadClick}
                />
              </Button>
            </label>
          </div>
          {url && (
            <img
              height={200}
              style={{
                marginTop: "10px",
                objectFit: "contain",
              }}
              src={url}
              alt=""
            />
          )}
        </Box>
        {/* Create button */}
        <Stack
          my={3}
          direction={"row"}
          sx={{
            justifyContent: "end",
          }}
        >
          <Button disabled={loading} variant="contained" onClick={handleAdd}>
            Add Material
          </Button>
        </Stack>
      </Paper>
    </div>
  );
};

export default AddMaterial;
