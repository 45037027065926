import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableFooter,
  TablePagination,
  Button,
  Chip,
  TextField,
  Stack,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { toast } from "react-toastify";
import {
  underProcessOrders,
  fulfillBulkInShopify,
  getDelhiveryWayBills,
  fulfillBulkInShopifyDelhivery,
  uploadPayloadToS3,
  getInvoiceNumbers,
  updateLastUsedInvoice,
} from "../../../actions/ordersActions";
import { resetInventoryStock } from "../../../actions/inventoryActions";
import { sendPayloadToMail } from "../../../actions/SESActions";
import { populateLBHforOrders } from "../../../utils/lbhCalculator";
import { createPDFforManifest } from "../../../utils/pdfUtils";
import { createXLforDelhivery } from "../../../utils/excelUtils";
import { delay } from "../../../utils/helpers";
import { CSVLink } from "react-csv";
import { formatCurrency } from "../../../utils/orderTakeoutHelpers";
import { updateDailyOrderAnalytics } from "../../../actions/analyticsActions";
import moment from "moment-timezone";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, index }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={{
          bgcolor: row.status
            ? "rgb(74, 237, 79)"
            : index % 2 === 0
            ? "rgb(225,220,225)"
            : "rgb(245,245,245)",

          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell component="th" scope="row">
          {row.original_order_id} (#{row.fulfillment_id})
        </TableCell>
        <TableCell align="right">
          {new Date(row.order_date).toLocaleDateString()}
        </TableCell>
        <TableCell align="right">{row.status || "pending"}</TableCell>
        <TableCell align="right">
          <Chip label={row.payment} color="secondary" />
        </TableCell>
        <TableCell align="right">
          {row.billing_customer_name + " " + row.billing_last_name}
        </TableCell>
        <TableCell align="right">{row.sub_total}</TableCell>
        <TableCell align="right">{row.total}</TableCell>
        <TableCell align="right">{row.order_items.length} Items</TableCell>
        <TableCell align="right">{row.weight}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Product Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell align="right">Size</TableCell>
                    <TableCell align="right">Quantity Order</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.order_items.map((productRow) => (
                    <TableRow key={productRow.sku}>
                      <TableCell>
                        <img
                          src={productRow.url}
                          alt="LBI"
                          width="100"
                          height="100"
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {productRow.name}
                      </TableCell>
                      <TableCell>{productRow.sku}</TableCell>
                      <TableCell align="right">
                        {productRow.variant_title}
                      </TableCell>
                      <TableCell align="right">{productRow.units}</TableCell>
                      <TableCell align="right">
                        {formatCurrency(
                          productRow.selling_price - productRow.discount
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <h4>Shipping Address:</h4>
              <p>
                Name: {row.billing_customer_name}
                <br />
                Mobile: {row.billing_phone}
                <br />
                Address: {row.billing_address}
                <br />
                {row.billing_address_2}
                <br />
                {row.billing_city}
                <br />
                {row.billing_country}
                <br />
                {row.billing_pincode}
              </p>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function OrderTakeoutTable({ tableData, updatedInventory }) {
  const dispatch = useDispatch();
  const { fulfillmentLoading } = useSelector((state) => state.orderDetails);
  //   const [filteredData, setFilteredData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [data, setData] = useState(
    // tableData.sort((a, b) => Number(a.order) - Number(b.order))
    tableData
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [splitNumber, setSplitNumber] = useState(1);
  const [wayBills, setWayBills] = useState([]);
  const [invoiceNumbers, setInvoiceNumbers] = useState([]);
  const [lastUsedInvoiceId, setLastUsedInvoiceId] = useState(0);

  const products = useSelector((state) => state.productDetails.products);
  console.log("products", products);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (wayBills.length > 0) {
      if (wayBills.length !== data.length) {
        toast.error("Wrong number of awbs");
        setWayBills([]);
        return;
      }
      for (let i = 0; i < data.length; i++) {
        data[i].awb_code = wayBills[i];
      }
    }
  }, [wayBills]);

  useEffect(() => {
    if (invoiceNumbers.length > 0) {
      if (invoiceNumbers.length !== data.length) {
        toast.error("Wrong number of awbs");
        setWayBills([]);
        return;
      }
      for (let i = 0; i < data.length; i++) {
        data[i].invoice_number = invoiceNumbers[i];
      }
    }
  }, [invoiceNumbers]);
  console.log("check data", data, lastUsedInvoiceId);

  useEffect(() => {
    setWayBills([]);
    setInvoiceNumbers([]);
    // Add LBH of each order
    const newData = populateLBHforOrders(tableData);
    const newDataWithShopifyImage = newData.map((o) => ({
      ...o,
      order_items: o.order_items.map((ot) => {
        const findProduct = products.find((p) =>
          p.variants.find((v) => v.sku === ot.sku.split(".")[0])
        );
        if (findProduct) {
          return {
            ...ot,
            url: findProduct.image.src,
          };
        } else {
          return ot;
        }
      }),
    }));
    // console.log("LBH of box", newData);
    setData(
      [...newDataWithShopifyImage]
        .sort((a, b) => Number(a.order) - Number(b.order))
        .reverse()
    );
  }, [tableData, products]);

  function splitArray(arr, numberOfArrays) {
    if (numberOfArrays < 1) {
      throw new Error("Number of arrays should be greater than or equal to 1");
    }

    const chunkSize = Math.ceil(arr.length / numberOfArrays);
    const result = [];

    for (let i = 0; i < numberOfArrays; i++) {
      const start = i * chunkSize;
      const end = start + chunkSize;
      const chunk = arr.slice(start, end);
      result.push(chunk);
    }

    return result;
  }

  const handleSubmit = async (data) => {
    const processed_at = moment(new Date()).tz("Asia/Kolkata").format();

    const fulfillable = data.every(
      (i) => i.awb_code !== null && i.invoice_number !== null
    );
    if (!fulfillable) {
      //toast and return
      toast.error("Wrong number of awbs/invoices. Please reload");
      return;
    }

    const updateInvoice = await updateLastUsedInvoice(lastUsedInvoiceId);
    if (updateInvoice.status !== 200) {
      toast.error("Invoice Number Not Updated");
      return;
    }
    const payload = data.map((o) => ({
      ...o,
      fulfillment_id: o.fulfillment_id.toString(),
      processed_at,
    }));

    const splitPayload = splitArray(data, splitNumber);
    // *******************************************************S
    let excelArr = [];
    for (let i = 0; i < splitPayload.length; i++) {
      const dataSplit = splitPayload[i].map((o) => ({
        ...o,
        fulfillment_id: o.fulfillment_id.toString(),
        processed_at,
      }));
      console.log(`data split ${i + 1}`, splitPayload[i]);
      //s3
      await uploadPayloadToS3(splitPayload[i], `part${i}`);
      // *******************
      // dispatch(sendPayloadToMail(splitPayload[i]));
      //Download PDF
      createPDFforManifest(dataSplit);
      //XpressBees Excel
      const xbData = createXLforDelhivery(dataSplit); // This just console logs the JSON. We need to make csv and download.
      excelArr.push(xbData);
    }
    setExcelData(excelArr);

    dispatch(underProcessOrders(payload));

    dispatch(resetInventoryStock(updatedInventory));
    // dispatch(sendPayloadToMail(updatedInventory));
    await uploadPayloadToS3(updatedInventory, "updatedInventory");
    await updateDailyOrderAnalytics(payload);
    //Fulfill in Shopify by default
    //AWAIT 1sec for excel and PDF to properly download
    await delay(1000);
    dispatch(fulfillBulkInShopifyDelhivery(payload));
    const payloadForMail = {
      message: "complete orders",
      payload: payload,
    };
    await uploadPayloadToS3(payloadForMail, "complete");
    // dispatch(sendPayloadToMail(payloadForMail));
  };

  useEffect(() => {
    if (excelData.length > 0) {
      // csvLink.current.link.click();
      excelData.forEach((e, i) => {
        const csvLink = document.getElementById(`csv-link-${i}`);
        if (csvLink) {
          csvLink.click();
        }
      });
    }
  }, [excelData]);

  const createWayBills = async () => {
    const wayBillData = await getDelhiveryWayBills(data.length);
    if (wayBillData.status === 200) {
      // check for invoices also
      const invoiceData = await getInvoiceNumbers(data.length);
      if (invoiceData.status === 200) {
        const invoices = invoiceData.body.invoices;
        setInvoiceNumbers(invoices);
        const awbs = wayBillData.body;
        setWayBills(awbs);
        setLastUsedInvoiceId(invoiceData.body.lastGeneratedId);
      } else {
        setInvoiceNumbers([]);
        setWayBills([]);
      }
    } else {
      setWayBills([]);
      setInvoiceNumbers([]);
    }
  };

  return (
    <>
      <br />
      <>
        {/* A button to fetch required number of AWBs */}
        {/* Take the number from table length, disable generate manifest button till awb fetched */}
        {data.length > 0 && (
          <Button variant="outlined" onClick={createWayBills}>
            Create AWBS
          </Button>
        )}
        <br />
        <br />
        <h4>Enter Number of splits</h4>
        <TextField
          label="Enter Number"
          type="number"
          size="small"
          value={splitNumber}
          onChange={(e) => setSplitNumber(parseInt(e.target.value))}
          sx={{ bgcolor: "white" }}
        />
        <br />
        <br />
        {fulfillmentLoading ? (
          "Fulfillment in progress, donot press any button"
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow
                // sx={{
                //   // bgcolor: "#333",
                //   "& > *": { color: "white" },
                // }}
                >
                  <TableCell>Order Number</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Payment Status</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">SubTotal</TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right">Product</TableCell>
                  <TableCell align="right">Weight</TableCell>
                  <TableCell align="left">List</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row, index) => (
                  <Row key={index} row={row} index={index} />
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ overflow: "inherit" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
        <br />
        <br />
        <Stack direction={"row"} gap={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleSubmit(data)}
            disabled={
              fulfillmentLoading ||
              wayBills.length !== data.length ||
              invoiceNumbers.length !== data.length ||
              data.length === 0 ||
              wayBills.length === 0 ||
              invoiceNumbers.length === 0
            }
          >
            Generate Manifest
          </Button>
        </Stack>
        {excelData.map((ed, i) => (
          <CSVLink
            key={i}
            data={ed}
            id={`csv-link-${i}`}
            // ref={csvLink}
            // target="_blank"
            filename={`XB Data ${i}`}
          />
        ))}
      </>
    </>
  );
}
